import {createSlice} from "@reduxjs/toolkit"

const templateSlice=createSlice({
    name:"template",
    initialState:{
          templateList:[],
          templateLoading:false,
          templateCategory:[],
          categoryLoading:false,
          total_template_element:0,
          total_template_category:0,
          AllTemplateCategoryModuleList:[],

    },
    reducers:{
      getTemplateListLoading:(state,{payload})=>{
        state.templateLoading=true
      },
      getTemplateListSuccess:(state,{payload})=>{
        state.templateLoading=false
        state.templateList=payload
      },
      getTemplateListFail:(state,{payload})=>{
        state.templateLoading=false
      },
      getTemplateCategoryLoading:(state,{payload})=>{
        state.templateLoading=true
      },
      getTemplateCategorySuccess:(state,{payload})=>{
        state.templateLoading=false
        state.templateCategory=payload
      },
      getTemplateCategoryFail:(state,{payload})=>{
        state.templateLoading=false
      },
      setTemplateTotalElement:(state,{payload})=>{
        state.total_template_element=payload
      },
      setTemplateCategoryElement:(state,{payload})=>{
        state.total_template_category=payload
      },
      setTemplateSwapCategory:(state,{payload})=>{
        const tempArr = [...state.templateCategory];
            let tempVal = tempArr[payload?.cat1]
            tempArr[payload?.cat1] = tempArr[payload?.cat2];
            tempArr[payload?.cat2] = tempVal;
            state.templateCategory = tempArr;
      },
      swapTemplateSubCategory: (state, { payload }) => {
        
      },
      getCategoryTemplateModuleRequest:(state)=>{
        state.templateLoading=true;

      },
      getCategoryTemplateModuleSuccess:(state,{payload})=>{
        state.AllTemplateCategoryModuleList=payload
        state.templateLoading=false
    },
    getCatgeoryTemplateModuleFail:(state,{payload})=>{
      state.templateLoading=false;
      state.AllTemplateCategoryModuleList=[]
    },
    getTemplateCategoryListFail:(state,{payload})=>{
        state.templateLoading=false
    },
    }
})

 export default templateSlice.reducer
 export const {getCategoryTemplateModuleRequest,getCategoryTemplateModuleSuccess,getCatgeoryTemplateModuleFail,getTemplateCategoryListFail,getTemplateListFail,setTemplateSwapCategory,setTemplateCategoryElement,setTemplateTotalElement,getTemplateListLoading,getTemplateListSuccess,getTemplateCategoryFail,getTemplateCategoryLoading,getTemplateCategorySuccess} = templateSlice.actions