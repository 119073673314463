import React, { useEffect, useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
// import logo from "../../assets/images/Logo.png"
import { Link, useNavigate } from "react-router-dom";
import * as routesKey from "../constants/routes"
import { ReactComponent as ConfiguratorIcon } from "../pages/assets/icons/configurator_icon.svg"
import { ReactComponent as NeonIcon } from "../pages/assets/icons/neoncolor_icon.svg"
import { ReactComponent as BackgroundIcon } from "../pages/assets/icons/background_icon.svg"
import { ReactComponent as TemplateIcon } from "../pages/assets/icons/template__icon.svg"
import { ReactComponent as ProductIcon } from "../pages/assets/icons/producticon.svg"
import logo from "../pages/assets/images/mock.png"
// import { ChevronRightIcon } from "@heroicons/react/outline";
// import OtaLogo from "../../assets/images/image 51.png"
import { useDispatch, useSelector } from "react-redux";
// import { setselectedChild, setselectedGrandId, setselectedParent } from "../../redux/slice/authSlice";
const Navigation = () => {
//   const { selectedGrandId, selectedParent, selectedChild } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectId, setSelectId] = useState(1)
  const menus = [
   
/*    
    {
      id:1,
      title: "Home",
      link: routesKey.HOME,
    }, */
    // {
    //   id:2,
    //   title: "Fonts",
    //   link: routesKey.FONTS,
    //   icon: <FontIcon />,
    // },
    

    // {
    //   id:5,
    //   title: "Distressed",
    //   link: routesKey.DISTRESSED,
    //   // icon: <NeonIcon />,
    // },
    // {
    //   id:6,
    //   title: "Image Mask",
    //   link: routesKey.IMAGEMASK,
    //   // icon: <NeonIcon />,
    // },
    // {
    //   id:8,
    //   title: "Clip Part",
    //   link: routesKey.CLIPPART,
    //   // icon: <NeonIcon />,
    // },
    //  {
    //   id:92,
    //   title: "Shape",
    //   link: routesKey.SHAPE,
    //  icon: <ShapeIcon />,
    //  },
    {
      id:32,
      title: "Products",
      link: routesKey.PRODUCT,
      icon: <ProductIcon />,
    },
    {
      id:36,
      title: "Configurator",
      link: routesKey.MANAGECONFIGURATOR,
      icon: <ConfiguratorIcon />,
    },
    {
      id:7,
      title: "Template",
      link: routesKey.TEMPLATE,
      icon: <TemplateIcon />,
    },
    {
      id:3,
      title: "Background",
      link: routesKey.BACKGROUND,
      icon:<BackgroundIcon />
    },
    {
      id:4,
      title: "Color",
      link: routesKey.COLOR,
      icon: <NeonIcon />,
    },
  ]
  
  const handleMenu = (currentObj) => {
    navigate(currentObj.link)
    setSelectId(currentObj?.id)
    // if (currentObj?.isSubChild) {
    //   dispatch(setselectedGrandId(currentObj?.parentId))
    //   dispatch(setselectedParent(currentObj?.subparentId))
    //   dispatch(setselectedChild(currentObj?.id))
    // } else if(currentObj?.parentId)  {
    //   dispatch(setselectedGrandId(currentObj?.parentId))
    //   dispatch(setselectedParent(currentObj?.id))
    //   dispatch(setselectedChild(null))
    // } else {
    //   dispatch(setselectedGrandId(currentObj?.id))
    //   dispatch(setselectedParent(null))
    //   dispatch(setselectedChild(null))
    // }
  }
  return (
    <div className="flex flex-col justify-start items-start h-full bg-blue">
    <div className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 h-16 gap-2.5 pl-6 pt-4">
      <div className="flex justify-start items-center  flex-grow-0 flex-shrink-0 relative overflow-hidden gap-[3px]">
        <p className="flex-grow-0 flex-shrink-0 text-xl  font-medium text-left capitalize">
         {<img alt="" className="h-16 w-16 object-contain" src={logo} />}
        </p>
      </div>
    </div>
    <div className="flex flex-col justify-between items-start flex-grow-0 flex-shrink-0 w-60 gap-1 p-3 min-h-full">
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">

      {menus.map((menu) => (
        // menu.children && menu.children.length > 0 ? (
        false? (
          <Disclosure key={menu.id} defaultOpen={true}>
            {({ open }) => (
              <div
                className={`${open ? "bg-white/5 transform" : ""
                  } flex flex-col self-stretch rounded-lg hover:bg-white/5 focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75 transition-all   `}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleMenu(menu)
                  }}
              >
                <Disclosure.Button className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 pl-4 pr-3 py-2.5 rounded-lg">
                    <div className="flex justify-start items-center flex-grow relative gap-4">
                      {menu.icon}
                      <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                        {menu.title}
                      </p>
                    </div>
                    {/* <ChevronRightIcon
                      className={`${
                        open ? "rotate-90 transform" : ""
                        } h-4 w-4 text-white transition-all`}
                    /> */}
                  </div>
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 p-2 pt-0">
                    {menu.children.map((submenu) => (
                      submenu?.children && submenu?.children?.length ?  <Disclosure key={submenu.id} defaultOpen={true}>
            {({ open }) => (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                  e.stopPropagation();
                              handleMenu(submenu)
                            }}
                className={`${open ? "bg-white/5 transform" : ""
                  } flex flex-col self-stretch rounded-lg hover:bg-white/5 focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75 transition-all   `}
              >
                <Disclosure.Button className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 pl-4 pr-3 py-2.5 rounded-lg">
                    <div className="flex justify-start items-center flex-grow relative gap-4">
                      {submenu.icon}
                      <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                        {submenu.title}
                      </p>
                    </div>
                    {/* <ChevronRightIcon
                      className={`${
                        open ? "rotate-90 transform" : ""
                        } h-4 w-4 text-white transition-all`}
                    /> */}
                  </div>
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 p-2 pt-0">
                    {submenu.children.map((subsubmenu) => (
                      <React.Fragment key={subsubmenu.id}>
                        <Link to={subsubmenu.link} className={`flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 relative pl-[48px] pr-3 py-3 rounded hover:bg-white/5 transition-all ${true && "bg-white/5"}`} onClick={(e) => {
                           e.preventDefault();
                  e.stopPropagation();
                          handleMenu(subsubmenu)
                        }}>
                          <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                            {subsubmenu.title}
                          </p>
                        </Link>
                        {subsubmenu.children && subsubmenu.children.length > 0 && (
                          <div className="ml-6">
                            {subsubmenu.children.map((subsub) => (
                              <Link to={subsub.link} key={subsub.id} className={`flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 relative pl-[48px] pr-3 py-3 rounded hover:bg-white/5 transition-all ${true && "bg-white/5"}`} onClick={(e) => {
                                 e.preventDefault();
                  e.stopPropagation();
                                handleMenu(subsub)
                              }}>
                                <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                                  {subsub.title}
                                </p>
                              </Link>
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>:   <Link
                          to={submenu.link}
                          onClick={(e) => {
                            e.preventDefault();
                  e.stopPropagation();
                            handleMenu(submenu)
                          }}
            className={`flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 px-4 py-2.5 rounded-lg hover:bg-white/5 ${true&&"bg-white/5"}  `}
          >
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
              {submenu.icon}
              <p className="flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white">
                {submenu.title}
              </p>
            </div>
          </Link>
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
        ) : (
          <Link
              to={menu.link}
              onClick={(e) => {
                e.preventDefault();
                  e.stopPropagation();
                handleMenu(menu)
              }}
            className={`flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 px-4 py-2.5 rounded-lg hover:bg-white/5 ${menu?.id===selectId && ""}    `}
          >
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
              {menu.icon}
              <p className="flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white">
                {menu.title}
              </p>
            </div>
          </Link>
        )
      ))}
      
      </div>
    </div>
  </div>
  
  );
};

export default Navigation;
