import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { MdDelete } from 'react-icons/md';
import axiosConfig from '../../axios_config/axios_instance';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getConfiguratorDetails } from '../../services/api';

const DeleteConfirmationModal = ({id,isVariation}) => {
    const [isopen, setisopen] = useState(false)
    function openModal() {
        setisopen(true);
    }
    function closeModal() {
        setisopen(false);
    }
    const [isLoading, setisLoading] = useState(false)
    const dispatch = useDispatch();
    const { id:configurator_id } = useParams();
    const handleYes =async() => {
        try {
            let url = '';
            if (isVariation) {
                url=`/configurator/delete-part-and-variation?variation_id=${id}`
            } else {
                url=`/configurator/delete-part-and-variation?part_id=${id}`
            }
            setisLoading(true);
            const { data } = await axiosConfig.delete(url);
            if (data?.status === "success") {
                toast.success(data?.message);
                setisopen(false)
                setisLoading(false);
                dispatch(getConfiguratorDetails(configurator_id))
            } else {
                toast.error(data?.message);
                setisLoading(false);
            }
        } catch (error) {
            toast.error("Some error occured!");
            setisLoading(false);
        }
    }

  return (
    <>
    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative py-1">
      {
                  <button onClick={openModal}>
                  <MdDelete fill='red' className='h-6 w-6 cursor-pointer' />
        </button>
      }
    </div>
    <Transition appear show={isopen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                   <Dialog.Panel className="w-screen overflow-y-auto transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all md:max-w-md mx-4">
                <div className="flex flex-col w-full gap-2">
                  <div className="flex flex-col p-6 md:pl-8 gap-1">
                    <h2 className="flex text-xl md:text-2xl font-medium md:font-semibold">
                      Are you sure you want to delete?
                    </h2>
                  </div>
                  <div className="flex justify-around md:justify-end items-center px-6 pb-6 gap-4 md:gap-2">
                    <button
                      className="btn-primary self-stretch flex-grow md:flex-grow-0 md:w-24 md:rounded-lg md:py-2  text-white hover:bg-black/20"
                                              onClick={closeModal}
                                              style={{background:"gray"}}
                    >
                      No
                    </button>
                    <button
                      className="btn-primary self-stretch flex-grow md:flex-grow-0 md:w-24 md:rounded-lg md:py-2"
                                              onClick={() => handleYes()}
                                              disabled={isLoading}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  </>
  )
}

export default DeleteConfirmationModal