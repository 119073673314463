import { useState } from "react";
import * as Yup from "yup";
import { MdOutlineEdit } from "react-icons/md";

import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import axiosConfig from "../../../axios_config/axios_instance";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { getAllProductCategoryList } from "../../../services/apiService";
const EditProductCategory = ({productcategoryEdit,page}) => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const [addCategoryLoading, setaddCategoryLoading] = useState(false)

  //intialData for formik
  const initialValues = {
    product_category_name: productcategoryEdit?.product_category_name,
    product_category_desc: productcategoryEdit?.description,
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    product_category_name: Yup.string().trim()
    .required("Please enter your name"),
    product_category_desc: Yup.string().trim()
    .required("required")
    // equipment_image: Yup.string().trim(),
  });

  //formik validation
  const { values,resetForm, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: EditInformationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit: async(values, action) => {
        try {
          setaddCategoryLoading(true);
          const formdata= new FormData()
          formdata.append("product_category_name", values?.product_category_name);
          formdata.append("description", values?.product_category_desc);
          formdata.append("sort_order",productcategoryEdit?.sort_order);
          formdata.append("product_category_id",productcategoryEdit?.product_category_id)
          const {data}=await axiosConfig.put(`/edit-product-category`,formdata)
          if (data?.status === "success") {
            toast.success(data?.message);
            setShowModal(false);
           dispatch(getAllProductCategoryList(page-1));
            setaddCategoryLoading(false);
          } else {
            toast.error(data?.message);
          setaddCategoryLoading(false);
          }
        } catch (error) {
          toast.error(error?.response?.data?.message || "Can't proceed your request right now!");
          setaddCategoryLoading(false);
        }

    
      },
    });
    const closeModal = () => {
      setShowModal(false);
    };
  


    return (
      <>
      <div
      onClick={(e) => {
        e.stopPropagation();
        setShowModal(true);
      }}
      className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3 group/edit invisible  group-hover/item:visible"
    >
      <div
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 "
                >
                    <MdOutlineEdit className='h-4 w-4 cursor-pointer' />
      </div>
    </div>
       {showModal ? (
         <>
         <div className="">
         <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
           <div className="relative p-4 w-full max-w-md max-h-full">
             {/* Modal content */}
             <div className="relative  rounded-lg shadow bg-gray-200">
               {/* Modal header */}
               <div className="flex items-center justify-between p-4 md:p-2 border-b rounded-t bg-[#454546]">
                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Edit Category
                 </h3>
                 <button
                   onClick={closeModal}
                   type="button"
                   className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                 >
                   <svg
                     className="w-3 h-3"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 14 14"
                   >
                     <path
                       stroke="currentColor"
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       strokeWidth="2"
                       d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                     />
                   </svg>
                 </button>
               </div>
               {/* Modal body */}
               <div className="p-4 md:p-5">
                 <div className="grid gap-4 mb-4 grid-cols-2">
 
                   <div className="col-span-2">
                   <div className="flex flex-col ">
   
           
     
                   </div>
                   
                   </div>
                   <div className="col-span-2">
                     <label
                       htmlFor="name"
                       className="block mb-2 text-sm  text-left font-medium text-gray-900"
                     >
                        Name
                     </label>
                     <input
                     className="form-input w-full h-8 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                     type={"text"}
                     placeholder="Enter Name"
                     value={values.product_category_name}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     name="product_category_name"
                     style={
                       errors.product_category_name &&
                       touched.product_category_name
                         ? { border: "1px solid red" }
                         : {}
                     }
                   />
                   </div>
 
                   <div className="col-span-2 sm:col-span-1">
                   <label className="block mb-2 text-left text-sm font-medium text-gray-900">
                     Description
                   </label>
                   <div className="flex flex-col flex-grow w-[370px]">
                   <textarea
                     type="text"
                     className="form-input w-full h-40 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                     placeholder="Type here"
                     value={values.product_category_desc}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     name="product_category_desc"
                     style={
                       errors.product_category_desc &&
                       touched.product_category_desc
                         ? { border: "1px solid red" }
                         : {}
                     }
                 ></textarea>
              
                 </div>
                 </div>
             
                 </div>
                 <button
                   type="submit"
                   onClick={handleSubmit}
                   className="text-white flex justify-start items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                 >
                   Save
                 </button>
               </div>
             </div>
           </div>
         </div>
       </div>
         </>
       ) : null}
     </>
    );
  };
  
  export default EditProductCategory;