/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import * as Yup from "yup";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
// import axiosConfig from "../../axios_config";
import Files from "react-files";
import { useFormik } from "formik";
import { WithContext as ReactTags } from "react-tag-input";

import { toast } from "react-hot-toast";
// import { getAllBackgroundImage } from "../../services/apiService1";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axiosConfig from "../../axios_config/axios_instance";
import { getAllBackgroundCategoryAllModule, getBackgrounds } from "../../services/api";

const AddBackground = () => {
  const [showModal, setShowModal] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([])
  const {backgroundCategoryList,backgroundList,AllBackgroundCategoryModuleList} = useSelector(state=>state.background)
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    thumb: Yup.string().required("Name is required"),
    // price: Yup.string().required("Price is required"),
    category: Yup.number().required("Category is required"),
  });

  const dispatch = useDispatch();

 const initialValues= {
    name: "",
    thumb: "",
    category:""
  }
  const Categoryoptionss = AllBackgroundCategoryModuleList?.map(
    ({ category_id, category_name }) => ({
      value: category_id,
      label: category_name,
    })
  );
  useEffect(()=>{
    if(!backgroundCategoryList?.length){
      dispatch(getAllBackgroundCategoryAllModule())

    }
  },[dispatch])
  const { values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched, } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (backgroundImage) {
        try {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("background_image", backgroundImage);
          formData.append("background_name", values.name);
          formData.append("category_id", values.category);
          formData.append("thumb", values.thumb);
          formData.append("price", 0);
          formData.append("tags", tags.map(({ text }) => text).join(","));
          formData.append("sort_order", backgroundList?.length+1);

          const { data } = await axiosConfig.post(`/save-background`, formData);
          if (data?.status === "success") {
            toast.success(data?.message || "BackgroundImage added successfully");
            setShowModal(false);
            setIsLoading(false);
            dispatch(getBackgrounds(1));
            setBackgroundImage("");
            resetForm();
          } else {
            toast.error(data?.message || "Something went wrong");
           // console.log(data);
            setIsLoading(false);
            setBackgroundImage("");
          }
        } catch (error) {
          toast.error("Can't proceed with your request right now!!");
          setIsLoading(false);
        }
      } else {
        toast.error("Upload at least one image of Background");
      }
    },
  });

  const handleImageChange = (files) => {
    if (files.length) {
      setBackgroundImage(files[0]);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };


  const suggestions = [];
const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];
const handleAddition = (tag) => {
  if (tags.length === 5) {
    toast.error("You can not add more than 5 search tags");
    return;
  }
  setTags([...tags, tag]);
};

const handleDelete = (i) => {
  setTags(tags.filter((tag, index) => index !== i));
};

const handlePrice=(event)=>{
  
  const inputValue = event.target.value;
  // Validate if the input is a valid number
  if (/^-?\d*$/.test(inputValue)) {
    setFieldValue("price",inputValue.replace(/[^0-9]/g, ''));
  }
};

  return (
    <>
      <div
        className="flex justify-start items-center flex-grow-0 flex-shrink-0"
        onClick={openModal}
      >
        <button className="text-lg" disabled={isLoading}>
          <div className="flex items-center">
            <IoIosAdd /> Add Background
          </div>
        </button>
      </div>
      {showModal ? (
        <div className="fixed bottom-0 left-20 w-full h-full flex items-center justify-center z-50">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row justify-between w-full bg-[#454546] p-3 items-center rounded">
              <div>
                <h2 className="text-white text-lg font-medium">
                  Upload Background
                </h2>
              </div>
              <div className="items-center">
                <button className="text-white " onClick={closeModal}>
                  <RxCross1 size={20} />
                </button>
              </div>
            </div>
            <div className="flex flex-row justify-center items-start bg-white p-4">
              <div className="flex flex-col w-1/2 p-4">
                <div className="mb-4">
                  <div className="w-[280px] h-64 border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex flex-col items-center justify-center">
                    <Files
                      onChange={(file) => handleImageChange(file)}
                      accepts={[".jpg", ".jpeg", ".png"]}
                      maxFileSize={5000000}
                      minFileSize={0}
                      clickable
                    >
                      {backgroundImage ? (
                        <img
                        alt=""
                          src={
                            typeof backgroundImage === "object"
                              ? backgroundImage?.preview?.url
                              : backgroundImage
                          }
                          className="h-64  w-[280px] flex justify-center object-cover"
                        />
                      ) : (
                        <label className="flex flex-col flex-shrink-0 flex-grow-0 justify-center items-center">
                          <BsFillCloudArrowUpFill
                            size={100}
                            className="text-black"
                          />
                          <span className="mt-2 text-base bg-[#454546] hover-bg-slate-600 rounded-lg w-full m-3 p-2 text-center text-white leading-normal cursor-pointer">
                            Upload Image
                          </span>
                        </label>
                      )}
                    </Files>
                    {
                      !backgroundImage&&
                    <span className="font-semibold">
                      (Only JPG, PNG Supported)
                    </span>
                    }
                  </div>
                
                </div>
              </div>
              <div className="flex flex-col w-96 gap-4 justify-start mt-4">
                <div >
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Name
                        </label>
                  <input
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={errors.name&&touched.name?{border:"1px solid red"}:{}}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Background name"
                  />
                  {/* {touched.name && errors.name ? (
                    <div className="text-red-600 text-xs">{errors.name}</div>
                  ) : null} */}
                </div>
                <div >
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Thumb
                        </label>
                        <Files
                              className="files-dropzone"
                              onChange={(file) => {
                                if (file.length) {
                                  setFieldValue("thumb",file[0])
                             }
                         }}
                          onError={(err) => toast.error(err.message)}
                          accepts={[
                            "image/png",
                            "image/jpg",
                            "image/jpeg",
                          ]}
                          maxFileSize={2000000}
                    minFileSize={0}
                              >
                    <div id="file-uploader2" class="wrapper js" className='h-max w-full mt-0'
                    style={errors?.thumb&&touched.thumb?{border:"1px solid red"}:{}}
                    >    
<form class="js--upload-form is-droppable " style={{margin:"0px"}}>
		<span class="text--center">
			<i class="fa fa-cloud-upload fa-2"></i>
                                          {
                                              values?.thumb ? <>
                                                  <span class="js--advanced-upload">{ `${values.thumb.name?.split(".")[0]?.slice(0,20)}. ${values.thumb.name?.split(".")?.pop()}`} <label class="btn btn--link" for="file">replace</label></span>
                                            </>:<span class="js--advanced-upload">Choose file <label class="btn btn--link" for="file">browse</label></span>
            }
			<span type="file" name="files[]" id="file" class="js--basic-upload hide" data-multiple-caption="{count} files selected" multiple="" accept=".pdf,.xlsx" />
		</span>
	</form>
                        </div>
                       </Files>
                </div>
                <div className="pt-2">

                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Category
                        </label>
                        <select
                        name="category"
                        onChange={(e)=>{
                          setFieldValue("category","")
                          handleChange(e)
                        }}
                        onBlur={() => handleBlur("category")}
                        value={values.category}
                        placeholder="select category"
                        style={
                          errors.category && touched.category
                            ? { border: "1px solid red" }
                            : {}
                        }
                        className=" text-black border capitalize text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400  "
                        >
                        <option>Select</option>
                        {AllBackgroundCategoryModuleList?.map((option,i) => (
                          <option key={i} className="text-black capitalize  bg-gray-200  " value={option.category_id}>
                           {option?.category_name}
                          </option>
                        ))}
                      </select>
                </div>
                 

                {/* tag design */}
                <div>
                <label  className="block text-base  text-gray-700 font-bold"
                    htmlFor="name">
                          Tag
                        </label>
                <div className="h-[100px] ">

                <ReactTags
                          inline={false}
                          tags={tags}
                          suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          inputFieldPosition="inline"
                          autocomplete
                          maxLength={15}
                          classNames={{
                            tags: "block h-full w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:b dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none",
                            tagInput: "w-full h-full focus:outline-none",
                            tagInputField:
                              "w-full focous focus:outline-none text-black text-sm rounded-lg p-2.5 dark: dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-none",
                            selected: "flex flex-wrap",
                            tag: "border border-gray-300 bg-gray-300 text-gray-900 text-sm rounded-md p-2.5 m-1",
                            remove: "ml-2 cursor-pointer",
                            suggestions:
                              "absolute bg-white border rounded-lg shadow w-200",
                            activeSuggestion:
                              "bg-blue-500 text-white cursor-pointer",
                          }}
                        />
                </div>
                </div>

                <div className="flex justify-end mt-6">
                  <button
                    className="bg-blue-500 p-2 w-[34%] hover-bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={isLoading}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
};

export default AddBackground;
