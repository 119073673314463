import { createSlice } from "@reduxjs/toolkit";

const NewFontList = createSlice({
    name: "fontlist",
    initialState: {
        FontListLoading: false,
        FontList:[],
        page:1,
        total_pages:0,
        TotalFontList:0,
      
    },
    reducers: {
         getFontListRequest:(state)=>{
            state.FontListLoading=true;
         },
         getFontListSuccess:(state, {payload})=>{
            state.FontListLoading=false;
            state.FontList=payload?.font_catgeory_List;
            state.TotalFontList=payload?.total_elements
         },
         getFontListFail:(state)=>{
            state.FontListLoading=false;
            state.FontList=[];
         },
     
    }
})  

export default NewFontList.reducer;
export const {getFontListFail, getFontListRequest, getFontListSuccess}= NewFontList.actions