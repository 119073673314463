import React, { useEffect, useState } from "react";
import {ReactComponent as EditIcon} from "../../assets/icons/pencil_icon.svg"
import {ReactComponent as AddIcon} from "../../assets/icons/addicon.svg"
import {ReactComponent as UploadIcon} from "../../assets/icons/uploadicon.svg"
import { Link } from "react-router-dom";
import * as Yup from "yup"
import Template from "../../../components/Template";
import * as routesKey from "../../../constants/routes";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ChevronRightIcon, PauseIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllProductCategoryList,
  getAllSubProductCategoryList,
} from "../../../services/apiService";
import axiosConfig from "../../../axios_config/axios_instance";
import Pagination from "../../../components/pagination";
import { ProductswapCategories } from "../../../redux/slice/ProductSlice";
import { getTemplateCategory } from "../../../services/api";
import { IoIosAdd } from "react-icons/io";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import EnableDisableModal from "../../../components/common/EnableDisableModal";
const ManageProductCategory = () => {
  const [expandedCategoryId, setexpandedCategoryId] = useState(0);
  const [page, setpage] = useState(1);
  const dispatch = useDispatch();

  const { productCategoryList, TotalProductCategoryList } = useSelector(
    (state) => state.product
    );
    const {templateCategory,total_template_category} = useSelector(state=>state.template)
    
    useEffect(()=>{
          dispatch(getTemplateCategory(page))
      },[dispatch,page])

  const { SubProductList } = useSelector((state) => state.product);


  


  const handleExpand = (id) => {
    if (id === expandedCategoryId) {
      setexpandedCategoryId(null);
    } else {
      setexpandedCategoryId(id);
    }
  };


  const handleDragEnd = async (e) => {
    if (!e.destination) {
      return;
    }
    const { destination, source,draggableId } = e;
    // dispatch(ProductswapCategories({ cat1: destination?.index, cat2: source?.index }));
    
    const tempArr = [...templateCategory[draggableId?.split("-")[1]].template_sub_category_list]
    const category1 = tempArr[destination?.index]?.template_sub_category_id;
    const category2 = tempArr[source.index]?.template_sub_category_id;
    try {
      const { data } = await axiosConfig.post(
        `/swap-template-sub-category-sort-order?sub_category_id1=${category1}&sub_category_id2=${category2}`
      );
      if (data?.status === "success") {
        dispatch(getTemplateCategory(page - 1));
      }
    } catch (error) {}
  };
const [loading, setLoading] = useState(false)
  const handleDelete=async (id,setshowModal)=>{
    try {
      setLoading(true)
      const {data} = await axiosConfig.delete(`/status-template-category-change?template_category_id=${id}`)
      if(data?.status==="success"){
       dispatch(getTemplateCategory(page))
       setshowModal(false)
       toast.success("Updated successfully")
      }else{
       toast.error("Something went wrong !!")
      }

      setLoading(false)
    } catch (error) {
     toast.error("Something went wrong !!")
     setLoading(false)
    }
}
  const handleSubCategoryDelete=async (id,setshowModal)=>{
    try {
      setLoading(true)
      const {data} = await axiosConfig.delete(`/status-template-sub-category-change?template_sub_category_id=${id}`)
      if(data?.status==="success"){
       dispatch(getTemplateCategory(page))
       setshowModal(false)
       toast.success("Updated successfully")
      }else{
       toast.error("Something went wrong !!")
      }

      setLoading(false)
    } catch (error) {
     toast.error("Something went wrong !!")
     setLoading(false)
    }
}

  return (
    <Template>
      <div className="bg-gray-200 flex justify-start w-full">
        <div className="flex justify-start gap-2 p-2">
          <p>Home</p>/
          <Link to={routesKey.TEMPLATE}>
            <p>Template</p>
          </Link>
          /<p>category</p>
        </div>
      </div>

      <>
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-center capitalize text-[#202223]">
              Template Categories
            </p>
          </div>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <AddCategory />
          </div>
        </div>
        <>

                    {templateCategory?.length ? (
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[65vh]">
                          <table class="table-auto w-full">
                            <thead>
                              <tr>
                                <th className="border-b border-black/10 text-xs font-semibold px-4 pl-0 py-3 text-center">
                                  SL NO
                                </th>
                                <th className="border-b border-black/10 text-xs font-semibold px-4 pl-0 py-3 text-left">
                                  Category Name
                                </th>
                                {/* <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                                  Description
                                </th> */}
                                <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {templateCategory?.map((category, index) => (
                                <Fragment key={index}>
                                  <tr className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5">
                                    <td className="text-sm p-4 pl-0 text-center ">
                                      {index+1}
                                  </td>
                                    <td className="text-sm p-4  text-center ">
                                      <div className="flex items-center gap-3">
                                      <PauseIcon className="w-4 h-4" />
                                        <div className="flex flex-col">
                                          <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#202223]">
                                            {category?.template_category_name}
                                          </p>
                                          <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#202223]">
                                            sub category:
                                            {category?.template_sub_category_list?.length}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    {/* <td className="text-sm p-4 text-center">
                                      <p className="flex-grow text-sm text-left text-[#202223]">
                                        {category?.description}
                                      </p>
                                    </td> */}
                                    <td className="text-sm p-4 text-center">
                                      <div className="group/edit  flex justify-end items-center gap-1">
                                   {  category?.is_active&& <EditCategory categoryDetails={category} />}
                                        <EnableDisableModal dontSHow={true}   onConfirm={handleDelete} id={category.template_category_id} isLoading={loading} desc={"Category"} is_active={!category?.is_active} />
                                        <div
                                          onClick={() =>
                                            handleExpand(category?.template_category_id)
                                          }
                                        >
                                          <ChevronRightIcon
                                            className={`${expandedCategoryId ===
                                              category?.template_category_id
                                              ? "rotate-90 transform"
                                              : ""
                                              } h-4 w-4  transition-all cursor-pointer`}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <Droppable  droppableId={`droppable`}>
                                    {(provided) => (
                                      <div
                                        className="contents"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {
                                          expandedCategoryId === category.template_category_id && 
                                          category?.template_sub_category_list?.map(
                                            (sub, i) => (
                                              <Draggable
                                                draggableId={`sub-${index}`}
                                                index={i}
                                                key={sub.template_sub_category_id}
                                              >
                                                {(provided) => (
                                                  <tr
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5"
                                                  >
                                                    <td className="text-xs font-semibold  text-center ">
                                                      { i+1}
                                                    </td>
                                                    <td className="text-sm p-4 pl-0">
                                                      <div className="flex gap-3 items-center">
                                                      <PauseIcon className="w-4 h-4" />
                                                        <div className="flex flex-col">
                                                          <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#202223]">
                                                            {sub?.template_sub_category_name}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    {/* <td className=" text-sm p-4">
                                                      <p className="flex-grow text-sm text-left text-[#202223]">
                                                        {sub?.description}
                                                      </p>
                                                    </td> */}
                                                    <td className="text-sm p-4">
                                                      <div className="group/edit  flex justify-end items-center gap-1">
                                                      {sub?.is_active&&  <EditSubCategory details={sub} parentId={category?.template_category_id} />}
                                                        <EnableDisableModal dontSHow={true}   onConfirm={handleSubCategoryDelete} id={sub.template_sub_category_id} isLoading={loading} desc={"Sub Category"} is_active={!sub?.is_active} />
                                                        {/* <EditSubCategory */}
                                                          {/* item={sub} */}
                                                          {/* parentIndex={index} */}
                                                          {/* parentId={category?.cat_id} */}
                                                        {/* /> */}
                                                        {/* <DeleteCategory category={sub} id_key="sub_cat_id" /> */}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )}
                                              </Draggable>
                                            )
                                          )}

                                  {expandedCategoryId === category.template_category_id && (
                                                                     <AddSubCategory parentId={category.template_category_id} sub_category_list={category?.template_sub_category_list} /> 
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </DragDropContext>
                    ) : (
                      <div className="flex justify-center items-center h-[70vh] w-full">
                        <span className="text-center w-full text-xl text-[#7b8794]">
                          No Category found
                        </span>
                      </div>
                    )}
                    <div className="flex justify-center w-full p-4 mb-4">
                      {/* <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  ></nav> */}
                      {/* <Pagination
                        className="pagination-bar"
                        currentPage={productCategoryCurrentPage}
                        totalCount={total_product_category}
                        pageSize={postPerPage}
                        onPageChange={(page) => {
                          dispatch(setProductCategoryCurrentPage(page))
                          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                          if (currentScroll > 0) {
                            window.scrollTo(0, currentScroll - (currentScroll / 1));
                          }
                        }
                        }
                      /> */}
                    </div></>
      </>
    </Template>
  );
};

export default ManageProductCategory;

export const AddCategory=()=>{
    const {templateCategory} = useSelector(state=>state.template)
    const [showmodal, setShowModal] = useState(false);
    const [loading, setLoading]=useState(false)
    const closeModal = () => {
      setShowModal(false);
    };

    const dispatch = useDispatch()
  // console.log("lengthhhh",backgroundCategoryList.length);
    const openModal = () => {
      setShowModal(true);
    };
  
    const validationSchema = Yup.object().shape({
      categoryname: Yup.string().trim().required("categoryname is required"),
      // enableanddisablestatus:Yup.string().required("status is required"),
  });
  
  
    const {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      errors,
      touched,
      resetForm,
      setFieldValue,
    } = useFormik({
      initialValues: {
     
        categoryname:"",
        enableanddisablestatus:"",
      },
      validationSchema: validationSchema,
      enableReinitialize:true,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: async (values, action) => {
         try {
          setLoading(true)
         const {data} = await axiosConfig.post(`/save-template-category?template_category_name=${values.categoryname}&sort_order=${templateCategory?.length+1}`)
         if(data?.status==="success"){
          setLoading(false)
           toast.success("Category saved successfully")
           resetForm()
           closeModal()
           dispatch(getTemplateCategory())
         }else{
          setLoading(false)
          toast.error("Something went wrong")
         }         
         } catch (error) {
          setLoading(false)
            toast.error("Something went wrong")
         }
      },
    });
  
    const EnableandDisableStatuschanges = [
      { label: 'enable', value: 0 },
      { label: 'disable', value: 1 },
   
     
    ];

    return (
      <div>
      <div   onClick={openModal} className='flex justify-start items-center p-1 flex-grow-0 flex-shrink-0 cursor-pointer bg-gray-400'>
      <button className='text-[18px] font-normal'>
        <div className='flex items-center'>
          <IoIosAdd className='mr-1' />Add Category
        </div>
      </button>
    </div>
    {showmodal ? (
      <div className="">
        <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-40 bg-black">
          <div className="relative p-4 w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative  rounded-lg shadow dark:bg-slate-100">
              {/* Modal header */}
              <div className="flex items-center bg-black justify-between px-4 py-2 border-b rounded-t text-white dark:border-gray-600">
                <h3 className="text-lg font-semibold text-white">
                  Add New Category
                </h3>
                <button
                  onClick={closeModal}
                  disabled={loading}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5 bg-white">
                <div className="grid gap-4 mb-4 grid-cols-2">
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Category Name
                        </label>
                        
                    <input
                    style={errors.categoryname&&touched.categoryname&&{border:"1px solid red"}}
                      type="text"
                      value={values.categoryname}
                      name="categoryname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  ${errors.categoryname&&touched.categoryname?"dark:border-red-500":"dark:border-gray-500"} dark:placeholder-gray-400 text-black dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                      placeholder="Category Name"
                    />
                  </div>
                </div>
                <button
                onClick={handleSubmit}
                disabled={loading}
                  type="submit"
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null}
      </div>
    )
}
  
export const EditCategory=({categoryDetails})=>{
  const {backgroundCategoryList} = useSelector(state=>state.background)
  const [showmodal, setShowModal] = useState(false);
  const [loading, setLoading]=useState(false)
  const closeModal = () => {
    setShowModal(false);
  };

  const dispatch = useDispatch()
// console.log("lengthhhh",backgroundCategoryList.length);
  const openModal = () => {
    setShowModal(true);
  };

  useEffect(()=>{
    if(categoryDetails){
      setFieldValue("categoryname",categoryDetails?.template_category_name)
    }

  },[categoryDetails])

  const validationSchema = Yup.object().shape({
    categoryname: Yup.string().trim().required("categoryname is required"),
    // enableanddisablestatus:Yup.string().required("status is required"),
});


  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
   
      categoryname:"",
      enableanddisablestatus:"",
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
       try {
        setLoading(true)
       const {data} = await axiosConfig.put(`/update-template-category?template_category_name=${values.categoryname}&sort_order=${categoryDetails?.sort_order}&template_category_id=${categoryDetails?.template_category_id}`)
       if(data?.status==="success"){
        setLoading(false)
         toast.success("Category updated successfully")
         resetForm()
         closeModal()
         dispatch(getTemplateCategory())
       }else{
        setLoading(false)
        toast.error("Something went wrong")
       }         
       } catch (error) {
        setLoading(false)
          toast.error("Something went wrong")
       }
    },
  });

  const EnableandDisableStatuschanges = [
    { label: 'enable', value: 0 },
    { label: 'disable', value: 1 },
 
   
  ];

  return (
    <div>
          <EditIcon className="w-4 h-4 cursor-pointer" onClick={openModal} />

  {showmodal ? (
    <div className="">
      <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-40 bg-black">
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative  rounded-lg shadow bg-slate-100">
            {/* Modal header */}
            <div className="flex items-center bg-black justify-between px-4 py-2 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg  font-semibold text-white">
                Edit  Category
              </h3>
              <button
                onClick={closeModal}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5 bg-white">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm text-start font-medium text-gray-900 "
                  >
                    Category Name
                  </label>
                  <input
                  style={errors.categoryname&&touched.categoryname&&{border:"1px solid red"}}
                    type="text"
                    value={values.categoryname}
                    name="categoryname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  ${errors.categoryname&&touched.categoryname?"dark:border-red-500":"dark:border-gray-500"} dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                    placeholder="Category Name"
                    required=""
                  />
                </div>
              </div>
              <button
              onClick={handleSubmit}
              disabled={loading}
                type="submit"
                className="text-white  inline-flex items-start bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-start dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null}
    </div>
  )
}
export const AddSubCategory=({parentId,sub_category_list})=>{
  const [showmodal, setShowModal] = useState(false);
  const [loading, setLoading]=useState(false)
  const closeModal = () => {
    setShowModal(false);
  };

  const dispatch = useDispatch()
  const openModal = () => {
    setShowModal(true);
  };

  const validationSchema = Yup.object().shape({
    categoryname: Yup.string().trim().required("categoryname is required"),
});


  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
   
      categoryname:"",
      enableanddisablestatus:"",
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
       try {
        setLoading(true)
       const {data} = await axiosConfig.post(`/save-template-sub-category?template_sub_category_name=${values.categoryname}&sort_order=${sub_category_list?.length+1}&template_category_id=${parentId}`)
       if(data?.status==="success"){
        setLoading(false)
         toast.success("Category saved successfully")
         resetForm()
         closeModal()
         dispatch(getTemplateCategory())
       }else{
        setLoading(false)
        toast.error("Something went wrong")
       }         
       } catch (error) {
        setLoading(false)
          toast.error("Something went wrong")
       }
    },
  });

  const EnableandDisableStatuschanges = [
    { label: 'enable', value: 0 },
    { label: 'disable', value: 1 },
 
   
  ];

  return (
    <div>
        <tr className="group/item bg-white  transition-all border-b border-black/5 cursor-pointer w-full"  onClick={openModal}>
                          <td className="text-xs font-semibold pl-10 text-left">
                        <UploadIcon />
                      </td>
                          <td className="text-sm p-4 pl-0">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#186CED]">
                          add sub category
                        </p>
                      </td>
                          </tr>
  {showmodal ? (
    <div className="">
      <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-40 bg-black">
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative  rounded-lg shadow dark:bg-slate-100">
            {/* Modal header */}
            <div className="flex items-center bg-black justify-between px-4 py-2 border-b rounded-t text-white dark:border-gray-600">
              <h3 className="text-lg font-semibold text-white">
                Add Sub Category
              </h3>
              <button
                onClick={closeModal}
                disabled={loading}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5 bg-white">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                     Name
                      </label>
                      
                  <input
                  style={errors.categoryname&&touched.categoryname&&{border:"1px solid red"}}
                    type="text"
                    value={values.categoryname}
                    name="categoryname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  ${errors.categoryname&&touched.categoryname?"dark:border-red-500":"dark:border-gray-500"} dark:placeholder-gray-400 text-black dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                    placeholder=" Name"
                  />
                </div>
              </div>
              <button
              onClick={handleSubmit}
              disabled={loading}
                type="submit"
                className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null}
    </div>
  )
}
export const EditSubCategory=({parentId,sub_category_list,details})=>{
  const [showmodal, setShowModal] = useState(false);
  const [loading, setLoading]=useState(false)
  const closeModal = () => {
    setShowModal(false);
  };

  const dispatch = useDispatch()
  const openModal = () => {
    setShowModal(true);
  };

  const validationSchema = Yup.object().shape({
    categoryname: Yup.string().trim().required("categoryname is required"),
});


  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
   
      categoryname:details?.template_sub_category_name,
      enableanddisablestatus:"",
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
       try {
        setLoading(true)
       const {data} = await axiosConfig.put(`/update-template-sub-category?template_sub_category_name=${values.categoryname}&sort_order=${details?.sort_order}&template_category_id=${parentId}&template_sub_category_id=${details?.template_sub_category_id}`)
       if(data?.status==="success"){
        setLoading(false)
         toast.success("Category saved successfully")
         resetForm()
         closeModal()
         dispatch(getTemplateCategory())
       }else{
        setLoading(false)
        toast.error("Something went wrong")
       }         
       } catch (error) {
        setLoading(false)
          toast.error("Something went wrong")
       }
    },
  });

  const EnableandDisableStatuschanges = [
    { label: 'enable', value: 0 },
    { label: 'disable', value: 1 },
 
   
  ];

  return (
    <div>
      <EditIcon className="w-4 h-4 cursor-pointer " onClick={openModal} />
  {showmodal ? (
    <div className="">
      <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-40 bg-black">
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative  rounded-lg shadow dark:bg-slate-100">
            {/* Modal header */}
            <div className="flex items-center bg-black justify-between px-4 py-2 border-b rounded-t text-white dark:border-gray-600">
              <h3 className="text-lg font-semibold text-white">
                Edit Sub Category
              </h3>
              <button
                onClick={closeModal}
                disabled={loading}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5 bg-white">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Name
                      </label>
                      
                  <input
                  style={errors.categoryname&&touched.categoryname&&{border:"1px solid red"}}
                    type="text"
                    value={values.categoryname}
                    name="categoryname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  ${errors.categoryname&&touched.categoryname?"dark:border-red-500":"dark:border-gray-500"} dark:placeholder-gray-400 text-black dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                    placeholder=" Name"
                  />
                </div>
              </div>
              <button
              onClick={handleSubmit}
              disabled={loading}
                type="submit"
                className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null}
    </div>
  )
}