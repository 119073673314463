import React from 'react'

const AdminSignup = () => {
  return (
    
<div class="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center w-full">
<div
  class="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-full"
>
  <div
    class="p-4 py-6 text-white bg-blue-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly w-[40%]"
  >
    <div class="my-3 text-4xl font-bold tracking-wider text-center">
      <button>MockDownloader</button>
    </div>
    <p class="mt-6 font-normal text-center text-gray-300 md:mt-0">
      With the power of K-WD, you can now focus only on functionaries for your digital products, while leaving the
      UI design on us!
    </p>
    <p class="mt-6 text-sm text-center text-gray-300">
      Read our <a href="#" class="underline">terms</a> and <a href="#" class="underline">conditions</a>
    </p>
  </div>
  <div class="p-5 bg-white md:flex-1">
    <h3 class="my-4 text-2xl font-semibold text-gray-700">Account Login</h3>
    <form action="#" class="flex flex-col space-y-5">
      <div class="flex flex-col space-y-1">
        <label for="email" class="text-sm font-semibold text-gray-500">Email address</label>
        <input
          type="email"
          id="email"
          autofocus
          class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
        />
      </div>
      <div class="flex flex-col space-y-1">
        <div class="flex items-center justify-between">
          <label for="password" class="text-sm font-semibold text-gray-500">Password</label>
          <a href="#" class="text-sm text-blue-600 hover:underline focus:text-blue-800">Forgot Password?</a>
        </div>
        <input
          type="password"
          id="password"
          class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
        />
      </div>
      <div class="flex items-center space-x-2">
        <input
          type="checkbox"
          id="remember"
          class="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200"
        />
        <label for="remember" class="text-sm font-semibold text-gray-500">Remember me</label>
      </div>
      <div>
        <button
          type="submit"
          class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
        >
          Log in
        </button>
      </div>
      <div class="flex flex-col space-y-5">
        <span class="flex items-center justify-center space-x-2">
          <span class="h-px bg-gray-400 w-14"></span>
          <span class="font-normal text-gray-500">or login with</span>
          <span class="h-px bg-gray-400 w-14"></span>
        </span>
        <div class="flex flex-col space-y-4">
          <button
            href="#"
            class="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-gray-800 rounded-md group hover:bg-gray-800 focus:outline-none"
          >
            <span>
             <GoogleIcon />
            </span>
            <span class="text-sm font-medium text-gray-800 group-hover:text-white">Google</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
</div>
  )
}

export default AdminSignup


const GoogleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <path
      d="M5.563 10c0 .375.063.75.125 1.125L6.875 10 5.75 8.875q-.188.563-.188 1.125"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M10 14.438a4.37 4.37 0 0 1-4.25-3.25l-4.188 4.188A10.02 10.02 0 0 0 10 20c1.938 0 3.75-.563 5.313-1.563l-4.188-4.188q-.563.188-1.125.188"
      style={{
        fill: "#34a853",
      }}
    />
    <path
      d="M20 8.625c-.063-.313-.313-.5-.625-.5H10c-.375 0-.625.25-.625.625v3.125c0 .375.25.625.625.625h3.313a4.5 4.5 0 0 1-2.188 1.75l4.188 4.188A9.93 9.93 0 0 0 20 10v-.438q.094-.375 0-.938"
      style={{
        fill: "#4285f4",
      }}
    />
    <path
      d="M5.563 10c0-.375.063-.75.125-1.125L1.563 4.688C.563 6.25 0 8.063 0 10s.563 3.75 1.563 5.313l4.188-4.188q-.188-.563-.188-1.125"
      style={{
        fill: "#fbbc05",
      }}
    />
    <path
      d="M17.813 3.75c-.688-.875-1.563-1.625-2.5-2.25C13.75.563 11.938 0 10 0a9.92 9.92 0 0 0-8.437 4.688l4.188 4.188C6.25 7 8 5.625 10 5.625q.563 0 1.125.188c.563.188 1.063.5 1.625.938.188.188.438.188.688.063l4.188-2.063a.47.47 0 0 0 .313-.438c.063-.188 0-.375-.125-.563"
      style={{
        fill: "#ea4335",
      }}
    />
  </svg>
);

