import {configureStore} from "@reduxjs/toolkit"
import backgroundSlice from "./slice/backgroundSlide"
import fontslice from "./slice/fontslice"
import distressedSlice from "./slice/distressedSlice"
import templateSlice from "./slice/templateSlice"
import clippartSlice from "./slice/clippartSlice"
import ProductSlice from "./slice/ProductSlice"
import ColorSlice from "./slice/ColorSlice"
import ShapeSlice from "./slice/ShapeSlice"
import productListSlice from "./slice/productListSlice"
import adminSlice from "./slice/adminSlice"
import configuratorSlice from "./slice/configuratorSlice"
export const store= configureStore({
    reducer:{
        background:backgroundSlice,
        fontlist:fontslice,
        distressed:distressedSlice,
        template:templateSlice,
        clippart:clippartSlice,
        product:ProductSlice,
        colorlist:ColorSlice,
        shape:ShapeSlice,
        productList:productListSlice,
    admin: adminSlice,
        configurator:configuratorSlice
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})