import { useState } from "react";

import { useDispatch } from "react-redux";
import axiosConfig from "../../../axios_config/axios_instance";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { getAllShapeCategory } from "../../../services/apiService";
import { getAllProductCategoryList, getAllShapeSubCategoryList, getAllSubProductCategoryList } from '../../../services/apiService';
import { ReactComponent as ActiveIcon } from "../../assets/icons/Activeicon.svg";

const ShapeInActiveCategory = ({page, setpage,ShapeproductCategory}) => {
  const [showModal, setShowModal] = useState(false);
 
  const dispatch = useDispatch();
  const [addCategoryLoading, setaddCategoryLoading] = useState(false)
  const {productCategoryList}=useSelector((state)=>state.product)
  const [loading,setIsLoading]=useState(false)

  //Yup validator Schema


   //formik validation
   const CatgeoryhandleActiveandInactive = async () => {
    try {

     const { data } = await axiosConfig.delete(`/status-shape-category-change?shape_category_id=${ShapeproductCategory}`)
     if(data?.status==="success"){
      toast.success(" updated Successfully")
      setShowModal(false)
      if(productCategoryList?.length===1&&page>1){
        dispatch(getAllShapeCategory(page-2))
        setpage(page-1)
      } else { 
        dispatch(getAllShapeCategory(page-1))
      }
      } else {
        toast.error(data?.message || "Something went wrong");
        setIsLoading(false);
      }
   
     } catch (error) {
      console.log("errors",error)

      toast.error("Error deleteing Gallery image:", error);
    }
  };
    const closeModal = () => {
      setShowModal(false);
    };
  

    const openModal = () => {
      setShowModal(true);
    }
    return (
      <>
      <div
      onClick={(e) => {
        e.stopPropagation();
        setShowModal(true);
      }}
      className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3 group/edit invisible  group-hover/item:visible"
    >
      <div
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 "
                >
                    <ActiveIcon className='h-6 w-6 cursor-pointer' />
      </div>
    </div>
       {showModal ? (
         <>
         <div className="">
         <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
           <div className="relative p-4 w-full max-w-md max-h-full">
             {/* Modal content */}
             <div className="relative  rounded-lg shadow bg-gray-200">
               {/* Modal header */}
            
               {/* Modal body */}
               <div className="p-4 md:p-5">
                 <div className="">
 
                   <div className="col-span-2">
                   <div className="flex flex-col justify-center items-center ">
                <h2 className="text-[16px] font-semibold">
                  Do you want to disable this product catgeory ?
                </h2>
           
     
                   </div>
                   
                   </div>
            
             
                 </div>
                 <div className="flex justify-center gap-4 mt-8 items-center">
               
                 <button
                   onClick={closeModal}
                   className="text-white flex  items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                 >
                   cancel
                 </button>
                 <button
                   type="submit"
                   onClick={CatgeoryhandleActiveandInactive}
                   className="text-white flex  items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                 >
                   Disable
                 </button>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
         </>
       ) : null}
     </>
    );
  };
  
  export default ShapeInActiveCategory;