import { createSlice } from "@reduxjs/toolkit";

const adminSlice=createSlice({
    name:"admin",
    initialState:{
        admin_id:"",
        isLogin:false,
        token:"",
        email:"",
        name:"",
        image:""
    },
    reducers:{
        setJwtToken:(state,{payload})=>{
            state.token=payload
        },
        setAdminId:(state,{payload})=>{
          state.admin_id=payload
        },
        setEmail:(state,{payload})=>{
            state.email=payload
        },
        setName:(state,{payload})=>{
            state.name=payload
        },
        setImage:(state,{payload})=>{
            state.image=payload
        },
        setIsLogin:(state,{payload})=>{
            state.isLogin=payload
        }
    }

})

export default adminSlice.reducer
export const {setAdminId,setEmail,setImage,setJwtToken,setName,setIsLogin} = adminSlice.actions