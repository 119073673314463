import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import {ReactComponent as EditIcon} from "../assets/icons/pencil_icon.svg"
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as routesKey from "../../constants/routes"
import { IoIosAdd } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { getProdouctSubCategory, getProducts } from '../../services/api'
import DeleteModal from '../../components/common/DeleteModal'
import toast from 'react-hot-toast'
import axiosConfig from '../../axios_config/axios_instance'
import Pagination from '../../components/pagination'
import EnableDisableModal from '../../components/common/EnableDisableModal'
import { getProdouctDetailsSuccess, setPage, setselectedCategory } from '../../redux/slice/productListSlice'
// import AddTemplate from './AddTemplate'

const Product = () => {
    const {productList,total_product_element,page,subCategoryList,selectedCategory} =useSelector(state=>state.productList)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(getProdouctDetailsSuccess(""))
      dispatch(getProdouctSubCategory())
    },[dispatch])
    useEffect(()=>{
        dispatch(getProducts(page,selectedCategory))
    },[dispatch,page,selectedCategory])


    const handleDelete=async (id,setshowModal)=>{
      try {
        setLoading(true)
        const {data} = await axiosConfig.delete(`/status-product-change?product_id=${id}`)
        if(data?.status==="success"){
         dispatch(getProducts(page))
         setshowModal(false)
         toast.success("Updated successfully")
        }else{
         toast.error("Something went wrong !!")
        }

        setLoading(false)
      } catch (error) {
       toast.error("Something went wrong !!")
       setLoading(false)
      }
 }
  return (
    <Template>
    <div className="flex  justify-between flex-grow-0 flex-shrink-0 items-center  py-4">
    <div className="flex  w-full  justify-between flex-grow-0 flex-shrink-0 items-center  pt-0">
     <div className="flex flex-row flex-shrink-0 flex-grow-0">
       <h2 className="text-xl font-sans font-bold">Product</h2>
     </div>
     <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
            <div className='flex gap-2'>
            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sub Category</label>
              <select  value={selectedCategory} onChange={(e)=>dispatch(setselectedCategory(e.target.value))} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option value={""}>All</option>
              {
                    subCategoryList?.map((subcat,i)=>
                    <option key={i} value={subcat?.product_sub_category_id}>{subcat?.product_sub_category_name}</option>
                    )
                  }
            </select>
              
     </div>
       <Link  to={routesKey.MANAGEPRODCUTCATEGORY}  className='text-lg'>
       Manage Category
       </Link>
       
       <Link
        to={routesKey.ADDPRODUCT}
        className="flex justify-start items-center flex-grow-0 flex-shrink-0"
        // onClick={openModal}
      >
        <button className="text-lg">
          <div className="flex items-center">
            <IoIosAdd /> Add Product
          </div>
        </button>
      </Link>

     
     {/* <AddTemplate/> */}

     </div>
   </div>
   </div>
 
 
   
   {/* <div className='text-black font-medium text-[30px]'>Backgrounds</div> */}
   <div className="flex flex-col border-2 p-4 bg-ed-600  w-full rounded-md  ">
          {productList?.length ?    <table >
               <thead>
     <tr className=" ">
    
       <th className="border-b text-center  whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 ">
         Preview
       </th>
       
       
       <th className="border-b whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3  ">
       Name
       </th>
       
       
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Category
       </th>
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Sub Category
       </th>
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Actions
       </th>
       
      
     </tr>
               </thead>
                 <tbody className="">
                   {productList?.map((product,i) => (
                      <tr   key={product?.product_id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                      
                      
                     
                       <td className="   whitespace-nowrap py-2  leading-tight  flex flex-col  items-center h-full  ">
                         <img alt='' src={product?.image_list[0]?.product_image} className='h-12 w-12 rounded-md'/>
                       </td>
                      <td className="whitespace-nowrap text-primary font-normal  text-[14px] leading-tight capitalize px-2 text-[#444444] text-center">{product?.product_name}</td>
                      
                      <td className=" text-primary font-normal  text-[14px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{product?.product_category_name}</td>
                      <td className=" text-primary font-normal  text-[14px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{product?.product_sub_category_name}</td>
                      <td className="text-primary  font-normal  text-[14px] leading-tight capitalize text-[#444444] mx-auto my-auto items-center text-center">
                      <div className="group/edit invisible  group-hover/item:visible flex justify-center items-center gap-1">
                        {
                          product?.is_active&&
                      <Link to={`/edit-product/${product?.product_id}`}
                               onClick={() => {
                                   // alert(background?.background_id)
                                  // seteditId(template?.designer_template_id)
                                 //setuserDetails(user);
                               }}
                               className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all"
                             >
                               <EditIcon className="w-4 h-4" />
                             </Link>
                        }
                             <EnableDisableModal   onConfirm={handleDelete} id={product?.product_id} isLoading={loading} desc={"Product"} is_active={!product?.is_active} />

                       {/* <Delete className='p-0  shadow-md'/> */}
                      </div>
                      </td>
                      </tr>
                   ))}
                 </tbody>
               </table>: <div className="flex justify-center items-center h-[20vh] w-full">
           <span className="text-center w-full text-xl text-[#7b8794]">
             No products available
           </span>
         </div>}
             </div>
         <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>
        <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={total_product_element} 
        pageSize={10}
                  onPageChange={(newPage) => { 
                   dispatch(setPage(newPage))
                      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                  }
                   }
      />  
      </div>
   
</Template>
  )
}

export default Product