import { createSlice } from "@reduxjs/toolkit";

const distressedSlice=createSlice({
    name:"distressed",
    initialState:{

        distressedList:[
            {
              "designer_distressed_id": 1,
              "designer_distressed_name": "television",
              "designer_distressed_image": "https://onlinedesigner.com/assets//designerDistressedImage/1/istockphoto-536248577-612x612.jpg",
              "sort_order": 1202,
              "is_active": true,
              "price": 152358,
              "tags": "this is television",
              "designer_distressed_category_id": 2,
              "designer_distressed_category_name": "world"
            },
            {
              "designer_distressed_id": 2,
              "designer_distressed_name": "television2",
              "designer_distressed_image": "https://onlinedesigner.com/assets//designerDistressedImage/2/istockphoto-536248577-612x612.jpg",
              "sort_order": 1525,
              "is_active": true,
              "tags": "this is television",
              "designer_distressed_category_id": 2,
              "designer_distressed_category_name": "world"
            },
            {
              "designer_distressed_id": 3,
              "designer_distressed_name": "television2",
              "designer_distressed_image": "https://onlinedesigner.com/assets//designerDistressedImage/3/istockphoto-536248577-612x612.jpg",
              "sort_order": 1525,
              "is_active": true,
              "price": 7899,
              "tags": "this is television",
              "designer_distressed_category_id": 2,
              "designer_distressed_category_name": "world"
            }
          ]
    },
    reducers:{

    }
})

export default distressedSlice.reducer;
export const {} = distressedSlice.actions
    

