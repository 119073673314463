import axiosConfig from "../axios_config/axios_instance"
import { getBackgroundCategoryListFail, getBackgroundCategoryListLoading, getBackgroundCategoryListSuccess, getBackgroundListFail, getBackgroundListLoading, getBackgroundListSuccess,getCategoryBackgroundModuleRequest,getCategoryBackgroundModuleSuccess,getCatgeoryBackgroundModuleFail,setTotalBackgroundCategory,setTotalBackgroundElement } from "../redux/slice/backgroundSlide"
import { getConfiguratorDetailsFail, getConfiguratorDetailsRequest, getConfiguratorDetailsSuccess, getConfiguratorListFail, getConfiguratorListRequest, getConfiguratorListSuccess } from "../redux/slice/configuratorSlice"
import { getProdouctDetailsFail, getProdouctDetailsLoading, getProdouctDetailsSuccess, getProductCategoryList, getProductColorList, getProductListLoading, getProductListSuccess, getProductListfail, getProductSubCategoryList, setTotalProductElement,  } from "../redux/slice/productListSlice"
import { getCategoryTemplateModuleRequest, getCategoryTemplateModuleSuccess, getCatgeoryTemplateModuleFail, getTemplateCategoryFail, getTemplateCategoryLoading, getTemplateCategorySuccess, getTemplateListFail, getTemplateListLoading, getTemplateListSuccess, setTemplateCategoryElement, setTemplateTotalElement } from "../redux/slice/templateSlice"

export const getBackgrounds=(page)=>async (dispatch)=>{
    try {
        dispatch(getBackgroundListLoading())
        const {data} = await axiosConfig.get(`/background-list?page=${page?page-1:0}&page_size=${10}`)
        dispatch(getBackgroundListSuccess(data?.background_list))
        dispatch(setTotalBackgroundElement(data?.total_elements))
    } catch (error) {
        dispatch(getBackgroundListFail())
    }
}

export const getBackgroundCategory=(page)=>async(dispatch)=>{
    
    try {
        dispatch(getBackgroundCategoryListLoading())
        const {data} = await axiosConfig.get(`/background-category-list?page=${page?page-1:0}&page_size=${10}`)
        dispatch(getBackgroundCategoryListSuccess(data?.background_category_list))
        dispatch(setTotalBackgroundCategory(data?.total_elements))
    } catch (error) {
        dispatch(getBackgroundCategoryListFail())
    }
}

export const getAllBackgroundCategoryAllModule=(page=0, page_size=5000, payload)=>async(dispatch)=>{
    try {
        dispatch(getCategoryBackgroundModuleRequest())
        const {data}= await axiosConfig.get(`/background-category-list?page=${page}&page_size=${page_size}`)
 
            dispatch(getCategoryBackgroundModuleSuccess(data?.background_category_list))
      
    } catch (error) {

        dispatch(getCatgeoryBackgroundModuleFail());
    }
}
export const getTemplates=(page)=>async (dispatch)=>{
    try {
        dispatch(getTemplateListLoading())
        const {data} = await axiosConfig.get(`/template-list?page=${page?page-1:0}&page_size=${10}`)
        dispatch(getTemplateListSuccess(data?.template_list))
        dispatch(setTemplateTotalElement(data?.total_elements))
    } catch (error) {
        dispatch(getTemplateListFail())
    }
}

export const getTemplateCategory=(page,page_size=10)=>async(dispatch)=>{
    try {
        dispatch(getTemplateCategoryLoading())
        const { data } = await axiosConfig.get(`/template-category-list?page=${page ? page - 1 : 0}&page_size=${page_size}`)
        dispatch(getTemplateCategorySuccess(data?.template_category_list))
        dispatch(setTemplateCategoryElement(data?.total_elements))
    } catch (error) {
        dispatch(getTemplateCategoryFail())
    }
}

export const getAllTemplateCategoryAllModule=(page=0, page_size=5000, payload)=>async(dispatch)=>{
    try {
        dispatch(getCategoryTemplateModuleRequest())
        const {data}= await axiosConfig.get(`/template-category-list?page=${page}&page_size=${page_size}`)
 
            dispatch(getCategoryTemplateModuleSuccess(data?.template_category_list))
      
    } catch (error) {

        dispatch(getCatgeoryTemplateModuleFail());
    }
}

export const getProducts=(page,sub_category_id)=>async(dispatch)=>{
    try {
        dispatch(getProductListLoading())
        let url = `/products?page=${page ? page - 1 : 0}&page_size=10`;
        if (sub_category_id) {
            url=`/products?page=${page ? page - 1 : 0}&page_size=10&product_sub_category_id=${sub_category_id}`
        }
        const {data} = await axiosConfig.get(url)
        dispatch(getProductListSuccess(data?.data?.product_list))
        dispatch(setTotalProductElement(data?.data?.total_elements))
    } catch (error) {
        dispatch(getProductListfail())
    }
}

export const getProdouctColors=()=>async(dispatch)=>{
    try {
        const {data} = await axiosConfig.get(`/get-colour-list`)
        dispatch(getProductColorList(data?.data?.colour_list))
    } catch (error) {
        
    }
}



export const getProdouctSubCategory=()=>async(dispatch)=>{
    try {
        const {data} = await axiosConfig.get(`/product-sub-categories?page=0&page_size=10000`)
        dispatch(getProductSubCategoryList(data?.data?.data))
    } catch (error) {
        
    }
}

export const getProductDetails=(payload)=>async(dispatch)=>{
    try {
        dispatch(getProdouctDetailsLoading())
        const {data} = await axiosConfig.get(`/product-details?product_id=${payload}`)
        dispatch(getProdouctDetailsSuccess(data?.data?.product_details))
    } catch (error) {
        dispatch(getProdouctDetailsFail())
    }
}

export const getConfiguratorLists=(page,page_size=10)=>async(dispatch)=>{
    try {
        dispatch(getConfiguratorListRequest())
        const {data} = await axiosConfig.get(`/configurator/get-product-configurators?page=${page}&page_size=${page_size}`)
        dispatch(getConfiguratorListSuccess(data))
    } catch (error) {
        dispatch(getConfiguratorListFail())
    }
}
export const getConfiguratorDetails=(id)=>async(dispatch)=>{
    try {
        dispatch(getConfiguratorDetailsRequest())
        const {data} = await axiosConfig.get(`/configurator/get-product-configurator-details?configurator_id=${id}`)
        dispatch(getConfiguratorDetailsSuccess(data?.configurator_details))
    } catch (error) {
        dispatch(getConfiguratorDetailsFail())
    }
}