import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Template from "../../../components/Template";
import * as routesKey from "../../../constants/routes";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ChevronRightIcon, PauseIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import EditShapeCategory from "./EditShapeCategory";
import AddShapeCategory from "./AddShapeCategory";
import EditSubShapeCategory from "./EditSubShapeCategory";
import DeleteSubShapeCategory from "./DeleteSubShapeCategory";
import AddSubShapeCategory from "./AddSubShapeCategory";
import { useDispatch } from "react-redux";
import {
  getAllShapeCategory,
  getAllShapeSubCategoryList,
} from "../../../services/apiService";
import { useSelector } from "react-redux";
import Pagination from "../../../components/pagination";
import axiosConfig from "../../../axios_config/axios_instance";
import toast from "react-hot-toast";
import { ReactComponent as ActiveIcon } from "../../assets/icons/Activeicon.svg";
import { ReactComponent as InActiveIcon } from "../../assets/icons/close_modal_icon.svg";
import { swapCategories } from "../../../redux/slice/ShapeSlice";
import ShapeActiveCategory from "./ShapeActiveCategory";
import ShapeInActiveCategory from "./ShapeInActiveCategory";
import SubShapeActiveCategory from "./SubShapeActiveCategory";
import SubInActiveCategory from "../../Product/categories/SubInActiveCatgeory";
import SubShapeInActiveCategory from "./SubShapeInActive";

const ManageShapeCategory = () => {
  const [showModal, setShowModal] = useState(false);
  const [expandedCategoryId, setexpandedCategoryId] = useState(0);
  const [isoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(1);
  const { ShapeCategoryList, TotalShapeCategorrList } = useSelector(
    (state) => state.shape
  );
  const { SubShapeList } = useSelector((state) => state.shape);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!ShapeCategoryList.length){
      dispatch(getAllShapeCategory(page - 1));

    }
  }, [dispatch, page]);

  useEffect(() => {
    if(!ShapeCategoryList.length){
      dispatch(getAllShapeSubCategoryList());

    }
  }, [dispatch, ]);

  const handleExpand = (id) => {
    if (id === expandedCategoryId) {
      setexpandedCategoryId(null);
    } else {
      setexpandedCategoryId(id);
    }
  };
  const handleDragEnd = async (e) => {
    if (!e.destination) {
      return;
    }
    const { destination, source } = e;
    const category1 = ShapeCategoryList[destination?.index]?.shape_category_id;
    const category2 = ShapeCategoryList[source.index]?.shape_category_id;
    dispatch(swapCategories({ cat1: destination?.index, cat2: source?.index }));
    try {
      const { data } = await axiosConfig.put(
        `/swap-shape-category-sort-order?category_id1=${category1}&category_id2=${category2}`
      );
      if (data?.status === "success") {
        dispatch(getAllShapeCategory(page - 1));
      }
    } catch (error) {}
  };

  console.log("swapCategories-----------", ShapeCategoryList);
  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };


  return (
    <Template>
      <div className="bg-gray-200 flex justify-start w-full">
        <div className="flex justify-start gap-2 p-2">
          <p>Home</p>/
          <Link to={routesKey.SHAPE}>
            <p>shape</p>
          </Link>
          /<p>category</p>
        </div>
      </div>

      <>
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-center capitalize text-[#202223]">
              Shape Categories
            </p>
          </div>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <AddShapeCategory page={page}/>
          </div>
        </div>
        <>
          {ShapeCategoryList?.length ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <div className="flex flex-col  items-start self-stretch flex-grow min-h-[65vh]">
                <table class="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center w-10">
                        {/* <PauseIcon className="w-4 h-4" /> */}
                      </th>
                      <th className="border-b border-black/10 text-xs font-semibold px-4 pl-0 py-3 text-center">
                        Category Name
                      </th>
                      <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                        Description
                      </th>

                      <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center"></th>
                    </tr>
                  </thead>
                  <Droppable droppableId={"droppable"}>
                    {(provided) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {ShapeCategoryList?.map((category, index) => (
                          <Draggable
                            draggableId={`draggable-${category.shape_category_id}`}
                            index={index}
                            key={category.shape_category_id}
                          >
                            {(provided) => (
                              <Fragment key={category?.shape_category_id}>
                                <tr
                                  className="group/item bg-transparent hover:bg-slate-100 transition-all border-b border-black/5"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <td className="text-xs font-semibold px-4 py-3 text-center cursor-move">
                                    <PauseIcon className="w-4 h-4" />
                                  </td>
                                  <td className="text-sm p-4 pl-0 text-center ">
                                    <div className="flex">
                                      <div className="flex flex-col">
                                        <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#202223]">
                                          {category?.shape_category_name}
                                        </p>
                                        <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#202223]">
                                          sub category:
                                          {
                                            SubShapeList?.filter(
                                              (shape) =>
                                                Number(
                                                  shape?.shape_category_id
                                                ) ===
                                                Number(
                                                  category?.shape_category_id
                                                )
                                            )?.length
                                          } items
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-sm p-4">
                                    <p className="flex-grow text-sm text-center text-[#202223]">
                                      {category?.description}
                                    </p>
                                  </td>

                                  <td className="text-sm p-4">
                                    <div className=" flex justify-center items-center gap-1">
                                      <button className="flex justify-center items-center flex-grow-0   flex-shrink-0 relative rounded gap-2.5 p-2  hover:bg-blue-400 transition-all">
                                      {category?.is_active ?      <EditShapeCategory
                                        page={page}
                                        setpage={setpage}
                                        productcategoryId={
                                          category?.shape_category_id
                                        }
                                        productcategoryEdit={category}
                                      /> : ""}
                                   
                                      </button>
                                      <button className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-3">
                                        {category?.is_active ? (
                                          <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2    transition-all">
                                            <ShapeInActiveCategory
                                              page={page}
                                              setpage={setpage}
                                              ShapeproductCategory={
                                                category?.shape_category_id
                                              }
                                            />
                                          </button>
                                        ) : (
                                          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2  hover:bg-red-600 transition-all">
                                            <ShapeActiveCategory
                                              page={page}
                                              setpage={setpage}
                                              ShapeproductCategory={
                                                category?.shape_category_id
                                              }
                                            />
                                          </div>
                                        )}{" "}
                                      </button>

                                      <div
                                        onClick={() =>
                                          handleExpand(
                                            category?.shape_category_id
                                          )
                                        }
                                      >
                                        <ChevronRightIcon
                                          className={`${
                                            expandedCategoryId ===
                                            category?.shape_category_id
                                              ? "rotate-90 transform"
                                              : ""
                                          } h-4 w-4  transition-all cursor-pointer`}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                {expandedCategoryId ===
                                  category.shape_category_id &&
                                  SubShapeList?.filter(
                                    (shape) => Number(shape?.shape_category_id) === Number(category?.shape_category_id)
                                  )?.map((sub, i) => (
                                    <tr
                                      className="group/item bg-transparent hover:bg-slate-100 transition-all border-b border-black/5"
                                      key={sub.shape_sub_category_id}
                                    >
                                      <td className="text-xs font-semibold pl-10 text-center cursor-move">
                                        <PauseIcon className="w-4 h-4" />
                                      </td>
                                      <td className="text-sm p-4 pl-0">
                                        <div className="flex">
                                          <div className="flex flex-col">
                                            <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-center text-[#202223]">
                                              {sub?.shape_sub_category_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-sm p-4">
                                        <p className="flex-grow text-sm text-center text-[#202223]">
                                          {sub?.description}
                                        </p>
                                      </td>


                                      <td className="text-sm p-4">
                                        <div className="group/edit invisible  group-hover/item:visible flex justify-center items-center gap-1">
                                        {sub?.is_active ? <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all">
                                        
                                        <EditSubShapeCategory
                                           page={page}
                                          subcatgrory={sub}
                                          category={category}
                                        />
                                      </button>:""}
                                         
                                         
                                            {sub?.is_active ? (
                                          <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2    transition-all">
                                            <SubShapeInActiveCategory
                                            page={page} setpage={setpage} SubshapeCategory={sub?.shape_sub_category_id}
                                            />
                                          </button>
                                        ) : (
                                          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2  hover:bg-red-600 transition-all">
                                            <SubShapeActiveCategory
                                            page={page} setpage={setpage} SubshapeCategory={sub?.shape_sub_category_id}
                                            />
                                          </div>
                                        )} 
                                       
                                        </div>  
                                      </td>
                                    </tr>
                                  ))}
                                {expandedCategoryId ===
                                  category.shape_category_id && (
                                  <AddSubShapeCategory category={category} />
                                )}
                              </Fragment>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </div>
            </DragDropContext>
          ) : (
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Category found
              </span>
            </div>
          )}
          <div className="flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0">
            <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={TotalShapeCategorrList}
              pageSize={10}
              onPageChange={(newPage) => {
                setpage(newPage);
                var currentScroll =
                  document.documentElement.scrollTop || document.body.scrollTop;
                if (currentScroll > 0) {
                  window.scrollTo(0, currentScroll - currentScroll / 1);
                }
              }}
            />
          </div>{" "}
        </>
      </>
    </Template>
  );
};

export default ManageShapeCategory;
