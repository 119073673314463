import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { useSelector } from 'react-redux';
import {  FaFilter } from 'react-icons/fa';
import Pagination from '../../components/pagination';
import AddColor from './AddColor';
import EditColor from './EditColor';
import DeleteColor from './DeleteColor';
import { useDispatch } from 'react-redux';
import { getAllColorList } from '../../services/apiService';
const Color_index = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setpage] = useState(1);

const dispatch=useDispatch()
  const {ColorList,TotalColorList}=useSelector((state)=>state.colorlist)

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


useEffect(()=>{
  if(!ColorList.length){
    dispatch(getAllColorList(page-1))

  }

},[dispatch,ColorList])

const handlePage=(page)=>{
  setpage(page)
  dispatch(getAllColorList(page-1))
}

// useEffect(()=>{
//   if(ColorList.length){
//     dispatch(getAllColorList(page-1))

//   }

// },[dispatch,page,ColorList])
  return (
    <Template>
    <div>
    <div className='bg-gray-200 flex justify-start  w-full'>
    <div className='flex justify-start gap-2 p-2'>
       <p>Home</p>
       /
       <p>Color</p>
    </div>
 
  </div>  
 
       <div className="flex flex-col flex-grow-0 flex-shrink-0 justify-center items-stretch mx-11 p-2">
       
         <div className="flex flex-1 justify-start gap-6 flex-grow-0 flex-shrink-0 items-center p-4 pt-4">
          
           <div className="flex flex-row flex-shrink-0 flex-grow-0">
             <h2 className="text-2xl font-sans font-bold">Color </h2>
           </div>
           <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
             <AddColor />
           </div>
           <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
         
           </div>
         </div>
         <div>
         </div>
         <div className="bg-gray-200 p-4 rounded-md">
           {/* Search Bar */}
          
 
         
           <div className="">
           {true ? (
             <div className="flex flex-col border-2  bg-ed-600  w-full rounded-md  ">
               {ColorList && ColorList.length > 0 ? (
                 <table className="min-w-full leading-normal">
                   <thead>
                     <tr className=" ">
                       <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                         Preview
                       </th>

                       <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                         Name
                       </th>
                
                       <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                         Hash Code
                       </th>
                    

                       <th className=" py-3 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                         Actions
                       </th>
                     </tr>
                   </thead>
                   <tbody className="">
                     {ColorList?.map((color, i) => (
                       <tr
                         key={color?.colour_id}
                         className={`group/item  ${
                           i % 2 === 0 ? "bg-white" : "bg-[#e7e7e7]"
                         }    transition-all border-b border-black/5 cursor-pointer`}
                       >
                         <td className="  px-5 py-2 border-b  border-gray-200 bg-white text-sm ">
                       
                         {color?.colour_image.trim() ? (
                          <img
                            alt=""
                            src={color?.colour_image}
                            className="h-16 w-16 rounded-md"
                          />
                        ) : (
                          <div className={`bg-[${color}] h-[80px] w-[80px] border border-black`} style={{background:color?.hash_code}} height={'120px'} width={'120px'} />
                          )}
  
                         </td>
                         <td className="px-5  border-b border-gray-200 bg-white text-sm">
                           {color?.colour_name}
                         </td>
                 
                         <td className="px-5  border-b border-gray-200 bg-white text-sm">
                         
                           {color?.hash_code}
                         </td>
                      

                         <td className=" py-5 border-b border-gray-200 bg-white text-sm">
                           <div className=" flex justify-center items-center gap-1">
                             <button className="flex justify-center items-center flex-grow-0   flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all">
                               <EditColor page={page} details={color} />
                             </button>
                             <button
                               onClick={() => {
                                 //   seteditmodal(true);
                                 //   setuserDetails(user);
                               }}
                               className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all"
                             >
                               <DeleteColor page={page} setpage={setpage} colordetailsId={color?.colour_id} />
                             </button>
                      
                           </div>
                         </td>
                       </tr>
                     ))}
                   </tbody>
                 </table>
               ) : (
                 <div className="flex justify-center items-center h-[20vh] w-full">
                   <span className="text-center w-full text-xl text-[#7b8794]">
                     No color available
                   </span>
                 </div>
               )}
             </div>
           ) : (
             <p>Loading....</p>
           )}
         </div>
         </div>
         <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>
         <Pagination
         className="pagination-bar"
         currentPage={page}
         totalCount={TotalColorList} 
         pageSize={10}
                   onPageChange={(newPage) => { 
                     handlePage(newPage)
                       var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                         if (currentScroll > 0) {
                           window.scrollTo(0, currentScroll - (currentScroll / 1));
                         }
                   }
                    }
       />  
       </div>      
       </div>
       </div>
     </Template>
  )
}

export default Color_index
