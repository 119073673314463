import { createSlice } from "@reduxjs/toolkit";

const configuratorSlice = createSlice({
    name: "configurator",
    initialState: {
        data: [
            {
                name: "",
                sub_parts: [
                    {
                        name: "",
                        thumb: "",
                        image: "",
                        is_default:1
                    }
                ]
            }
        ],
        configuratiorName:"",
        productParts:[],
        errors:[],
        configurationErrors:[],
        configuratorList: [],
        isLoading: false,
        totalConfigurator: 0,
        currentConfiguratorPage: 1,
        parts: {
            name: "",
            variants: [
                {
                    is_default: 1,
                    thumb: "",
                    image: "",
                    name:""
                }
            ]
        },
    },
    reducers: {
        setNewPartsName: (state,{payload}) => {
            state.parts.name = payload;  
        },
        addPartsVariants: (state) => {
            state.parts.variants = [
                ...state.parts.variants,   {
                    is_default: 0,
                    thumb: "",
                    image: "",
                    name:""
                }
            ]
        },
        removePartsVariants: (state, { payload }) => {
            state.parts.variants=state.parts.variants?.filter((_,i)=>i!==payload)
        },
        resetPartsData: (state) => {
            state.parts={
                name: "",
                variants: [
                    {
                        is_default: 1,
                        thumb: "",
                        image: "",
                        name:""
                    }
                ]
            }
        },
        setPartsData: (state,{payload}) => {
            state.parts = payload;
        },
        setVariantsName: (state, { payload }) => {
            state.parts.variants[payload.index].name = payload.value;
        },
        setVariantsThumb: (state, { payload }) => {
            state.parts.variants[payload.index].thumb = payload.value;
        },
        setVariantsImage: (state, { payload }) => {
            state.parts.variants[payload.index].image = payload.value;
        },
        setVariantsIsDefault: (state, { payload }) => {
            let tempArr = [...state.parts.variants]
            tempArr = tempArr.map((item) => {
                item.is_default = 0
                return item;
            });
            tempArr[payload.index].is_default = 1;
            state.parts.variants = tempArr;
        },
        addConfiguratorParts: (state, { payload }) => {
        state.data=[...state.data, {
                name: "",
                sub_parts: [
                    {
                        name: "",
                        thumb: "",
                        image: "",
                        is_default:0
                    }
                ]
            }]
        },
        removeConfiguratorParts: (state, { payload }) => {
            state.data = state.data.filter((_, i) => i !== payload);
        },
        addConfiguratorSubParts: (state, { payload }) => {
        state.data[payload].sub_parts=[...state.data[payload].sub_parts,  {name: "", thumb: "", image: "",is_default:0}]
        },
        removeConfiguratorSubParts: (state, { payload }) => {
        state.data[payload.parentIndex].sub_parts=state.data[payload.parentIndex].sub_parts.filter((_,i)=>i!==payload.index)
        },
        setPartsName: (state, { payload }) => {
            state.data[payload.index].name = payload.value;
        },
        setSubPartsName: (state, { payload }) => {
            state.data[payload.parentIndex].sub_parts[payload?.index].name = payload.value;
        },
        setSubPartsThumb: (state, { payload }) => {
            state.data[payload.parentIndex].sub_parts[payload?.index].thumb = payload.value;
        },
        setSubPartsImage: (state, { payload }) => {
            state.data[payload.parentIndex].sub_parts[payload?.index].image = payload.value;
        },
        setSubPartsDefault: (state, { payload }) => {
            let tempArr = [...state.data[payload.parentIndex].sub_parts]
            tempArr = tempArr.map((item) => {
                item.is_default = 0
                return item;
            });
            tempArr[payload.index].is_default = 1;
            state.data[payload.parentIndex].sub_parts = tempArr;
        },
        resetData: (state) => {
            state.data= [
                {
                    name: "",
                    sub_parts: [
                        {
                            name: "",
                            thumb: "",
                            image: "",
                            is_default:1
                        }
                    ]
                }]
            state.errors=[]
        },
        getConfiguratorListRequest: (state) => {
            state.isLoading = true;
        },
        getConfiguratorListSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.configuratorList = payload?.configurators;
            state.totalConfigurator = payload?.total_elements;
        },
        getConfiguratorListFail: (state) => {
            state.isLoading = false;
            state.configuratorList = [];
            state.totalConfigurator = 0;
        },
        setConfiguratorCurrentPage: (state,{payload}) => {
            state.currentConfiguratorPage = payload;
        },
        seterrors: (state, { payload }) => {
            state.errors=[...state.errors,payload]
        },
        removeErrors: (state, { payload }) => {
            state.errors = state.errors.filter((err) => err !== payload);
        },
        getConfiguratorDetailsRequest: (state) => {
            state.isLoading = true;
        },
        getConfiguratorDetailsSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.productParts = payload?.parts?.map((item) => {
                return {
                    id: item?.id,
                    sub_parts: item?.variants,
                    name:item?.name
                }
            })
            state.configuratiorName=payload?.name
        },
        getConfiguratorDetailsFail: (state) => {
            state.isLoading = false;
        },
        addEditedConfiguratorParts: (state) => {
            state.productParts=[...state.productParts, {
                    name: "",
                    sub_parts: [
                        {
                            name: "",
                            thumb: "",
                            image: "",
                            is_default:0
                        }
                    ]
                }]
            },
            removeEditedConfiguratorParts: (state, { payload }) => {
                state.productParts = state.productParts.filter((_, i) => i !== payload);
            },
            addEditedConfiguratorSubParts: (state, { payload }) => {
            state.productParts[payload].sub_parts=[...state.productParts[payload].sub_parts,  {name: "", thumb: "", image: "",is_default:0}]
            },
            removeEditedConfiguratorSubParts: (state, { payload }) => {
            state.productParts[payload.parentIndex].sub_parts=state.productParts[payload.parentIndex].sub_parts.filter((_,i)=>i!==payload.index)
            },
            setEditedPartsName: (state, { payload }) => {
                state.productParts[payload.index].name = payload.value;
            },
            setEditedSubPartsName: (state, { payload }) => {
                state.productParts[payload.parentIndex].sub_parts[payload?.index].name = payload.value;
            },
            setEditedSubPartsThumb: (state, { payload }) => {
                state.productParts[payload.parentIndex].sub_parts[payload?.index].thumb = payload.value;
            },
            setEditedSubPartsImage: (state, { payload }) => {
                state.productParts[payload.parentIndex].sub_parts[payload?.index].image = payload.value;
            },
            setEditedSubPartsDefault: (state, { payload }) => {
                let tempArr = [...state.productParts[payload.parentIndex].sub_parts]
                tempArr = tempArr.map((item) => {
                    item.is_default = 0
                    return item;
                });
                tempArr[payload.index].is_default = 1;
                state.productParts[payload.parentIndex].sub_parts = tempArr;
            },
    }
})

export default configuratorSlice.reducer
export const {addConfiguratorParts,addConfiguratorSubParts,removeConfiguratorParts,removeConfiguratorSubParts,setPartsName,setSubPartsImage,setSubPartsName,setSubPartsThumb,setSubPartsDefault ,resetData,getConfiguratorListFail,getConfiguratorListRequest,getConfiguratorListSuccess,setConfiguratorCurrentPage,removeErrors,seterrors,getConfiguratorDetailsFail,getConfiguratorDetailsRequest,getConfiguratorDetailsSuccess,addEditedConfiguratorParts,addEditedConfiguratorSubParts,removeEditedConfiguratorParts,removeEditedConfiguratorSubParts,setEditedPartsName,setEditedSubPartsDefault,setEditedSubPartsImage,setEditedSubPartsThumb,setEditedSubPartsName,addPartsVariants,removePartsVariants,setNewPartsName,setVariantsImage,setVariantsIsDefault,setVariantsName,setVariantsThumb,resetPartsData,setPartsData}=configuratorSlice.actions