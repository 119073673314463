import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Template from "../../../components/Template";
import * as routesKey from "../../../constants/routes";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ChevronRightIcon, PauseIcon } from "@heroicons/react/24/outline";
import AddProductCategory from "./AddProductCategory";
import { Fragment } from "react";
import EditProductCategory from "./EditProductCategory";
import EditSubCategory from "./EditSubCategory";
import AddSubCategory from "./AddSubCategory";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllProductCategoryList,
  
  getAllSubProductCategoryList,
} from "../../../services/apiService";
import axiosConfig from "../../../axios_config/axios_instance";
import Pagination from "../../../components/pagination";
import ActiveCategory from "./ActiveCategory";
import InActiveCategory from "./InActiveCategory";
import SubActiveCategory from "./SubActiveCategory";
import SubInActiveCategory from "./SubInActiveCatgeory";
import { ProductswapCategories } from "../../../redux/slice/ProductSlice";
const ManageProductCategory = () => {
  const [expandedCategoryId, setexpandedCategoryId] = useState(0);
  const [page, setpage] = useState(1);

  const { productCategoryList, TotalProductCategoryList } = useSelector(
    (state) => state.product
  );
  const { SubProductList } = useSelector((state) => state.product);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!productCategoryList.length){
      dispatch(getAllProductCategoryList(page - 1));
    }
  }, [dispatch, page]);

  useEffect(() => {
    if(!SubProductList.length){
      dispatch(getAllSubProductCategoryList());
    }
  }, [dispatch]);


  const handleExpand = (id) => {
    if (id === expandedCategoryId) {
      setexpandedCategoryId(null);
    } else {
      setexpandedCategoryId(id);
    }
  };


  const handleDragEnd = async (e) => {
    if (!e.destination) {
      return;
    }
    const { destination, source } = e;
    dispatch(ProductswapCategories({ cat1: destination?.index, cat2: source?.index }));
    const category1 = productCategoryList[destination?.index]?.product_category_id;
    const category2 = productCategoryList[source.index]?.product_category_id;
    try {
      const { data } = await axiosConfig.post(
        `/save-swap-product-category-sort-order?product_category_id_1=${category1}&product_category_id_2=${category2}`
      );
      if (data?.status === "success") {
        dispatch(getAllProductCategoryList(page - 1));
      }
    } catch (error) {}
  };
  return (
    <Template>
      <div className="bg-gray-200 flex justify-start w-full">
        <div className="flex justify-start gap-2 p-2">
          <p>Home</p>/
          <Link to={routesKey.PRODUCT}>
            <p>Product</p>
          </Link>
          /<p>category</p>
        </div>
      </div>

      <>
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-center capitalize text-[#202223]">
              Product Categories
            </p>
          </div>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <AddProductCategory />
          </div>
        </div>
        <>
                    {productCategoryList?.length ? (
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[65vh]">
                          <table class="table-auto w-full">
                            <thead>
                              <tr>
                                <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center w-10">
                                  {/* <PauseIcon className="w-4 h-4" /> */}
                                </th>
                                <th className="border-b border-black/10 text-xs font-semibold px-4 pl-0 py-3 text-center">
                                  Category Name
                                </th>
                                <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                                  Description
                                </th>

                                <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center"></th>
                              </tr>
                            </thead>
                            <Droppable droppableId={"droppable"}>
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {productCategoryList?.map(
                                    (category, index) => (
                                      <Draggable
                                        draggableId={`draggable-${category.product_category_id}`}
                                        index={index}
                                        key={category.product_category_id}
                                      >
                                        {(provided) => (
                                          <Fragment
                                            key={category?.product_category_id}
                                          >
                                            <tr
                                              className="group/item bg-transparent hover:bg-slate-100 transition-all border-b border-black/5"
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              ref={provided.innerRef}
                                            >
                                              <td className="text-xs font-semibold px-4 py-3 text-center cursor-move">
                                                <PauseIcon className="w-4 h-4" />
                                              </td>
                                              <td className="text-sm p-4 pl-0 text-center ">
                                                <div className="flex">
                                                  <div className="flex flex-col">
                                                    <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#202223]">
                                                      {
                                                        category?.product_category_name
                                                      }
                                                    </p>
                                                    <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#202223]">
                                                      sub category:
                                                      {
                                                        SubProductList?.filter(
                                                          (shape) =>
                                                            Number(
                                                              shape?.product_category_id
                                                            ) ===
                                                            Number(
                                                              category?.product_category_id
                                                            )
                                                        )?.length
                                                      } items
                                                    </p>
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-sm p-4">
                                                <p className="flex-grow text-sm text-center text-[#202223]">
                                                  {category?.description}
                                                </p>
                                              </td>

                                              <td className="text-sm p-4">
                                                <div className="group/edit invisible  group-hover/item:visible flex justify-center items-center gap-1">
                                                  <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all">
                                                  {category?.is_active ?   <EditProductCategory
                                                    page={page}
                                                    productcategoryId={
                                                      category?.product_category_id
                                                    }
                                                    productcategoryEdit={
                                                      category
                                                    }
                                                  /> : ""}
                                                  
                                                  </button>
                                                  <button className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-3">
                                                    {category?.is_active ? (
                                                      <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm  transition-all">
                                                        <InActiveCategory
                                                          page={page}
                                                          setpage={setpage}
                                                          productCategory={
                                                            category?.product_category_id
                                                          }
                                                        />
                                                      </button>
                                                    ) : (
                                                      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-red-600 transition-all">
                                                        <ActiveCategory
                                                          page={page}
                                                          setpage={setpage}
                                                          productCategory={
                                                            category?.product_category_id
                                                          }
                                                        />
                                                      </div>
                                                    )}{" "}
                                                  </button>

                                                  <div
                                                    onClick={() =>
                                                      handleExpand(
                                                        category?.product_category_id
                                                      )
                                                    }
                                                  >
                                                    <ChevronRightIcon
                                                      className={`${
                                                        expandedCategoryId ===
                                                        category?.product_category_id
                                                          ? "rotate-90 transform"
                                                          : ""
                                                      } h-4 w-4  transition-all cursor-pointer`}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>

                                            {expandedCategoryId ===
                                              category.product_category_id &&
                                              SubProductList?.filter(
                                                (shape) =>
                                                  Number(
                                                    shape?.product_category_id
                                                  ) ===
                                                  Number(
                                                    category?.product_category_id
                                                  )
                                              )?.map((sub, i) => (
                                                <tr
                                                  className="group/item bg-transparent hover:bg-slate-100 transition-all border-b border-black/5"
                                                  key={
                                                    sub.product_sub_category_id
                                                  }
                                                >
                                                  <td className="text-xs font-semibold pl-10 text-center cursor-move">
                                                    <PauseIcon className="w-4 h-4" />
                                                  </td>
                                                  <td className="text-sm p-4 pl-0">
                                                    <div className="flex">
                                                      <div className="flex flex-col">
                                                        <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-center text-[#202223]">
                                                          {
                                                            sub?.product_sub_category_name
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td className="text-sm p-4">
                                                    <p className="flex-grow text-sm text-center text-[#202223]">
                                                      {sub?.description}
                                                    </p>
                                                  </td>

                                                  <td className="text-sm p-4">
                                                    <div className="group/edit invisible  group-hover/item:visible flex justify-center items-center gap-1">
                                                      <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all">
                                                      {sub?.is_active ?   <EditSubCategory
                                                        subcatgrory={sub}
                                                        category={category}
                                                      /> : ""}
                                                      
                                                      </button>
                                                      <button>
                                                        {sub?.is_active ? (
                                                          <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm  transition-all">
                                                            <SubInActiveCategory
                                                              page={page}
                                                              setpage={setpage}
                                                              SubproductCategory={
                                                                sub?.product_sub_category_id
                                                              }
                                                            />
                                                          </button>
                                                        ) : (
                                                          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-red-600 transition-all">
                                                            <SubActiveCategory
                                                              page={page}
                                                              setpage={setpage}
                                                              SubproductCategory={
                                                                sub?.product_sub_category_id
                                                              }
                                                            />
                                                          </div>
                                                        )}
                                                      </button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))}
                                              {expandedCategoryId === category.product_category_id && (
                                                <AddSubCategory category={category} />
                                              )}
                          
                                          </Fragment>
                                        )}
                                      </Draggable>
                                    )
                                  )}
                                  {provided.placeholder}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </div>
                      </DragDropContext>
                    ) : (
                      <div className="flex justify-center items-center h-[70vh] w-full">
                        <span className="text-center w-full text-xl text-[#7b8794]">
                          No Category found
                        </span>
                      </div>
                    )}
          <div className="flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0">
            <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={TotalProductCategoryList}
              pageSize={10}
              onPageChange={(newPage) => {
                setpage(newPage);
                var currentScroll =
                  document.documentElement.scrollTop || document.body.scrollTop;
                if (currentScroll > 0) {
                  window.scrollTo(0, currentScroll - currentScroll / 1);
                }
              }}
            />
          </div>{" "}
        </>
      </>
    </Template>
  );
};

export default ManageProductCategory;
