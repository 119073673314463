import { Dialog, Switch, Transition } from '@headlessui/react'
import { PencilIcon } from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { IoIosAdd } from 'react-icons/io'
import Files from "react-files"
import axiosConfig from '../../axios_config/axios_instance';
import { useSelector } from 'react-redux'
import { addPartsVariants, removeErrors, removePartsVariants, resetData, setNewPartsName, setPartsData, setVariantsImage, setVariantsIsDefault, setVariantsName, setVariantsThumb, seterrors } from '../../redux/slice/configuratorSlice'
import { useDispatch } from 'react-redux'
import { MdDelete } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { getConfiguratorDetails } from '../../services/api'
export default function AddParts({name,existingPartsData}) {
  let [isOpen, setIsOpen] = useState(false)
  const { id } = useParams();
  const { parts,errors } = useSelector((state) => state.configurator)
  const [isLoading, setisLoading] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }

  const dispatch = useDispatch();
  function openModal() {
    setIsOpen(true)
    dispatch(setPartsData({
      id:existingPartsData?.id,
      name: existingPartsData?.name,
      variants:existingPartsData?.sub_parts
    }))
    dispatch(resetData())
  }
  const validateAllField = () => {
    let isValidated = true;
    if (!parts?.name?.trim()) {
      isValidated = false;
      if (!errors.includes("partsName")) { 
        dispatch(seterrors(`partsName`))
      }

    }
    parts?.variants?.forEach((child,parentIndex) => {

      if (!child?.name?.trim()) {
        isValidated = false;
        if (!errors.includes(`subPartsName${parentIndex}`)) { 
          dispatch(seterrors(`subPartsName${parentIndex}`))
        }
      }
      if (!child?.thumb) {
        isValidated = false;
        if (!errors.includes(`subPartsThumb${parentIndex}`)) { 
          dispatch(seterrors(`subPartsThumb${parentIndex}`))
        }
      }
      if (!child?.image) {
        isValidated = false;
        if (!errors.includes(`subPartsImage${parentIndex}`)) { 
          dispatch(seterrors(`subPartsImage${parentIndex}`))
        }
      }
    })
    return isValidated;
  }
    const handleSubmit = async () => {
      if (!validateAllField()) {
        return;
        }
        try {
          const postdata = new FormData();
          postdata.append('configurator_id', id);
          postdata.append('configurator_name', name);
          postdata.append('part_name', parts?.name);
          postdata.append('part_id', parts?.id);
          postdata.append('total_variations', parts?.variants?.length);
          parts?.variants?.map((item, i) => {
            if (item?.id) { 
              postdata.append('existing_variation_ids',item?.id)
              postdata.append('existing_variant_names',item?.name)
              postdata.append('existing_variant_defaults', item?.is_default)
              if (typeof item?.thumb==="object") { 
                postdata.append('existing_variant_thumbs',item?.thumb)
              } else {
                const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                postdata.append('existing_variant_thumbs',emptyFile)
              }
              if (typeof item?.image==="object") { 
                postdata.append('existing_variant_images',item?.image)
              } else {
                const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                postdata.append('existing_variant_images',emptyFile)
              }
            } else {
            postdata.append('variant_names', item?.name)
            postdata.append('variant_defaults',item?.is_default)
            postdata.append('variant_thumbs',item?.thumb)
            postdata.append('variant_images', item?.image)
            }
          })
          setisLoading(true);
          const {data}=await axiosConfig.put(`/configurator/edit-product-configurator`,postdata)
          if (data?.status === "success") {
            toast.success(data?.message);
            setIsOpen(false)
            setisLoading(false);
            dispatch(getConfiguratorDetails(id))
          } else {
            toast.error(data?.message);
            setisLoading(false);
          }
        } catch (error) {
            toast.error("Some error occured")
            setisLoading(false);
        }
    }
  return (
    <>
      <div className=" flex items-center justify-center">
      <button onClick={openModal} className='flex cursor-pointer gap-2 items-center border rounded-md border-blue-600 py-2 px-3'><PencilIcon className='h-4 w-4 cursor-pointer' />
                          <span>Edit Parts</span>
                          </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform max-h-[80vh] overflow-y-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900 px-2"
                  >
                    Edit Parts
                                  </Dialog.Title>
                                  <div className='flex flex-col gap-3'>
                                      <div className='flex flex-col gap-1'>
                                  <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                        Name
                                      </label>
                                      <input
                    name="name"
                    type="text"
                    style={errors.includes(`partsName`)?{border:"1px solid red"}:{}}
                    value={parts.name}
                    onChange={(e) => { 
                      if (errors?.includes(`partsName`)) {
                 dispatch(removeErrors(`partsName`))
                      }
                    dispatch(setNewPartsName(e.target.value))
                    } 
                            }
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="parts name"
                  />                      
                                  </div>
                                      <div className='w-full justify-between flex items-center'>
                                      <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold "
                    htmlFor="name">
                        Variants
                                          </label>
                                             <button onClick={()=>dispatch(addPartsVariants())}  className='flex cursor-pointer gap-2 items-center border rounded-md border-blue-600 py-2 px-3'><IoIosAdd />
                          <span>Add Subparts</span>
                          </button>
                                      </div>
                                      {
                                          parts?.variants?.map((child,index) => (
                                            <div className='flex gap-3 items-center'>
                                          <Switch
                                checked={child?.is_default === 1 ? true : false}
                          onChange={(value) => {
                              dispatch(setVariantsIsDefault({index}))
        }}
        className={`${child?.is_default===1 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[18px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${child?.is_default===1 ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
                          <div className='w-[25%]'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Name
                        </label>
                  <input
                    name="name"
                    type="text"
                            value={child.name}
                            style={errors.includes(`subPartsName${index}`)?{border:"1px solid red"}:{}}
                            onChange={(e) => {
                              if (errors?.includes(`subPartsName${index}`)) {
                                dispatch(removeErrors(`subPartsName${index}`))
                              }
                              dispatch(setVariantsName({  index, value: e.target.value }))
                            }}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="sub parts name"
                  />
                          </div>
                          <div className='w-[15%]'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Thumb
                          </label>
                          <Files
                                    className="files     -dropzone cursor-pointer"
                                    onChange={(file) => {
                                        if (file.length) {
                                            dispatch(setVariantsThumb({  index, value: file[0] }))
                                        }
                                        if (errors?.includes(`subPartsThumb${index}`)) {
                                            dispatch(removeErrors(`subPartsThumb${index}`))
                                        }
                                    }}
                                    onError={(error) => toast.error(error?.message)}
                                    accepts={[".png", ".jpeg", ".jpg"]}
                                    maxFileSize={512000}
                                    minFileSize={0}
                                    multiple={false}
                                    clickable
                          >
                                                  {child?.thumb ? <img className='h-12 w-12 object-contain cursor-pointer'
                                                      src={typeof child?.thumb === "object" ? child?.thumb?.preview?.url : child?.thumb}              
                            /> : <div className='w-full flex justify-center items-center border-dashed border border-black/50 py-2'
                             style={errors.includes(`subPartsThumb${index}`)?{border:"1px solid red"}:{}}
                            >
                                      <IoIosAdd className='w-5 h-5' />
                          </div>}
                    </Files>
                          </div>
                        <div className='w-[15%]'>
                      
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Image
                          </label>
                          <Files
                    className="files     -dropzone cursor-pointer"
                            onChange={(file) => {
                              if (file.length) {
                                dispatch(setVariantsImage({index,value:file[0]}))
                              }
                              if (errors?.includes(`subPartsThumb${index}`)) {
                                dispatch(removeErrors(`subPartsThumb${index}`))
                              }
                    }}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".png",".jpeg",".jpg"]}
                    maxFileSize={512000}
                    minFileSize={0}
                    multiple={false}
                     clickable
                    >
                                                  {child?.image  ? <img className='h-12 w-12 object-contain cursor-pointer'
                                                      src={typeof child?.image === "object" ? child?.image?.preview?.url : child?.image}
                                                  /> : <div className='w-full flex justify-center items-center border-dashed border border-black/50 py-2'
                            style={errors.includes(`subPartsImage${index}`)?{border:"1px solid red"}:{}}
                            >
                                      <IoIosAdd className='w-5 h-5' />
                          </div>}
                    </Files>
                        </div>
                        <div className='flex flex-col gap-2'>

                        <label  className="block text-base  text-gray-700 font-bold"
                    htmlFor="name">
                        </label>
                        {/* {index>0 &&  <MdDelete fill='red' className='h-6 w-6 cursor-pointer' onClick={()=>dispatch(removePartsVariants(index))} />} */}
                        </div>
                              </div>
                                          ))
                                     }
                                  </div>
                  <div className="mt-4">
                  <div className='flex justify-end'>
                    <button
                    type="submit"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    className="text-white inline-flex mt-2 items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Save
                  </button>
                  </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
