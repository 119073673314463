import axiosConfig from "../axios_config/axios_instance";
import { getColorDetailsFail, getColorDetailsRequest, getColorDetailsSuccess, getColorListFail, getColorListRequest, getColorListSuccess } from "../redux/slice/ColorSlice";
import { getProductCategoryDetailsFail, getProductCategoryDetailsRequest, getProductCategoryDetailsSuccess, getProductCategoryListFail, getProductCategoryListRequest, getProductCategoryListSuccess, getSubproductCategoryDetailsFail, getSubproductCategoryDetailsRequest, getSubproductCategoryDetailsSuccess, getSubproductCategoryListFail, getSubproductCategoryListRequest, getSubproductCategoryListSuccess } from "../redux/slice/ProductSlice";
import { getShapeCategoryAllModuleFail, getShapeCategoryAllModuleRequest, getShapeCategoryAllModuleSuccess, getShapeCategoryDetailsFail, getShapeCategoryDetailsRequest, getShapeCategoryDetailsSuccess, getShapeCategoryListFail, getShapeCategoryListRequest, getShapeCategoryListSuccess, getShapeDetailsFail, getShapeDetailsRequest, getShapeDetailsSuccess, getShapeListFail, getShapeListRequest, getShapeListSuccess, getSubShapeCategoryAllModuleFail, getSubShapeCategoryAllModuleRequest, getSubShapeCategoryAllModuleSuccess, getSubShapeCategoryDetailsFail, getSubShapeCategoryDetailsRequest, getSubShapeCategoryDetailsSuccess, getSubShapeCategoryListFail, getSubShapeCategoryListRequest, getSubShapeCategoryListSuccess } from "../redux/slice/ShapeSlice";



export const getAllColorList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getColorListRequest())
        const {data}= await axiosConfig.get(`/get-colour-list?page=${page}&page_size=${page_size}`)
 
            dispatch(getColorListSuccess(data?.data))
      
    } catch (error) {

        dispatch(getColorListFail());
    }
}

export const getAllColorDetails=(colour_id)=>async(dispatch)=>{
    try {
        dispatch(getColorDetailsRequest())
        const {data}= await axiosConfig.get(`/colour-details?colour_id=${colour_id}`)
 
            dispatch(getColorDetailsSuccess(data?.data?.colour_data))
      
    } catch (error) {

        dispatch(getColorDetailsFail());
    }
}

export const getAllShapeList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getShapeListRequest())
        const {data}= await axiosConfig.get(`/shapes?page=${page}&page_size=${page_size}`)
 
            dispatch(getShapeListSuccess(data?.data))
      
    } catch (error) {

        dispatch(getShapeListFail());
    }
}

export const getAllShapeDetails=(shape_id)=>async(dispatch)=>{
    try {
        dispatch(getShapeDetailsRequest())
        const {data}= await axiosConfig.get(`/shape-details?shape_id=${shape_id}`)
 
            dispatch(getShapeDetailsSuccess(data?.data))
      
    } catch (error) {

        dispatch(getShapeDetailsFail());
    }
}
export const getAllShapeCategory=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
       dispatch(getShapeCategoryListRequest())
        const {data}= await axiosConfig.get(`/shape-categories?page=${page}&page_size=${page_size}`)
 
            dispatch(getShapeCategoryListSuccess(data?.data))
      
    } catch (error) {

     dispatch(getShapeCategoryListFail());
    }
}
export const getAllShapeCategoryDetails=(productcategoryId)=>async(dispatch)=>{
    try {
        dispatch(getShapeCategoryDetailsRequest())
        const {data}= await axiosConfig.get(`/shape-category-details?shape_category_id=${productcategoryId}`)
 
            dispatch(getShapeCategoryDetailsSuccess(data?.data?.shape_data))
      
    } catch (error) {

        dispatch(getShapeCategoryDetailsFail());
    }
}
export const getAllShapeCategoryAllModule=(page=0, page_size=1000, payload)=>async(dispatch)=>{
    try {
        dispatch(getShapeCategoryAllModuleRequest())
        const {data}= await axiosConfig.get(`/shape-categories?page=${page}&page_size=${page_size}`)
 
            dispatch(getShapeCategoryAllModuleSuccess(data?.data))
      
    } catch (error) {

        dispatch(getShapeCategoryAllModuleFail());
    }
}
export const getAllShapeSubCategoryList=(page=0, page_size=5000, payload)=>async(dispatch)=>{
    try {
        dispatch(getSubShapeCategoryListRequest())
        const {data}= await axiosConfig.get(`/shape-sub-categories?page=0&page_size=1000`)
 
            dispatch(getSubShapeCategoryListSuccess(data?.data?.sub_category_list))
        
    } catch (error) {

        dispatch(getSubShapeCategoryListFail());
    }
}

export const getAllShapeSubCategoryDetails=(shape_sub_category_data)=>async(dispatch)=>{
    try {
        dispatch(getSubShapeCategoryDetailsRequest())
        const {data}= await axiosConfig.get(`/shape-sub-categories?shape_sub_category_data=${shape_sub_category_data}`)
 
            dispatch(getSubShapeCategoryDetailsSuccess(data?.data?.shape_sub_category_data))
        
    } catch (error) {

        dispatch(getSubShapeCategoryDetailsFail());
    }
}

export const getAllShapeSubCategoryListAllModule=(page=0, page_size=5000, shape_category_id)=>async(dispatch)=>{
    try {
        let url=`/shape-sub-categories?page=${page}&page_size=${page_size}`
        if(shape_category_id){
            url=`/shape-sub-categories?shape_category_id=${shape_category_id}&page=${page}&page_size=${page_size}`
        }

        dispatch(getSubShapeCategoryAllModuleRequest())
 
            const {data}= await axiosConfig.get(url)
                dispatch(getSubShapeCategoryAllModuleSuccess(data?.data))
  
        
        
    } catch (error) {

        dispatch(getSubShapeCategoryAllModuleFail());
    }
}
export const getAllProductCategoryList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getProductCategoryListRequest())
        const {data}= await axiosConfig.get(`/product-categories?page=${page}&page_size=${page_size}`)
 
            dispatch(getProductCategoryListSuccess(data?.data))
      
    } catch (error) {

        dispatch(getProductCategoryListFail());
    }
}
export const getAllProductCategoryDetails=(product_category_id)=>async(dispatch)=>{
    try {
        dispatch(getProductCategoryDetailsRequest())
        const {data}= await axiosConfig.get(`/product-category-details?product_category_id=${product_category_id}`)
 
            dispatch(getProductCategoryDetailsSuccess(data?.data?.data))
      
    } catch (error) {

        dispatch(getProductCategoryDetailsFail());
    }
}

export const getAllSubProductCategoryList=(page=0, page_size=1000, payload)=>async(dispatch)=>{
    try {
        dispatch(getSubproductCategoryListRequest())
        const {data}= await axiosConfig.get(`/product-sub-categories?page=${page}&page_size=${page_size}`)
 
            dispatch(getSubproductCategoryListSuccess(data?.data?.data))
      
    } catch (error) {

        dispatch(getSubproductCategoryListFail());
    }
}
export const getAllSubProductCategoryDetails=(product_sub_category_id)=>async(dispatch)=>{
    try {
        dispatch(getSubproductCategoryDetailsRequest())
        const {data}= await axiosConfig.get(`/product-sub-category-details?product_sub_category_id=${product_sub_category_id}`)
 
            dispatch(getSubproductCategoryDetailsSuccess(data?.data?.data))
      
    } catch (error) {

        dispatch(getSubproductCategoryDetailsFail());
    }
}