import React, { useEffect, useState } from "react";
import Template from "../../components/Template";
import { useSelector } from "react-redux";
import { FaTh, FaBars, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import * as routesKey from "../../constants/routes";
import { FiSettings } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { getAllShapeList } from "../../services/apiService";
import Pagination from "../../components/pagination";
import InActiveShapeList from "./InActiveShapeList";
import ActiveShapeList from "./ActiveShapeList";
import AddShape from "./AddShape";
import EditShape from "./EditShape";

const Shape_index = () => {
  const [selectedFont, setSelectedFont] = useState([]);
  const [viewMode, setViewMode] = useState("grid");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setpage] = useState(1);


  const dispatch=useDispatch()


  const {ShapeList,TotalShapeList}=useSelector((state)=>state?.shape)



  useEffect(() => {
    if (!ShapeList.length) {
      dispatch(getAllShapeList(page - 1));
    }
  }, [dispatch, page]);

  const handlePage=(page)=>{
    setpage(page)
   dispatch(getAllShapeList(page-1))
  }


  const handleSelectedImage = (imageId) => {
    if (selectedFont.includes(imageId)) {
      setSelectedFont(selectedFont.filter((id) => id !== imageId));
    } else {
      setSelectedFont([...selectedFont, imageId]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  return (
    <Template>
      <div>
        <div className="bg-gray-200 flex justify-start  w-full">
          <div className="flex justify-start gap-2 p-2">
            <p>Home</p>/<p>Shape</p>
          </div>
        </div>
        <div className="flex flex-col flex-grow-0 flex-shrink-0 justify-center items-stretch mx-11 p-2">
          <div className="flex flex-1 justify-start gap-6 flex-grow-0 flex-shrink-0 items-center p-4 pt-4">
            <div className="flex flex-row flex-shrink-0 flex-grow-0">
              <h2 className="text-2xl font-sans font-bold">Shape</h2>
            </div>
            <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
            <AddShape />
          </div>
            <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
            <Link
            to={routesKey.MANAGESHAPECATEGORY}
            className="flex justify-start items-center flex-grow-0 flex-shrink-0 p-1 px-3 cursor-pointer bg-gray-200 rounded-md"
          >
            <button className="text-[16px]">
              <div className="flex items-center">
                <FiSettings className="mr-1" /> Manage category
              </div>
            </button>
          </Link>
            </div>
          </div>
          <div></div>
          <div className="bg-gray-200 p-4 rounded-md">
            {/* Search Bar */}
            

            <div className="">
              {true ? (
                <div className="flex flex-col border-2  bg-ed-600  w-full rounded-md  ">
                  {ShapeList && ShapeList.length > 0 ? (
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr className=" ">
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                            Preview
                          </th>

                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                            Name
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                            Category
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                            Price
                          </th>

                          <th className=" py-3 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {ShapeList?.map((background, i) => (
                          <tr
                            key={background?.shape_id}
                            className={`group/item  ${
                              i % 2 === 0 ? "bg-white" : "bg-[#e7e7e7]"
                            }    transition-all border-b border-black/5 cursor-pointer`}
                          >
                            <td className=" py-1 px-5 border-b  border-gray-200 bg-white text-sm ">
                              <img
                                alt=""
                                src={background?.image}
                                className="h-16 w-16 rounded-md"
                              />
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {background?.shape_name}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {background?.shape_category_name}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {background?.price}
                            </td>

                            <td className=" py-5 border-b border-gray-200 bg-white text-sm">
                         
                              <div     className="group/edit invisible  group-hover/item:visible flex justify-center items-center gap-1">
                              {background?.is_active ?      <div   >
                              <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all">
                              <div
                           
                              className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
                            >
                              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 ">
                                <EditShape shape_id={background?.shape_id} />
                              </div>
                            </div>
                              </button>
                              </div> : ""}
                         
                                <button>
                                {background?.is_active ? (
                                  <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm  transition-all">
                                    <InActiveShapeList            
                                      page={page}
                                      setpage={setpage}
                                      SubproductCategory={
                                        background?.shape_id
                                      }
                                    />
                                  </button>
                                ) : (
                                  <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-red-600 transition-all">
                                    <ActiveShapeList
                                      page={page}
                                      setpage={setpage}
                                      SubproductCategory={
                                        background?.shape_id
                                      }
                                    />
                                  </div>
                                )}
                              </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="flex justify-center items-center h-[20vh] w-full">
                      <span className="text-center w-full text-xl text-[#7b8794]">
                        No promotion test available
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <p>Loading....</p>
              )}
            </div>
      
          </div>
        </div>
        <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>
        <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={TotalShapeList} 
        pageSize={10}
                  onPageChange={(newPage) => { 
                    handlePage(newPage)
                      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                  }
                   }
      />  
      </div>  
      </div>
    </Template>
  );
};

export default Shape_index;
