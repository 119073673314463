import React, {  useState } from "react";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import axiosConfig from "../../../axios_config/axios_instance";
import toast from "react-hot-toast";
import { getAllSubProductCategoryList } from "../../../services/apiService";
import { useSelector } from "react-redux";
const AddSubCategory = ({category}) => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const [addCategoryLoading, setaddCategoryLoading] = useState(false)

  const {SubProductList}=useSelector((state)=>state.product)
  //intialData for formik
  const initialValues = {
    Sub_product_category_name: "",
    Sub_product_category_desc: "",
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    Sub_product_category_name: Yup.string().trim()

      .required("Please enter your name"),
      Sub_product_category_desc: Yup.string().trim()
     
      .required("required")
     
    // equipment_image: Yup.string().trim(),
  });

  //formik validation
  const { values,resetForm, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: EditInformationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit: async(values, action) => {

        
  try {
          setaddCategoryLoading(true);
          const formdata= new FormData()
          formdata.append("product_sub_category_name", values?.Sub_product_category_name)
          formdata.append("sort_order",SubProductList?.length)
          formdata.append("product_category_id",category?.product_category_id)
          formdata.append("description" ,values?.Sub_product_category_desc)
          const {data}=await axiosConfig.post(`/save-product-sub-category`,formdata)
          if (data?.status === "success") {
            toast.success(data?.message);
            setShowModal(false);
           dispatch(getAllSubProductCategoryList());
            setaddCategoryLoading(false);
          } else {
            toast.error(data?.message);
          setaddCategoryLoading(false);
          }
        } catch (error) {
          toast.error(error?.response?.data?.message || "Can't proceed your request right now!");
          setaddCategoryLoading(false);
        }

    
      },
    });

    const closeModal = () => {
      setShowModal(false);
    };
  



  const openModal = () => {
    setShowModal(true);
    resetForm();
  }

  return (
    <>
     <tr className="group/item bg-transparent  transition-all border-b border-black/5 cursor-pointer"  onClick={openModal}>
                          <td className="text-xs font-semibold pl-10 text-left">
                        <UploadIcon />
                      </td>
                          <td className="text-sm p-4 pl-0">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 whitespace-nowrap text-sm font-medium text-left text-[#186CED]">
                          Add Sub Category
                        </p>
                      </td>
                          </tr>
      {showModal ? (
        <>
        <div className="">
        <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
          <div className="relative p-4 w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative  rounded-lg shadow bg-gray-200">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-2 border-b rounded-t bg-[#454546]">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add Sub Category
                </h3>
                <button
                  onClick={closeModal}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">

                  <div className="col-span-2">
                  <div className="flex flex-col ">
  
          
    
                  </div>
                  
                  </div>
                  <div className="col-span-2">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                       Name
                    </label>
                    <input
                    className="form-input w-full h-8 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                    type={"text"}
                    placeholder="Enter Name"
                    value={values.Sub_product_category_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="Sub_product_category_name"
                    style={
                      errors.Sub_product_category_name &&
                      touched.Sub_product_category_name
                        ? { border: "1px solid red" }
                        : {}
                    }
                  />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Description
                  </label>
                  <div className="flex flex-col flex-grow w-[370px]">
                  <textarea
                    type="text"
                    className="form-input w-full h-40 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                    placeholder="Type here"
                    value={values.Sub_product_category_desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="Sub_product_category_desc"
                    style={
                      errors.Sub_product_category_desc &&
                      touched.Sub_product_category_desc
                        ? { border: "1px solid red" }
                        : {}
                    }
                ></textarea>
             
                </div>
                </div>
            
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
      ) : null}
    </>
  );
};

export default AddSubCategory;
