import React, { useEffect } from 'react'
import {HashRouter as Router,Route,Routes} from "react-router-dom"
import * as routesKey from "../constants/routes"
import * as pages from "../pages"
import axiosConfig from '../axios_config/axios_instance'
import { useDispatch } from 'react-redux'
import { setIsLogin, setJwtToken } from '../redux/slice/adminSlice'
import { useSelector } from 'react-redux'
import ProtectedRoute from '../components/protectedroute'
import Testing from '../pages/test'

const AppRouter = () => {
  const dispatch = useDispatch()
  const {isLogin} = useSelector(state=>state.admin)
  useEffect(()=>{
  const isLogin = localStorage.getItem("is_login")
  const token = localStorage.getItem("jwt_token")
  if(isLogin==="true"||isLogin===true){
    dispatch(setIsLogin(true))
    dispatch(setJwtToken(token))
  }else{
    dispatch(setIsLogin(false))
  }
  },[])
  axiosConfig.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("jwt_token")) { 
        config.headers.Authorization = `Bearer ${localStorage.getItem("jwt_token")}`;
      }
      return config;
    },
    (error) => {
      console.error("Authentication request failed:", error);
      return Promise.reject(error);
    }
  );
  return (
    <Router>
        <Routes>
        <Route element={<ProtectedRoute isAuthenticated={isLogin} redirect="/login" />}>
 {  /*  <Route path={routesKey.HOME} element={<pages.Home />}  /> */}
    <Route path={routesKey.BACKGROUND_CATEGORY} element={<pages.BACKGROUND_CATEGORY />}  />
    <Route path={routesKey.BACKGROUND} element={<pages.BACKGROUND />}  />
    <Route path={routesKey.COLOR} element={<pages.Color_index />}  />
    <Route path={routesKey.TEMPLATE} element={<pages.TEMPLATE />}  />
    <Route path={routesKey.MANAGECOLORCATEGORY} element={<pages.ManageColorCategory />}  />
    <Route path={routesKey.PRODUCT} element={<pages.PRODUCT />}  />
    <Route path={routesKey.EDITPRODUCT} element={<pages.EditProduct />}  />
    <Route path={routesKey.ADDPRODUCT} element={<pages.AddProduct />}  />
    <Route path={routesKey.MANAGEPRODCUTCATEGORY} element={<pages.ManageProductCategory />}  />
    <Route path={routesKey.SHAPE} element={<pages.Shape_index />}  />
    <Route path={routesKey.MANAGESHAPECATEGORY} element={<pages.ManageShapeCategory />}  />
    <Route path={routesKey.ADDSHAPE} element={<pages.Addshape />}  />
    <Route path={routesKey.EDITSHAPE} element={<pages.EditShape />}  />
    <Route path={routesKey.TEMPLATECATEGORY} element={<pages.TemplateCategory />}  />
    <Route path={routesKey.ADDCONFIGURATOR} element={<pages.AddConfigurator />}  />
    <Route path={routesKey.EDITCONFIGURATOR} element={<pages.EditConfigurator />}  />
    <Route path={routesKey.MANAGECONFIGURATOR} element={<pages.ManageConfigurator />}  />
    <Route path={"/test"} element={<Testing />}  />
    </Route>
    <Route element={<ProtectedRoute isAuthenticated={!isLogin} redirect="/" />}>
    <Route path={routesKey.LOGIN} element={<pages.Login />}  />
    <Route path={routesKey.ADMINSIGNUP} element={<pages.SignUp />}  />
     
    </Route>
        </Routes>
    </Router>
  )
}

export default AppRouter