import {createSlice} from "@reduxjs/toolkit"

const NewProductSlice=createSlice({
    name:"product",
    initialState:{
        productCategpryListLoading:false,
        productCategoryList:[],
        productCategoryDetailsLoading:false,
        ProductCategoryDetails:[],
        SubProductListLoading:false,
        SubProductList:[],
        SubProductDetailsLoading:false,
        SubProductDetails:[],
        TotalProductCategoryList:0,
        productDetailsLoading:false

    },
    reducers:{
        getProductCategoryListRequest:(state)=>{
            state.productCategpryListLoading=true;
            state.productCategoryList=[]
        },
        getProductCategoryListSuccess:(state,{payload})=>{
            state.productCategpryListLoading=false;
            state.productCategoryList=payload?.data;
            state.TotalProductCategoryList=payload?.total_elements

        },
        getProductCategoryListFail:(state)=>{
            state.productCategpryListLoading=false;
            state.productCategoryList=[];
        },


        getProductCategoryDetailsRequest:(state)=>{
            state.productCategoryDetailsLoading=true;
            state.ProductCategoryDetails=[]
        },
        getProductCategoryDetailsSuccess:(state,{payload})=>{
            state.productCategoryDetailsLoading=false;
            state.ProductCategoryDetails=payload;

        },
        getProductCategoryDetailsFail:(state)=>{
            state.productCategoryDetailsLoading=false;
            state.ProductCategoryDetails=[];
        },


        getSubproductCategoryListRequest:(state)=>{
            state.SubProductListLoading=true;
            state.SubProductList=[]
        },
        getSubproductCategoryListSuccess:(state,{payload})=>{
            state.SubProductListLoading=false;
            state.SubProductList=payload;

        },
        getSubproductCategoryListFail:(state)=>{
            state.SubProductList=false;
            state.SubProductList=[];
        },


        getSubproductCategoryDetailsRequest:(state)=>{
            state.SubProductDetailsLoading=true;
            state.SubProductDetails=[]
        },
        getSubproductCategoryDetailsSuccess:(state,{payload})=>{
            state.SubProductDetailsLoading=false;
            state.SubProductDetails=payload;

        },
        getSubproductCategoryDetailsFail:(state)=>{
            state.SubProductDetailsLoading=false;
            state.SubProductDetails=[];
        },
        getProductDetailsLoading:(state,{payload})=>{
            state.productDetailsLoading=true
        },
        getProductDetailsSuccess:(state,{payload})=>{
            state.productDetailsLoading=false
            state.productDetails=payload
        },
        getProductDetailsFail:(state,{payload})=>{
            state.productDetailsLoading=false
        },

        ProductswapCategories: (state, { payload }) => {
            const tempArr = [...state.productCategoryList];
            let tempVal = tempArr[payload?.cat1]
            tempArr[payload?.cat1] = tempArr[payload?.cat2];
            tempArr[payload?.cat2] = tempVal;
            state.productCategoryList = tempArr;
       }


    }
})

export default NewProductSlice.reducer
export const {getProductCategoryDetailsFail,ProductswapCategories, getProductCategoryDetailsRequest, getProductCategoryDetailsSuccess,getProductCategoryListFail,getProductCategoryListRequest,getProductCategoryListSuccess, getSubproductCategoryDetailsFail,getSubproductCategoryDetailsRequest,getSubproductCategoryDetailsSuccess,getSubproductCategoryListFail,getSubproductCategoryListRequest,getSubproductCategoryListSuccess} = NewProductSlice.actions
