import React, { useEffect, useRef } from 'react';
import Template from '../../components/Template';
import * as PIXI from 'pixi.js';
import { Transformer } from '@pixi-essentials/transformer';

const Testing = () => {
  const pixiContainerRef = useRef(null);
  
  useEffect(() => {
    // Create a Pixi Application with 600x600 dimensions
    const app = new PIXI.Application({ width: 600, height: 600 });
    pixiContainerRef.current.appendChild(app.view);

    // Load background image and create a sprite
    const loader = PIXI.Loader.shared;
    const resourceName = 'background';
    const resourceURL = 'https://mockey.s3.fr-par.scw.cloud/mockups/tshirt/model-319.png';

    if (!loader.resources[resourceName]) {
      loader.add(resourceName, resourceURL);
    }

    loader.load(() => {
      const background = new PIXI.Sprite(loader.resources[resourceName].texture);
      
      // Ensure the background covers the entire canvas
      background.width = app.screen?.width;
      background.height = app.screen?.height;
      
      app.stage.addChild(background);

      // Create an interactive rectangle
     const  rectangle = new PIXI.Graphics();
      rectangle.lineStyle(2, 0x6ce6cf); // Border color and width
      rectangle.drawRect(0, 0, 120, 80);
      rectangle.x = (app.screen?.width - 120) / 2;
      rectangle.y = (app.screen?.height - 80) / 2;

      // Enable interactivity
      rectangle.interactive = true;
      rectangle.buttonMode = true;

      app.stage.addChild(rectangle);
      let transformer = new Transformer({
        group: [rectangle],
        lockAspectRatio: true,
        wireframeStyle: {
            thickness: 1,
            color: 0xFF0000
        },
        lazyMode: true,
        skewEnabled: true
    });
    
    let handleStyle = {
        color: 0xFFFFFF,
        outlineColor: 0,
        outlineThickness: 1,
        radius: 12,
        shape: "tooth"
    };
    transformer.handleStyle = handleStyle;
    transformer.enabledHandles = [
        "bottomRight", "bottomLeft", "topRight", "topLeft",
        "rotator", "skewHorizontal", "skewVertical"
    ];
    app.stage.addChild(transformer);

    });

    // Clean up the Pixi application on unmount
    return () => {
      app.destroy(true, { children: true });
    };
  }, []);
  return (
    <Template>
      <div ref={pixiContainerRef}></div>
    </Template>
  );
};

export default Testing;
