import {createSlice} from "@reduxjs/toolkit"

const NewShapeSlice=createSlice({
    name:"shape",
    initialState:{
        ShapeListLoading:false,
        ShapeList:[],
        ShapeDetailsLoading:false,
        ShapeDetails:[],
        ShapeCategpryListLoading:false,
        ShapeCategoryList:[],
        ShapeCategoryDetailsLoading:false,
        ShapeCategoryDetails:[],
        SubShapeListLoading:false,
        SubShapeList:[],
        SubShapeDetailsLoading:false,
        SubShapeDetails:[],
        SubShapeCategoryAllModule:[],
        SubShapeCategoryAllModuleLoading:false,
        ShapeCategoryAllModule:[],
        ShapeCategoryAllModuleLoading:false,
        TotalShapeList:0,
        TotalShapeCategorrList:0

    },
    reducers:{
          
        getShapeListRequest:(state)=>{
            state.ShapeListLoading=true;
            state.ShapeList=[]
        },
        getShapeListSuccess:(state,{payload})=>{
            state.ShapeListLoading=true;
            state.ShapeList=payload?.shape_list
            state.TotalShapeList=payload?.total_elements

        },
        getShapeListFail:(state)=>{
            state.ShapeListLoading=true;
            state.ShapeList=[]
        },
          
        getShapeDetailsRequest:(state)=>{
            state.ShapeDetailsLoading=true;
            state.ShapeDetails=[]
        },
        getShapeDetailsSuccess:(state,{payload})=>{
            state.ShapeDetailsLoading=true;
            state.ShapeDetails=payload?.shape_list[0]
        },
        getShapeDetailsFail:(state)=>{
            state.ShapeDetailsLoading=true;
            state.ShapeDetails=[]
        },
        getShapeCategoryListRequest:(state,{payload})=>{
            state.ShapeCategpryListLoading=false
        },
        getShapeCategoryListSuccess:(state,{payload})=>{
            state.ShapeCategpryListLoading=false;
            state.ShapeCategoryList=payload?.category_with_shape_dto;
            state.TotalShapeCategorrList=payload?.total_elements

        },
        getShapeCategoryListFail:(state)=>{
            state.ShapeCategpryListLoading=false;
            state.ShapeCategoryList=[];
        },


        getShapeCategoryDetailsRequest:(state)=>{
            state.ShapeCategoryDetailsLoading=true;
            state.ShapeCategoryDetails=[]
        },
        getShapeCategoryDetailsSuccess:(state,{payload})=>{
            state.ShapeCategoryDetailsLoading=false;
            state.ShapeCategoryDetails=payload;

        },
        getShapeCategoryDetailsFail:(state)=>{
            state.ShapeCategoryDetailsLoading=false;
            state.ShapeCategoryDetails=[];
        },


        getSubShapeCategoryListRequest:(state)=>{
            state.SubShapeListLoading=true;
            state.SubShapeList=[]
        },
        getSubShapeCategoryListSuccess:(state,{payload})=>{
            state.SubShapeListLoading=false;
            state.SubShapeList=payload;

        },
        getSubShapeCategoryListFail:(state)=>{
            state.SubShapeListLoading=false;
            state.SubShapeList=[];
        },


        getSubShapeCategoryDetailsRequest:(state)=>{
            state.SubShapeDetailsLoading=true;
            state.SubShapeDetails=[]
        },
        getSubShapeCategoryDetailsSuccess:(state,{payload})=>{
            state.SubShapeDetailsLoading=false;
            state.SubShapeDetails=payload;

        },
        getSubShapeCategoryDetailsFail:(state)=>{
            state.SubShapeDetailsLoading=false;
            state.SubShapeDetails=[];
        },

       getSubShapeCategoryAllModuleRequest:(state)=>{
          state.SubShapeCategoryAllModuleLoading=false;
          state.SubShapeCategoryAllModule=[]
       },
       getSubShapeCategoryAllModuleSuccess:(state,{payload})=>{
          state.SubShapeCategoryAllModuleLoading=false;
          state.SubShapeCategoryAllModule=payload?.sub_category_list;
          console.log("payload...", payload)
       },

       getSubShapeCategoryAllModuleFail:(state,{payload})=>{
          state.SubShapeCategoryAllModuleLoading=false;
          state.SubShapeCategoryAllModule=payload?.shape_data;
       },

       getShapeCategoryAllModuleRequest:(state,{payload})=>{
          state.ShapeCategoryAllModuleLoading=false;
          state.ShapeCategoryAllModule=payload?.shape_category_data
       },
       getShapeCategoryAllModuleSuccess:(state,{payload})=>{
          state.ShapeCategoryAllModuleLoading=false;
          state.ShapeCategoryAllModule=payload?.category_with_shape_dto;
       },
       getShapeCategoryAllModuleFail:(state,{payload})=>{
          state.ShapeCategoryAllModuleLoading=false;
          state.ShapeCategoryAllModule=payload?.shape_data;
        },
        swapCategories: (state, { payload }) => {
            const tempArr = [...state.ShapeCategoryList];
            let tempVal = tempArr[payload?.cat1]
            tempArr[payload?.cat1] = tempArr[payload?.cat2];
            tempArr[payload?.cat2] = tempVal;
            state.ShapeCategoryList = tempArr;
       }
    }
})

export default NewShapeSlice.reducer
export const {getShapeDetailsFail,getShapeDetailsRequest,getShapeDetailsSuccess,getShapeListFail,getShapeListRequest,getShapeListSuccess,getShapeCategoryDetailsFail,getShapeCategoryDetailsRequest,getShapeCategoryDetailsSuccess,getShapeCategoryListFail,getShapeCategoryListRequest,getShapeCategoryListSuccess,getSubShapeCategoryDetailsFail,getSubShapeCategoryDetailsRequest,getSubShapeCategoryDetailsSuccess,getSubShapeCategoryListFail,getSubShapeCategoryListRequest,getSubShapeCategoryListSuccess,getSubShapeCategoryAllModuleFail,getSubShapeCategoryAllModuleRequest,getSubShapeCategoryAllModuleSuccess,getShapeCategoryAllModuleFail,getShapeCategoryAllModuleRequest,getShapeCategoryAllModuleSuccess,swapCategories} = NewShapeSlice.actions
