import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import {ReactComponent as EditIcon} from "../assets/icons/pencil_icon.svg"
import {ReactComponent as Delete} from "../assets/icons/trashiconsmall.svg"
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as routesKey from "../../constants/routes"
import AddTemplate from './AddTemplate'
import { useDispatch } from 'react-redux'
import { getTemplateCategory, getTemplates } from '../../services/api'
import EditTemplate from './EditTemplate'
import DeleteModal from '../../components/common/DeleteModal'
import axiosConfig from '../../axios_config/axios_instance'
import toast from 'react-hot-toast'
import EnableDisableModal from '../../components/common/EnableDisableModal'
import Pagination from '../../components/pagination'
// import EditDistressed from './EditDistressed'
// import AddDistressed from './AddDistressed'

const TemplatePage = () => {
  const {templateList,total_template_element,templateCategory} = useSelector(state=>state.template)
  const [page, setPage] = useState(1)
    const [editId, seteditId] = useState("")

const [loading, setLoading] = useState(false)









    const [history, setHistory] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
   const dispatch = useDispatch()
    const handleSubmit = (event) => {
      event.preventDefault();
  
      if (!searchTerm.trim()) return;
  
      setHistory([searchTerm, ...history]);
      setSearchTerm('');
    };
  
    const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
    };
  
    const handleInputFocus = () => {
      // Most recent search first.
      const reversedHistory = history.slice().reverse();
  
      return reversedHistory.map((item, index) => (
        <div key={index} className="history-item">{item}</div>
      ));
    };
  
    useEffect(()=>{
        dispatch(getTemplates(page))
    },[page])
    useEffect(()=>{
      if(!templateCategory.length){
        dispatch(getTemplateCategory())

      }
    },[dispatch])
    const handleInputBlur = () => {
      // Clear history on blur
      setHistory([]);
    };

    const handleDelete=async (id,setshowModal)=>{
      try {
        setLoading(true)
        const {data} = await axiosConfig.delete(`/status-template-change?template_id=${id}`)
        if(data?.status==="success"){
         dispatch(getTemplates(page))
         setshowModal(false)
         toast.success("Updated successfully")
        }else{
         toast.error("Something went wrong !!")
        }

        setLoading(false)
      } catch (error) {
       toast.error("Something went wrong !!")
       setLoading(false)
      }
 }



  return (
    <Template>
    <div className="flex  justify-between flex-grow-0 flex-shrink-0 items-center  py-4">
    <div className="flex  w-full  justify-between flex-grow-0 flex-shrink-0 items-center  pt-0">
     <div className="flex flex-row flex-shrink-0 flex-grow-0">
       <h2 className="text-xl font-sans font-bold">Template</h2>
     </div>
     <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
       <Link to={routesKey.TEMPLATECATEGORY} className='text-lg'>
       Manage Category
       </Link>
       {/* {
           editId&&
     <EditDistressed modalOpen={editId} dismissModal={()=>seteditId("")}/>
       } */}


     
     <AddTemplate/>

     </div>
   </div>
   </div>
 
 {/* search */}
   {/* <div className="container">
      <form id="searchForm" onSubmit={handleSubmit}>
        <div className="input-wrapper">
          <input
            id="search"
            type="search"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search"
            required
          />
          <button type="submit">Search</button>
        </div>
        <div id="history" onFocus={handleInputFocus} onBlur={handleInputBlur}></div>
      </form>
    </div> */}
   
   {/* <div className='text-black font-medium text-[30px]'>Backgrounds</div> */}
   <div className="flex flex-col border-2 p-4 bg-ed-600  w-full rounded-md  ">
          {templateList?.length ?    <table >
               <thead>
     <tr className=" ">
    
       <th className="border-b text-center  whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 ">
         Preview
       </th>
       
       
       <th className="border-b whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3  ">
       Name
       </th>
       <th className="border-b whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3  ">
       Price
       </th>
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1  py-3 ">
       No. of colors
       </th>
       
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Category
       </th>
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Actions
       </th>
       
      
     </tr>
               </thead>
                 <tbody className="">
                   {templateList?.map((template,i) => (
                      <tr   key={template?.designer_template_id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                     
                      
                     
                       <td className="   whitespace-nowrap py-2  leading-tight  flex flex-col  items-center h-full  ">
                         <img alt='' src={template?.template_image} className='h-12 w-12 rounded-md'/>
                       </td>
                      <td className="whitespace-nowrap text-primary font-normal  text-[14px] leading-tight capitalize px-2 text-[#444444] text-center">{template?.template_name}</td>
                      
                       <td className="  text-primary font-normal  text-[14px] whitespace-nowrap  leading-tight  capitalize text-[#444444]  text-center">{template?.price}</td>
                      <td className=" text-primary font-normal  text-[14px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{template?.no_of_colors_used}</td>
                       <td className="  text-primary font-normal  text-[14px] whitespace-nowrap  leading-tight  capitalize text-[#444444]  text-center">{template?.template_category_name}</td>
                      <td className="text-primary  font-normal  text-[14px] leading-tight capitalize text-[#444444] mx-auto my-auto items-center text-center">
                      <div className="group/edit invisible  group-hover/item:visible flex justify-center items-center gap-1">
                      <button
                               onClick={() => {
                                   // alert(background?.background_id)
                                  // seteditId(template?.designer_template_id)
                                 //setuserDetails(user);
                               }}
                               className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all"
                             >
                              <EditTemplate templateDetails={template} page={page}/>
                             </button>
                             <EnableDisableModal   onConfirm={handleDelete} id={template?.template_id} isLoading={loading} desc={"Template"} is_active={template?.is_active} />

                             {/* <DeleteModal  heading={"Template"} onDelete={handleDelete} id={template?.template_id} isLoading={loading} desc={"Are you sure want to delete template ?"} /> */}

                       {/* <Delete className='p-0  shadow-md'/> */}
                      </div>
                      </td>
                      </tr>
                   ))}
                 </tbody>
               </table>: <div className="flex justify-center items-center h-[20vh] w-full">
           <span className="text-center w-full text-xl text-[#7b8794]">
             No template available
           </span>
         </div>}
             </div>
             <div className='flex justify-center items-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>
        <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={total_template_element} 
        pageSize={10}
                  onPageChange={(newPage) => { 
                    setPage(newPage)
                      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                  }
                   }
      />  
          </div>
   
</Template>
  )
}

export default TemplatePage