import React, { Fragment, useState } from 'react';
import ImageCanvas from './Canvas';
// import Template from '../../../../components/common/Template';
// import bgimg from "../../../../assets/images/600X600bg.jpg"
import { Dialog, Transition } from '@headlessui/react';
import {ReactComponent as CloseModalIcon } from "../assets/icons/close_modal_icon.svg"
import {ReactComponent as SettingIcon } from "../assets/icons/setting_icon.svg"
import {ReactComponent as RedSettingIcon } from "../assets/icons/red_setting_icon.svg"
import {ReactComponent as TickIcon } from "../assets/icons/single_tick_icon.svg"
import {ReactComponent as Delete } from "../assets/icons/deleteIcon.svg"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createMultipleEmptyRect, removeCo_ordinate, setImageCo_ordinates } from '../../redux/slice/productListSlice';
import Files from "react-files"
import toast from 'react-hot-toast';
import PixiJsCanvas from './PixiJsCanvas';
const Fabriccanvas = ({setFieldValue,chekingError,error,value,image,index,allImage,secondValue,layer,mask,thumb}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {co_ordinates,emptyRect} = useSelector(state=>state.productList)
  const [create, setcreate] = useState(secondValue?secondValue:"")
  const [rect, setRect] = useState([""])
  // const [co_ordinate, setCo_ordinate] = useState([""])
 const dispatch = useDispatch()

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
const handleClick=()=>{
 setcreate("create2")
}

const handleType=(i,val)=>{
  const tempValues=[...allImage];
  tempValues[i].type=val;
  setFieldValue("files",tempValues)
}
const addRect=()=>{
  dispatch(createMultipleEmptyRect(index))
//  dispatch(setImageCo_ordinates([...co_ordinates,""]))
//  setCo_ordinate(...co_ordinate,"")
}

// const saveRect=(i,codinate)=>{
//   console.log("data coming",i,codinate);
//   let tempRect=[...co_ordinates];
//   tempRect[i]=codinate
//   dispatch(setImageCo_ordinates(tempRect))

// }

const handleCo_ordinateDelete=(i)=>{
  if(!(co_ordinates[index].length===1)){
    dispatch(removeCo_ordinate({index:i,inner_index:index}))
  }else{
    toast.error("You can't delete all print area")
  }
}

const handleFileUpload=(file,field)=>{
  const tempValues=[...allImage];
  tempValues[index][field]=file;
  setFieldValue(field,tempValues)
}


  return (
<>
    <button
      type="button"
      className="p-2 border border-white/20  rounded-md hover:bg-blue-800 md:hover:bg-white/10 transition relative flex md:w-[140px] md:h-8 md:p-0 md:flex md:justify-center md:items-center md:border-0"
      onClick={openModal}
          >
            <div className='flex justify-between w-[100%]'>
              <input className='w-[70%] px-2'value={allImage[index].type}  onChange={(e)=>handleType(index,e.target.value)} placeholder={`Side ${index+1}`}  onClick={(e)=>e.stopPropagation()}/>
              <div className='w-[25%]'>

              {co_ordinates[index]?.some(val=>val?.X==="") ? <RedSettingIcon/>:
                  <div className='flex gap-1 items-center'>
                      {
                          true&& <TickIcon /> 
              }    <SettingIcon/>   
              </div>
              }
              </div>
            </div>
              
    </button>
    <Transition
      show={modalOpen}
      className="fixed inset-y-0 max-w-[700px] min-h-screen mx-auto z-50"
    >
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-y-0 inset-x-0 m-auto min-w-full sm:max-w-[700px] bg-black/20 backdrop-blur-sm z-40"
          onClick={closeModal}
        >
        </Transition.Child>
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
          className="fixed top-0  inset-x-0 m-auto min-w-[900px] max-w-full md:max-w-[900px] min-h-[90vh] max-h-[90vh] rounded-t-xl md:rounded-xl text-base shadow-lg overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
        >
          <div className="flex flex-col justify-start items-start self-stretch flex-grow relative bg-white min-h-[inherit]">
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0  bg-white sticky top-0 z-50 p-4 px-6 gap-8">
              <div className="flex flex-col w-full gap-10">
                <div className="flex  justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2">
                  <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-center text-black">
                   Product Image
                                      </p>
                                      <div className='flex gap-5'>
                                        {/* <select value={allImage[index].type} onChange={(e)=>handleType(e.target.value)} className='border-[1px] outline-none'>
                                          <option value={""}>Select</option>
                                          <option value={"front"}>Front</option>
                                          <option value={"back"}>Back</option>
                                          <option value={"left"}>Left</option>
                                          <option value={"right"}>Right</option>
                                        </select> */}
                                      <p onClick={()=>setModalOpen(false)} className='bg-blue-600 cursor-pointer rounded-md p-2 text-white font-medium'>Save</p>
                                      <CloseModalIcon onClick={()=>setModalOpen(false)} className='cursor-pointer' />
                                      </div>
                </div>
                <div className='justify-between items gap-3 w-full flex'>
                  <div >

                    <div className='w-[100%] flex flex-col gap-2' >
                  {
                    co_ordinates[index]?.map((v,i)=>
                <button onClick={()=>handleCo_ordinateDelete(i)} className='justify-center border-[1px] rounded-md  gap-2 p-2 w-full items-center my-auto flex'>
               <p className=' text-base font-medium'>Area {i+1}</p>
               <Delete/>
                  </button>

)
}
</div>
                  <button className='text-base font-medium' onClick={addRect}>+ Add Imprint</button>

                  </div>
                
               <div className='w-[70%]'>
                
               <ImageCanvas imageUrl={image} rectArray={rect} secondCordinator={create} secondValue={secondValue} setFieldValue={setFieldValue}   value={value} index={index} allImage={allImage} error={error} co_ordinates={co_ordinates[index]} emptyRect={emptyRect[index]} />
               {/* <PixiJsCanvas imageUrl={image} rectArray={rect} secondCordinator={create} secondValue={secondValue} setFieldValue={setFieldValue}   value={value} index={index} allImage={allImage} error={error} co_ordinates={co_ordinates[index]} emptyRect={emptyRect[index]} /> */}
               
               </div>
               <div className='w-[15%] flex  flex-col'>
                {
                  allImage?.filter(v=>v.image!=="")?.map((v,i)=>
                    <div className='flex gap-2 items-center px-[5px] py-[10px]'>
                <img alt='' src={typeof v?.image==="object"?v?.image?.preview?.url:v?.image} className='w-[40px] h-[40px] rounded-md bg-red-600'/>
                <input  className='w-[50%] border-[1px] p-1'value={v.type} onChange={(e)=>handleType(i,e.target.value)} placeholder={`Side ${i+1}`} onClick={(e)=>e.stopPropagation()}/>
                </div>
                  
                  )
                }

                <div className='flex flex-col gap-2'>
                <div className='flex flex-col gap-1'>
                <span>Thumb</span>
                <Files
                className="files-dropzone cursor-pointer"
                onChange={(doc) =>{
                  if(doc.length){
                    handleFileUpload(doc[0],"thumb")
                  }
                }}
                onError={(error) => toast.error(error.message)}
                accepts={["image/png"]}
                
                // maxFileSize={512000}
                maxFileSize={5000000}
                minFileSize={0}
                multiple
                clickable
              >
              <div className='w-full '>
            {
              thumb? <img src={thumb} alt='layer_image'  className='w-full h-[70px] object-contain bg-black' />:<div className='border border-dashed border-gray-500 flex justify-center items-center'>
              Upload
              </div>
              }
              </div>
              </Files>
                </div>
                <div className='flex flex-col gap-1'>
                <span>Layer</span>
                <Files
                className="files-dropzone cursor-pointer"
                onChange={(doc) =>{
                  if(doc.length){
                    handleFileUpload(doc[0],"layer")
                  }
                }}
                onError={(error) => toast.error(error.message)}
                accepts={["image/png"]}
                
                // maxFileSize={512000}
                maxFileSize={5000000}
                minFileSize={0}
                multiple
                clickable
              >
              <div className='w-full '>
            {
              layer? <img src={layer} alt='layer_image'  className='w-full h-[70px] object-contain bg-black' />:<div className='border border-dashed border-gray-500 flex justify-center items-center'>
              Upload
              </div>
              }
              </div>
              </Files>
                </div>
                <div className='flex flex-col gap-1'>
                <span>Mask</span>
                <Files
                className="files-dropzone cursor-pointer"
                onChange={(doc) =>{
                  if(doc.length){
                    handleFileUpload(doc[0],"mask")
                  }
                }}
                onError={(error) => toast.error(error.message)}
                accepts={["image/png"]}
                
                // maxFileSize={512000}
                maxFileSize={5000000}
                minFileSize={0}
                multiple
                clickable
              >
              <div className='w-full '>
            {
              mask? <img src={mask} alt='layer_image' className='w-full h-[70px] object-contain bg-black' />:<div className='border border-dashed border-gray-500 flex justify-center items-center'>
              Upload
              </div>
              }
              </div>
              </Files>
                </div>
                </div> 
               </div>
                  </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  </>
  );
};

export default Fabriccanvas;