import React, { useState } from 'react'
import Template from '../../components/Template'
import { Link, useNavigate } from 'react-router-dom'
import { MdDelete } from "react-icons/md";
import Files from "react-files"
import * as routesKey from "../../constants/routes"
import { IoIosAdd } from 'react-icons/io'
import { Switch } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { addConfiguratorParts, addConfiguratorSubParts, removeConfiguratorParts, removeConfiguratorSubParts, removeErrors, resetData, setPartsName, setSubPartsDefault, setSubPartsImage, setSubPartsName, setSubPartsThumb, seterrors } from '../../redux/slice/configuratorSlice'
import toast from 'react-hot-toast';
import axiosConfig from '../../axios_config/axios_instance';
const AddConfigurator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {data,errors}=useSelector((state)=>state.configurator)
  const [configuratorname, setConfiguratorname] = useState("")
  const [isLoading, setisLoading] = useState(false);
  const validateAllField = () => {
    let isValidated = true;
    if (!configuratorname?.trim()) {
      isValidated = false;
      if (!errors.includes("configuratorname")) { 
        dispatch(seterrors(`configuratorname`))
      }

    }
    data.forEach((item,parentIndex) => {
      if (!item?.name?.trim()) {
      isValidated = false;
        if (!errors.includes(`partsName${parentIndex}`)) { 
          dispatch(seterrors(`partsName${parentIndex}`))
        }
      }
      item?.sub_parts?.forEach((child, index) => {
        if (!child?.name?.trim()) {
          isValidated = false;
          if (!errors.includes(`subPartsName${parentIndex}${index}`)) { 
            dispatch(seterrors(`subPartsName${parentIndex}${index}`))
          }
        }
        if (!child?.thumb) {
          isValidated = false;
          if (!errors.includes(`subPartsThumb${parentIndex}${index}`)) { 
            dispatch(seterrors(`subPartsThumb${parentIndex}${index}`))
          }
        }
        if (!child?.image) {
          isValidated = false;
          if (!errors.includes(`subPartsImage${parentIndex}${index}`)) { 
            dispatch(seterrors(`subPartsImage${parentIndex}${index}`))
          }
        }
      })
    })
    return isValidated;
  }
  const handleSubmit = async () => {
    if (!validateAllField()) {
      return;
      }
        try {
          const postdata = new FormData();
          postdata.append("configurator_name", configuratorname)
          let partsName = [];
          let total_variations = [];
          let sub_parts_name = [];
          data?.forEach((item) => {
            partsName.push(item.name);
            total_variations.push(item?.sub_parts?.length); 
            item?.sub_parts?.forEach((child,i) => {
              postdata.append('variant_thumbs',child?.thumb)
              postdata.append('variant_images',child?.image)
              sub_parts_name.push(child.name)
              if (child?.is_default === 1) {
          postdata.append('variant_defaults',1)
        } else {
                postdata.append('variant_defaults',0)
              }
            })
          })
          postdata.append('parts_name',partsName)
          postdata.append('total_variations',total_variations)
          postdata.append('variant_names',sub_parts_name)
          setisLoading(true);
          const { data: saveProductResponse } = await axiosConfig.post(`/configurator/save-product-configurator`, postdata);
          if (saveProductResponse?.status === "success") {
            navigate(`/manage-configurator`);
            toast.success(saveProductResponse?.message);
            dispatch(resetData())
            setisLoading(false);
          } else {
            toast.error(saveProductResponse?.message||"Some error occured");
            setisLoading(false);
          }
        } catch (error) {
          toast.error(error?.response?.data?.message||"Some error occured");
          setisLoading(false);
        }
  }
  return (
      <Template>
           <div className='flex flex-col gap-8 mx-7'>
            <div className='flex w-full justify-between items-center'>

        <div className='flex gap-5 items-center'>
            <button className='cursor-pointer' onClick={()=>navigate(-1)}>

        <svg  fill="#000000" width="20px" height="20px" viewBox="0 0 1.2 1.2" id="left" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path id="primary" d="M1.05 0.55H0.271l0.265 -0.265a0.05 0.05 0 1 0 -0.071 -0.071l-0.35 0.35a0.05 0.05 0 0 0 0 0.071l0.35 0.35a0.05 0.05 0 0 0 0.071 0 0.05 0.05 0 0 0 0 -0.071L0.271 0.65H1.05a0.05 0.05 0 0 0 0 -0.1" /></svg>
            </button>
        <h2 className='text-2xl font-medium'>Add Configurator</h2>
        </div>
        <div className="f ">
            <button
              disabled={isLoading}
                  onClick={handleSubmit}
                    className="bg-blue-500 p-2  hover-bg-blue-700 text-white font-bold py-2 px-7  rounded-[90px] focus:outline-none focus:shadow-outline"
                    type="submit"
                    // disabled={saveLoading}
                    // style={saveLoading?{background:"#808080"}:{}}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className='w-[60%] max-w-[60%] flex flex-col gap-7'>
        <div className='w-full'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Configurator Name
                        </label>
                  <input
                    name="name"
                    type="text"
                    style={errors.includes("configuratorname")?{border:"1px solid red"}:{}}
                    value={configuratorname}
              onChange={(e) => {
                if (errors.includes("configuratorname")) {
                 dispatch(removeErrors("configuratorname"))
                }
                setConfiguratorname(e.target.value)
                    }}
                    // onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="configurator name"
                  />
                </div>
                </div>
                  <div className='w-full'>
                      <div className='w-[60%] justify-between flex'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold "
                    htmlFor="name">
                          Parts
                          </label>
                          <button onClick={()=>dispatch(addConfiguratorParts())} className='flex cursor-pointer gap-2 items-center border rounded-md border-blue-600 py-2 px-3'><IoIosAdd />
                          <span>Add Parts</span>
                          </button>
                      </div>
          {
            data?.map((item,parentIndex)=>(
              <div className='flex flex-col gap-3'>
                <div className='w-[60%]'>
                  <div className='flex w-full justify-between py-2 items-center'>
                    <span className='text-gray-700 font-bold'>  {parentIndex+1}.</span>
                  {parentIndex>0 &&  <MdDelete fill='red' className='h-6 w-6 cursor-pointer' onClick={()=>dispatch(removeConfiguratorParts(parentIndex))} />}
                  </div>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                        Name
                        </label>
                  <input
                    name="name"
                    type="text"
                    style={errors.includes(`partsName${parentIndex}`)?{border:"1px solid red"}:{}}
                    value={item.name}
                    onChange={(e) => { 
                      if (errors?.includes(`partsName${parentIndex}`)) {
                 dispatch(removeErrors(`partsName${parentIndex}`))
                      }
                    dispatch(setPartsName({index:parentIndex,value:e.target.value}))
                    } 
                    }
                    // onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="parts name"
                  />
                          </div>
                          <div className='w-[60%] justify-between flex ml-10'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold "
                    htmlFor="name">
                        Sub  Parts
                          </label>
                          <button onClick={()=>dispatch(addConfiguratorSubParts(parentIndex))} className='flex cursor-pointer gap-2 items-center border rounded-md border-blue-600 py-2 px-3'><IoIosAdd />
                          <span>Add Subparts</span>
                          </button>
                          </div>
                          <div className=' ml-10 flex flex-col gap-3 w-full'>
                  {
                    item?.sub_parts?.map((child,index) => (
                      <div className='flex gap-3 items-center'>
                          <Switch
        checked={child?.is_default===1?true:false}
                          onChange={(value) => {
                              dispatch(setSubPartsDefault({parentIndex,index}))
        }}
        className={`${child?.is_default===1 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[18px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${child?.is_default===1 ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
                          <div className='w-[25%]'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Name
                        </label>
                  <input
                    name="name"
                    type="text"
                            value={child.name}
                            style={errors.includes(`subPartsName${parentIndex}${index}`)?{border:"1px solid red"}:{}}
                            onChange={(e) => {
                              if (errors?.includes(`subPartsName${parentIndex}${index}`)) {
                                dispatch(removeErrors(`subPartsName${parentIndex}${index}`))
                              }
                              dispatch(setSubPartsName({ parentIndex, index, value: e.target.value }))
                            }}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="sub parts name"
                  />
                          </div>
                          <div className='w-[15%]'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Thumb
                          </label>
                          <Files
                    className="files     -dropzone cursor-pointer"
                            onChange={(file) => {
                              if (file.length) {
                                dispatch(setSubPartsThumb({parentIndex,index,value:file[0]}))
                              }
                              if (errors?.includes(`subPartsThumb${parentIndex}${index}`)) {
                                dispatch(removeErrors(`subPartsThumb${parentIndex}${index}`))
                              }
                    }}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".png",".jpeg",".jpg"]}
                    maxFileSize={512000}
                    minFileSize={0}
                    multiple={false}
                    clickable
                          >
                            {child?.thumb ? <img className='h-12 w-12 object-contain cursor-pointer' src={child?.thumb?.preview?.url} /> : <div className='w-full flex justify-center items-center border-dashed border border-black/50 py-2'
                             style={errors.includes(`subPartsThumb${parentIndex}${index}`)?{border:"1px solid red"}:{}}
                            >
                                      <IoIosAdd className='w-5 h-5' />
                          </div>}
                    </Files>
                          </div>
                        <div className='w-[15%]'>
                      
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Image
                          </label>
                          <Files
                    className="files     -dropzone cursor-pointer"
                            onChange={(file) => {
                              if (file.length) {
                                dispatch(setSubPartsImage({parentIndex,index,value:file[0]}))
                              }
                              if (errors?.includes(`subPartsThumb${parentIndex}${index}`)) {
                                dispatch(removeErrors(`subPartsThumb${parentIndex}${index}`))
                              }
                    }}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".png",".jpeg",".jpg"]}
                    maxFileSize={512000}
                    minFileSize={0}
                    multiple={false}
                    clickable
                    >
                            {child?.image ? <img className='h-12 w-12 object-contain cursor-pointer' src={child?.image?.preview?.url} /> : <div className='w-full flex justify-center items-center border-dashed border border-black/50 py-2'
                            style={errors.includes(`subPartsImage${parentIndex}${index}`)?{border:"1px solid red"}:{}}
                            >
                                      <IoIosAdd className='w-5 h-5' />
                          </div>}
                    </Files>
                        </div>
                        <div className='flex flex-col gap-2'>

                        <label  className="block text-base  text-gray-700 font-bold"
                    htmlFor="name">
                        </label>
                        {index>0 &&  <MdDelete fill='red' className='h-6 w-6 cursor-pointer' onClick={()=>dispatch(removeConfiguratorSubParts({parentIndex,index}))} />}
                        </div>
                              </div>
                    ))
                        }
                          </div>
                      </div>
            ))
                      }
                </div>
            </div>
    </Template>
  )
}

export default AddConfigurator