import React, { useState } from 'react'
import { IoIosAdd } from "react-icons/io";
import toast from 'react-hot-toast';
import axiosConfig from '../../axios_config/axios_instance';
import { useDispatch } from 'react-redux';
import { getAllColorList } from '../../services/apiService';
import { FiLoader } from "react-icons/fi";
import Files from "react-files";

const AddColor = () => {
    const [showmodal, setShowModal] = useState(false);
    const [isloading,setIsLoading]=useState(false)
    const [color,setColor]=useState("")
    const [name,setName]=useState("")
    const [colorImage, setColorImage]=useState("")
    const dispatch=useDispatch();

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
    setColor("");
    setName("")
    setColorImage("")
  };



     

  // const saveData=()=>{
  //   if(name&&color&& colorImage){
  //     const postdata=new FormData();
  //     postdata.append("hash_code",color)
  //     postdata.append("colour_name",name)
  //     uploadColor(postdata)
  //   }else{
  //     toast.error("Please fill all input field")
  //   }
  //  }
  const saveData = () => {
    if (name) {
      const postData = new FormData();
      postData.append("colour_name", name);
      if (color) {
        postData.append("hash_code", color);
      }
      if (colorImage) {
        postData.append("colour_image", colorImage);
      }
      uploadColor(postData);
    } else {
      toast.error("Please fill the name input field");
    }
  };
  
   
  const uploadColor= async (payload)=>{
    try {
    setIsLoading(true)
   const {data}= await axiosConfig.post(`/save-colour `,payload)
      if(data?.status==="success"){
        setShowModal(false)
        setColor("");
        setName("")
        setColorImage("")
        dispatch(getAllColorList())
        toast.success(data?.message)
      } 
    } catch (error) {
      toast.error("Something went wromg")
    }finally{
      setIsLoading(false)
    }
  }

  const handleImageChange = async (files) => {
    if (files.length) {
      const file = files[0];
      // Assuming you're handling image file according to your backend requirements
      setColorImage(file);
      // Clearing the color when an image is selected
      setColor('');
    }
  };
  return (
  
    <div>
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 p-1 px-3 cursor-pointer bg-gray-200 rounded-md"
      onClick={openModal}
    >
      <button className="text-[16px]">
        <div className="flex items-center">
          <IoIosAdd className="mr-1" /> Add Color
        </div>
      </button>
    </div>
    {showmodal ? (
          <div className="">
          <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
            <div className="relative p-4  w-[550px] max-h-full">
              {/* Modal content */}
              <div className="relative  rounded-lg shadow bg-gray-200">
                {/* Modal header */}
                <div className="flex items-center justify-between p-4 md:p-2 border-b rounded-t bg-[#454546]">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Add New Color
                  </h3>
                  <button
                    onClick={closeModal}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                  </button>
                </div>
                {/* Modal body */}
                <div className="p-4 md:p-5">
                  <div className="flex flex-col">

                    <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-8">
    
                    <div className='h-36 w-36 gap-2 border-dotted items-center  justify-center  flex-col flex border-[1px] border-black'>
                    {colorImage ? (
                      <img  src={URL.createObjectURL(colorImage)} alt="Color Image" className="h-full w-full object-cover" />
                    ) : (
                      <div className={`bg-[${color}] h-full w-full border border-black`} style={{background:color}} fill={color} height={'120px'} width={'120px'} />
                    )}                   </div>
                    <Files
                    className="files     -dropzone cursor-pointer"
                    onChange={(file) => handleImageChange(file)}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".svg"]}
                    maxFileSize={512000}
                    minFileSize={0}
                    multiple
                    clickable
                    >
                
                  </Files>
      
                    </div>
                    
                  
    
              
                    
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="name"
                        className="block mb-2 mt-2 text-sm font-medium text-gray-900"
                      >
                        Color Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className=" text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Color Name"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                      
                      />
                    </div>
              
                  <div className='flex w-full justify-between'>

                    <div className="row-span-2 w-[49%] sm:col-span-1">
                    <div className='w-[100%]  '>
                    <p className='block mb-2 mt-1 text-sm font-medium text-gray-900'>Choose a color</p>
                    <input type='color' value={color}   onChange={(e)=>{
                      setColor(e.target.value);
                      setColorImage(null)
                    }} className='border-[1px] w-[100%] border-[#808080] h-10   rounded-md  '/>

            
                      </div>
                    </div>


                    <div className="row-span-2 w-[49%] sm:col-span-1">
                    <div className='w-[100%] '>
                    <p className='block mb-2 text-sm mt-1 font-medium text-gray-900'>Color Code</p>
                    <input   value={color} placeholder='#000'   onChange={(e)=>setColor(e.target.value)} className=' text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 dark:border-gray-500 dark:placeholder-gray-400 text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 '/>

            
                      </div>
                    </div>
                  </div>
                     <div className='my-0 text-[18px] text-center'>
                    or 
                    </div>
                    <div className="row-span-2 sm:col-span-1">
                    <div className='w-[100%]  '>
                    <p className='block mb-2 text-sm font-medium text-gray-900'>Choose a image</p>
                    <Files
                    className="files     -dropzone cursor-pointer"
                    onChange={(file) => handleImageChange(file)}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".png"]}
                    maxFileSize={512000}
                    minFileSize={0}
                    multiple
                    clickable
                    >
                    <div id="file-uploader2" class="wrapper js">    
                    <form class="js--upload-form is-droppable">
                        <span class="text--center">
                          <i class="fa fa-cloud-upload fa-2"></i>
                                                              {
                                                                  colorImage? <>
                                                                      <span class="js--advanced-upload">{ colorImage?.name} <label class="btn btn--link" for="file">replace</label></span>
                                                                </>:<span class="js--advanced-upload">Choose file <label class="btn btn--link" for="file">browse</label></span>
                                }
                          <span type="file" name="files[]" id="file" class="js--basic-upload hide" data-multiple-caption="{count} files selected" multiple="" accept=".pdf,.xlsx" />
                        </span>
                      </form>
                                            </div>
                
                  </Files>
            
                      </div>
                    </div>

            
              
                  </div>
                

                  {isloading ? (
                    <div className='flex justify-end'>
                    <div className="spinner-border text-light text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" role="status">
                      <span className="visually-hidden"><FiLoader /></span>
                    </div>
                    </div>
                  ) : (
                    <div className='flex justify-end'>
                    <button
                    type="submit"
                    onClick={saveData}
                    className="text-white inline-flex mt-2 items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Save
                  </button>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AddColor
