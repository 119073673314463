import { createSlice } from "@reduxjs/toolkit";

const NewColorList = createSlice({
    name: "colorlist",
    initialState: {
        ColorListLoading: false,
        ColorList:[],
        ColorDetails:[],
        ColorCategory:[],
        page:1,
        total_pages:0,
        TotalColorList:0,
        TotalColorCategory:0,
    },
    reducers: {
         getColorListRequest:(state)=>{
            state.ColorListLoading=true;
         },
         getColorDetailsRequest:(state)=>{
            state.ColorListLoading=true;
            state.ColorDetails=[]
         },
         getColorListSuccess:(state, {payload})=>{
            state.ColorListLoading=false;
            state.ColorList=payload?.colour_list;
            state.TotalColorList=payload?.total_elements
         },
         getColorDetailsSuccess:(state, {payload})=>{
            state.ColorListLoading=false;
            state.ColorDetails=payload?.font_catgeory_List;
         },
         getColorCategorySuccess:(state,{payload})=>{
          state.ColorListLoading=false;
          state.ColorCategory=payload?.font_catgeory_List;
          state.TotalColorCategory=payload?.total_elements;
         },
         getColorListFail:(state)=>{
            state.ColorListLoading=false;
            state.ColorList=[];
         },
         getColorDetailsFail:(state)=>{
            state.ColorListLoading=false;
            state.ColorDetails=[]
         }
     
    }
})  

export default NewColorList.reducer;
export const {getColorListFail, getColorListRequest,getColorListSuccess,getColorCategorySuccess, getColorDetailsSuccess,getColorDetailsFail,getColorDetailsRequest}= NewColorList.actions