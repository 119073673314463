import {createSlice} from "@reduxjs/toolkit"

const backgroundSlice=createSlice({
    name:"background",
    initialState:{
        backgroundList:[],
        backgroundLoading:false,
        backgroundCategoryList:[],
        categoryLoading:false,
        total_background_element:0,
        total_background_category:0,
        AllBackgroundCategoryModuleList:[],
    },
    reducers:{
        getBackgroundListLoading:(state,{payload})=>{
            state.backgroundLoading=true
        },
        getBackgroundListSuccess:(state,{payload})=>{
            state.backgroundList=payload
            state.backgroundLoading=false
        },
        getBackgroundListFail:(state,{payload})=>{
            state.backgroundLoading=false
        },
        getBackgroundCategoryListLoading:(state,{payload})=>{
            state.categoryLoading=true
        },
        getBackgroundCategoryListSuccess:(state,{payload})=>{
            state.backgroundCategoryList=payload
            state.categoryLoading=false
        },
        getCategoryBackgroundModuleRequest:(state)=>{
          state.backgroundLoading=true;

        },
        getCategoryBackgroundModuleSuccess:(state,{payload})=>{
            state.AllBackgroundCategoryModuleList=payload
            state.categoryLoading=false
        },
        getCatgeoryBackgroundModuleFail:(state,{payload})=>{
          state.categoryLoading=false;
          state.AllBackgroundCategoryModuleList=[]
        },
        getBackgroundCategoryListFail:(state,{payload})=>{
            state.categoryLoading=false
        },
        setTotalBackgroundElement:(state,{payload})=>{
            state.total_background_element=payload
        },
        setTotalBackgroundCategory:(state,{payload})=>{
            state.total_background_category=payload
        },
        setBackgroundSwapCategory:(state,{payload})=>{
            const tempArr = [...state.backgroundCategoryList];
                let tempVal = tempArr[payload?.cat1]
                tempArr[payload?.cat1] = tempArr[payload?.cat2];
                tempArr[payload?.cat2] = tempVal;
                state.backgroundCategoryList = tempArr;
          }
    }
})

export default backgroundSlice.reducer
export const {getCategoryBackgroundModuleRequest,getCategoryBackgroundModuleSuccess,getCatgeoryBackgroundModuleFail,getBackgroundListFail,setTotalBackgroundCategory,setBackgroundSwapCategory,setTotalBackgroundElement,getBackgroundListLoading,getBackgroundListSuccess,getBackgroundCategoryListFail,getBackgroundCategoryListLoading,getBackgroundCategoryListSuccess} = backgroundSlice.actions
