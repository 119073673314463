import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setImageCo_ordinates } from '../../redux/slice/productListSlice';
const ImageCanvas = ({co_ordinates, imageUrl,rectArray,setFieldValue,saveRect,showBtn,value,index,allImage,secondCordinator,secondValue,emptyRect }) => {
    const canvasRef = useRef(null);
    const canvasRef2 = useRef(null);
    const dispatch = useDispatch()
    useEffect(() => {
      const canvas = new fabric.Canvas(canvasRef.current, {
        width: 600,
        height: 600,
      });
  
      fabric.Image.fromURL(imageUrl, (img) => {
        img.set({
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height,
        });
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
      });
      
  
  
        


       emptyRect?.forEach((re,i)=>{
       
           const rect2 = new fabric.Rect({
                left: co_ordinates[i]?.X ? Number(co_ordinates[i]?.X):Math.floor(Math.random() * (200 - 100 + 1)) + 100,
                top: co_ordinates[i]?.Y ? Number(co_ordinates[i]?.Y):Math.floor(Math.random() * (200 - 100 + 1)) + 100,
                fill: 'transparent',
                stroke: 'blue',
                strokeWidth: 2,
                width: co_ordinates[i]?.width ? Number(co_ordinates[i]?.width):120,
                height: co_ordinates[i]?.height ? Number(co_ordinates[i]?.height):200,
                angle: co_ordinates[i]?.rotate ? Number(co_ordinates[i]?.rotate):0,
                resizable: true,
                movable: true,
              });
          
          
                canvas.add(rect2);
                rect2.on('moving', (event) => {
                    if (event.transform.target) {
                        const { left, top, width, height, scaleX, scaleY,angle } = event?.transform.target;
                        
                        if (left < 0 || top < 0 || (left+(width*scaleX))>=600 || (top+(height*scaleY))>=600) {
                            if (left < 0) {   
                                rect2.set({
                                    left: 0,
                                    top:top
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if (top < 0) {
                                rect2.set({
                                    left: left,
                                    top:0
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if ((left + (width*scaleX)) >= 600) {
                                rect2.set({
                                    left: 600-(width*scaleX),
                                    top: top,
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if(top+(height*scaleY)>=600) {
                                rect2.set({
                                    left: left,
                                    top: 600-(height*scaleY),
                                })
                                rect2.canvas.renderAll();
                                return;
                            }
                        }
                        const tempValues=[...co_ordinates];
                        tempValues[i]=`${left},${top},${width*scaleX},${height * scaleY}`;
                        // setFieldValue("files",tempValues)
                        dispatch(setImageCo_ordinates({value:{"X":left,"Y":top,"width":width*scaleX,"height":height * scaleY,"rotate":angle},inner_index:i,index}))
                        // dispatch(setImageCo_ordinates({value:`${left},${top},${width*scaleX},${height * scaleY}`,inner_index:i,index}))
                        // const  cordinate = `${left},${top},${width*scaleX},${height * scaleY}`
                        // saveRect(i,cordinate)
                    }
                });
                rect2.on('scaling', (event) => {

                    if (event.transform.target) {
                        const { left, top, width, height, scaleX, scaleY,angle } = event?.transform.target;
                        if (left < 0 || top < 0 || (left+(width*scaleX))>=600 || (top+(height*scaleY))>=600) {
                            if (left < 0) {   
                                rect2.set({
                                    left: 0,
                                    top:top
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if (top < 0) {
                                rect2.set({
                                    left: left,
                                    top:0
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if ((left + (width*scaleX)) >= 600) {
                                rect2.set({
                                    left: 600-(width*scaleX),
                                    top: top,
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if(top+(height*scaleY)>=600) {
                                rect2.set({
                                    left: left,
                                    top: 600-(height*scaleY),
                                })
                                rect2.canvas.renderAll();
                                return;
                            }
                        }
                        const tempValues=[...co_ordinates];
                        tempValues[i]=`${left},${top},${width*scaleX},${height * scaleY}`;
                        dispatch(setImageCo_ordinates({value:{"X":left,"Y":top,"width":width*scaleX,"height":height * scaleY,"rotate":angle},inner_index:i,index}))
                        // setFieldValue("files",tempValues)
                        // dispatch(setImageCo_ordinates({value:`${left},${top},${width*scaleX},${height * scaleY}`,inner_index:i,index}))

                        // const  cordinate = `${left}-${top},${width*scaleX},${height * scaleY}`
                        // saveRect(i,cordinate)

                    }
                });
                rect2.on('rotating', (event) => {
                    if (event.transform.target) {
                        const { left, top, width, height, scaleX, scaleY,angle } = event.transform.target;
                        if (left < 0 || top < 0 || (left+(width*scaleX))>=600 || (top+(height*scaleY))>=600) {
                            if (left < 0) {   
                                rect2.set({
                                    left: 0,
                                    top:top
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if (top < 0) {
                                rect2.set({
                                    left: left,
                                    top:0
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if ((left + (width*scaleX)) >= 600) {
                                rect2.set({
                                    left: 600-(width*scaleX),
                                    top: top,
                                })
                                rect2.canvas.renderAll();
                                return;
                            } else if(top+(height*scaleY)>=600) {
                                rect2.set({
                                    left: left,
                                    top: 600-(height*scaleY),
                                })
                                rect2.canvas.renderAll();
                                return;
                            }
                        }
                        const tempValues=[...co_ordinates];
                        tempValues[i]=`${left},${top},${width*scaleX},${height * scaleY}`;
                        dispatch(setImageCo_ordinates({value:{"X":left,"Y":top,"width":width*scaleX,"height":height * scaleY,"rotate":angle},inner_index:i,index}))
                    }
                })
        })
      return () => {
        canvas?.dispose();
      };
    }, [imageUrl,emptyRect]);

    // useEffect(()=>{
    //     if(secondCordinator){
    //         createSecondCordinator()
    //     }
    // },[secondCordinator])

    const createSecondCordinator =()=>{
     if(imageUrl){
        const canvas = new fabric.Canvas(canvasRef.current, {
            width: 600,
            height: 600,
          });
      
          fabric.Image.fromURL(imageUrl, (img) => {
            img.set({
              scaleX: canvas.width / img.width,
              scaleY: canvas.height / img.height,
            });
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
          });
          const rect = new fabric.Rect({
            left: value ? Number(value?.split(",")[0]):50,
            top: value ? Number(value?.split(",")[1]):50,
            fill: 'transparent',
            stroke: 'red',
            strokeWidth: 2,
            width: value ? Number(value?.split(",")[2]):120,
            height: value ? Number(value?.split(",")[3]):200,
            resizable: true,
            movable: true,
          });
      
      
            canvas.add(rect);
            rect.on('moving', (event) => {
                if (event.transform.target) {
                    const { left, top, width, height, scaleX, scaleY } = event?.transform.target;
                    if (left < 0 || top < 0 || (left+(width*scaleX))>=600 || (top+(height*scaleY))>=600) {
                        if (left < 0) {   
                            rect.set({
                                left: 0,
                                top:top
                            })
                            rect.canvas.renderAll();
                            return;
                        } else if (top < 0) {
                            rect.set({
                                left: left,
                                top:0
                            })
                            rect.canvas.renderAll();
                            return;
                        } else if ((left + (width*scaleX)) >= 600) {
                            rect.set({
                                left: 600-(width*scaleX),
                                top: top,
                            })
                            rect.canvas.renderAll();
                            return;
                        } else if(top+(height*scaleY)>=600) {
                            rect.set({
                                left: left,
                                top: 600-(height*scaleY),
                            })
                            rect.canvas.renderAll();
                            return;
                        }
                    }
                    const tempValues=[...allImage];
                    tempValues[index].cordinate2=`${left},${top},${width*scaleX},${height * scaleY}`;
                    setFieldValue("files",tempValues)
                }
            });
            rect.on('scaling', (event) => {
                if (event.transform.target) {
                    const { left, top, width, height, scaleX, scaleY } = event?.transform.target;
                    if (left < 0 || top < 0 || (left+(width*scaleX))>=600 || (top+(height*scaleY))>=600) {
                        if (left < 0) {   
                            rect.set({
                                left: 0,
                                top:top
                            })
                            rect.canvas.renderAll();
                            return;
                        } else if (top < 0) {
                            rect.set({
                                left: left,
                                top:0
                            })
                            rect.canvas.renderAll();
                            return;
                        } else if ((left + (width*scaleX)) >= 600) {
                            rect.set({
                                left: 600-(width*scaleX),
                                top: top,
                            })
                            rect.canvas.renderAll();
                            return;
                        } else if(top+(height*scaleY)>=600) {
                            rect.set({
                                left: left,
                                top: 600-(height*scaleY),
                            })
                            rect.canvas.renderAll();
                            return;
                        }
                    }
                    const tempValues=[...allImage];
                    tempValues[index].cordinate2=`${left},${top},${width*scaleX},${height * scaleY}`;
                    setFieldValue("files",tempValues)
                }
            });
          return () => {
            canvas?.dispose();
          };
     }
    }
    return (
        <div>
        {
            showBtn&&<button className='text-base font-medium'>+ Add Imprint</button>
        }
    <canvas ref={canvasRef}></canvas>;
    {/* <canvas ref={canvasRef2} style={{ border: '3px solid black' }}></canvas>; */}
    </div>
        )
  };
  
  export default ImageCanvas;