import React from "react";
import { useDispatch } from "react-redux";
import { setIsLogin } from "../redux/slice/adminSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import LogoutModal from "./LogoutModal";


const Header = ({ children }) => {
  const {image} = useSelector(state=>state.admin)
  const dispatch = useDispatch()
  const navigate = useNavigate()
 const handleLogOut =()=>{
  dispatch(setIsLogin(false))
  localStorage.setItem("is_login",false)
  navigate("/login",{replace:true})
 }
  return (
    <header className="flex justify-end gap-5 items-center self-stretch h-12 bg-white/90 backdrop-blur shadow sticky top-0 z-10">
      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2 py-5">
        {children}
      </div>
      <div className="dropdown flex justify-start relative items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0">
          <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1 rounded-[5px]">
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 pl-4 pr-3 py-2 rounded">
              <img alt="" src={image?image:"https://cdn.vectorstock.com/i/preview-1x/34/96/flat-business-man-user-profile-avatar-in-suit-vector-4333496.jpg"} className="flex justify-center items-center flex-grow-0 flex-shrink-0 w-8 h-8 rounded-[100px] bg-black bg-cover bg-no-repeat bg-center" />
              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1">
                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative space-y-[-2px]">
                  <p className="flex-grow-0 flex-shrink-0 text-[13px] font-semibold text-left text-[#202223]">
                    Super Admin
                  </p>
                </div>
                <svg
                  width={12}
                  height={12}
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0 w-3 h-3 relative"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M3 4.5L6 7.5L9 4.5"
                    stroke="#BABEC3"
                    stroke-width="1.33"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <ul className="dropdown-menu absolute hidden  text-gray-700 pt-1 w-full top-[80%] right-0">
      <li className=""><button className="w-full rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" onClick={handleLogOut} >Logout</button></li>
    </ul>
      </div>
      <div>
        {/* <div
          type="button"
          className="cursor-pointer p-2 border border-black/20 rounded-md hover:bg-blue-800 transition relative flex"
          
        > */}
          

      
        {/* </div> */}
      </div>
    </header>
  );
};


export default Header;


export const NotificationIcon = ({ active }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M14.5837 8.33333C16.167 8.33333 17.5003 7 17.5003 5.41667C17.5003 3.83333 16.167 2.5 14.5837 2.5C13.0003 2.5 11.667 3.83333 11.667 5.41667C11.667 7 13.0003 8.33333 14.5837 8.33333Z"
        fill={active ? "red" : "green"}
      />
      <path
        d="M15.8333 14.167V9.83366C15.4167 9.91699 15 10.0003 14.5833 10.0003H14.1667V15.0003H5.83333V9.16699C5.83333 6.83366 7.66667 5.00033 10 5.00033C10.0833 3.91699 10.5833 3.00033 11.25 2.25033C11 1.91699 10.5 1.66699 10 1.66699C9.08333 1.66699 8.33333 2.41699 8.33333 3.33366V3.58366C5.83333 4.33366 4.16667 6.58366 4.16667 9.16699V14.167L2.5 15.8337V16.667H17.5V15.8337L15.8333 14.167ZM8.33333 17.5003C8.33333 18.417 9.08333 19.167 10 19.167C10.9167 19.167 11.6667 18.417 11.6667 17.5003H8.33333Z"
        fill="black"
        fillOpacity="0.64"
      />
    </svg>
  );
};