import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AppRouter from './router/AppRouter';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <>
    <GoogleOAuthProvider clientId="70551208113-h1qeud2hgs014vq1l68jg03c830m0f7o.apps.googleusercontent.com">
    <Provider store={store}>
    <AppRouter />
    </Provider>
    <Toaster />
    </GoogleOAuthProvider>
    </>
  );
}

export default App;
