import {createSlice} from "@reduxjs/toolkit"

const productSlice=createSlice({
    name:"productList",
    initialState:{
        productList:[],
        productLoading:false,
        colorList:[],
        categorylist:[],
        subCategoryList:[],
        productDetails:"",
        detailsLoading:false,
        total_product_element:0,
        co_ordinates:[],
        emptyRect: [],
        page: 1,
        selectedCategory:""
    },
    reducers: {
        setPage: (state, { payload }) => {
            state.page = payload;
        },
        getProductListLoading:(state,{payload})=>{
            state.productLoading=true
        },
        getProductListSuccess:(state,{payload})=>{
            state.productList=payload
            state.productLoading=false
        },
        getProductListfail:(state,{payload})=>{
            state.productLoading=false
        },
        getProductColorList:(state,{payload})=>{
            state.colorList=payload
        },
        getProductCategoryList:(state,{payload})=>{
            state.categorylist=payload
        },
        getProductSubCategoryList:(state,{payload})=>{
            state.subCategoryList=payload
        },
        getProdouctDetailsLoading:(state,{payload})=>{
            state.detailsLoading=true
        },
        getProdouctDetailsSuccess:(state,{payload})=>{
            state.detailsLoading=false
            state.productDetails=payload
        },
        getProdouctDetailsFail:(state,{payload})=>{
            state.detailsLoading=false
        },
        setTotalProductElement:(state,{payload})=>{
            state.total_product_element=payload
        },
        setImageCo_ordinates:(state,{payload})=>{
            state.co_ordinates[payload?.index][payload.inner_index]=payload.value
        },
        setEmptyRect:(state,{payload})=>{
            state.emptyRect=payload
        },
        createCo_ordinateField:(state,{payload})=>{
            state.co_ordinates=payload
        },
        createMultipleEmptyRect:(state,{payload})=>{
            state.emptyRect[payload]=[...state.emptyRect[payload],{"X":"","Y":"","width":"",'height':"","rotate":""}]
            state.co_ordinates[payload]=[...state.co_ordinates[payload],{"X":"","Y":"","width":"",'height':"","rotate":""}]
           
        },
        removeCo_ordinate:(state,{payload})=>{
           const emptFilter= state.emptyRect[payload?.inner_index].filter((v,i)=>i!==payload?.index)
        //    const cordFilter= state.co_ordinates.filter((v,i)=>i!==payload?.index)
          
           state.emptyRect[payload?.inner_index]=state.emptyRect[payload?.inner_index].filter((v,i)=>i!==payload?.index)
           state.co_ordinates[payload?.inner_index]=state.co_ordinates[payload?.inner_index].filter((v,i)=>i!==payload?.index)
        },
        clearCo_ordinates:(state,{payload})=>{
            state.co_ordinates=[]
            state.emptyRect=[]
        },
        setPreviousCo_odinates:(state,{payload})=>{
            state.co_ordinates=payload
            state.emptyRect=payload
        },
        setselectedCategory: (state, { payload }) => {
            state.selectedCategory = payload;
        }

    }
})

export default productSlice.reducer
export const {getProductListLoading,setPreviousCo_odinates,clearCo_ordinates,removeCo_ordinate,createCo_ordinateField,createMultipleEmptyRect,setEmptyRect,setTotalProductElement,getProductListSuccess,getProductListfail,getProductCategoryList,getProductColorList,getProductSubCategoryList,getProdouctDetailsFail,getProdouctDetailsLoading,getProdouctDetailsSuccess,setImageCo_ordinates,setPage,setselectedCategory} = productSlice.actions