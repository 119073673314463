import { useEffect, useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import {ReactComponent as EditIcon} from "../assets/icons/pencil_icon.svg"
import * as Yup from "yup";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
// import axiosConfig from "../../axios_config";
import Files from "react-files";
import { useFormik } from "formik";
import { WithContext as ReactTags } from "react-tag-input";

import { toast } from "react-hot-toast";
// import { getAlltemplateImage } from "../../services/apiService1";
import { useDispatch } from "react-redux";
import Select from "react-select" 
import { useSelector } from "react-redux";
import axiosConfig from "../../axios_config/axios_instance";
import { getAllTemplateCategoryAllModule, getTemplates } from "../../services/api";


const EditTemplate = ({templateDetails,page}) => {
    const [showModal, setShowModal] = useState(false);
  const [templateImage, setTemplateImage] = useState("");
  const {templateCategory,templateList,AllTemplateCategoryModuleList} = useSelector(state=>state.template)
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([])
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    // price: Yup.string().required("Price is required"),
    category: Yup.string().required("Category is required"),
    no_of_colour: Yup.number().required("No of color is required"),
    sub_category: Yup.number().required("No of color is required"),
  });

  const dispatch = useDispatch();

  const Categoryoptionss = AllTemplateCategoryModuleList?.map(
    ({ template_category_id, template_category_name }) => ({
      value: template_category_id,
      label: template_category_name,
    })
  );

  useEffect(()=>{
    if(templateDetails){
      setFieldValue("name",templateDetails?.template_name)
      setFieldValue("price",templateDetails?.price)
      setFieldValue("category",templateDetails?.template_category_id)
      setFieldValue("no_of_colour",templateDetails?.no_of_colors_used)
      setFieldValue("sub_category",templateDetails?.template_subcategory_id)
      const previousPrintMethod=[]
      if (templateDetails?.dtg){
        previousPrintMethod.push({value:1,label:"DTG"})
      }
      if (templateDetails?.screen_print){
        previousPrintMethod.push({value:2,label:"Screen Print"})
      }
      if (templateDetails?.gravyr_glass){
        previousPrintMethod.push({value:3,label:"Glass"})
      }

      setFieldValue("print_methods",previousPrintMethod)
      setTemplateImage(templateDetails?.template_image)
      if(templateDetails?.tags){

        const previousTags = []
          templateDetails?.tags?.split(",")?.forEach((val) => {
            previousTags.push({ id: val, text: val })
          })
          setTags(previousTags)
      }
    }
  },[templateDetails])

  const initialValues= {
    name: "",
    print_methods:[],
    price: "",
    no_of_colour:"",
    category:""
  }
  const { values, handleChange,handleBlur,setFieldValue, handleSubmit, errors, touched,resetForm } = useFormik({
    validationSchema: validationSchema,
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (templateImage) {
        if(true){

          try {
            setIsLoading(true);
            const formData = new FormData();
            if(typeof templateImage==="object"){

              formData.append("template_image", templateImage);
            }
            formData.append("template_subcategory_id", values.sub_category);
            formData.append("template_name", values.name);
            formData.append("template_category_id", values.category);
            formData.append("price", values.price);
            formData.append("tags", tags.map(({ text }) => text).join(","));
            // formData.append("dtg",values.print_methods?.find(element => element.value === 1)?1:0 );
            // formData.append("screen_print", values.print_methods?.find(element => element.value === 2)?1:0 );
            // formData.append("gravyr_glass", values.print_methods?.find(element => element.value === 3)?1:0 );
            formData.append("template_id",templateDetails?.template_id );
            formData.append("no_of_colors_used", values.no_of_colour);
            formData.append("sort_order", templateDetails?.sort_order);
  
            const { data } = await axiosConfig.put(`/update-template`, formData);
            if (data?.status === "success") {
              toast.success(data?.message || "BackgroundImage updated successfully");
              setShowModal(false);
              setIsLoading(false);
              dispatch(getTemplates(page));
              setTemplateImage("");
              resetForm();
            } else {
              toast.error(data?.message || "Something went wrong");
             // console.log(data);
              setIsLoading(false);
              setTemplateImage("");
            }
          } catch (error) {
            toast.error("Can't proceed with your request right now!!");
            setIsLoading(false);
          }
        }else{
        toast.error("Choose at least one print method");

        }
      } else {
        toast.error("Upload at least one image of Template");
      }
    },
  });

  const handleImageChange = (files) => {
    if (files.length) {
      setTemplateImage(files[0]);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };
  const printOptions=[
    {value:"",label:"Select"},
    {value:1,label:"DTG"},
    {value:2,label:"Screen Print"},
    {value:3,label:"Glass"},
]

const suggestions = [];
const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];
const handleAddition = (tag) => {
  if (tags.length === 5) {
    toast.error("You can not add more than 5 search tags");
    return;
  }
  setTags([...tags, tag]);
};

const handleDelete = (i) => {
  setTags(tags.filter((tag, index) => index !== i));
};

const handlePrice=(event)=>{
  
  const inputValue = event.target.value;
  // Validate if the input is a valid number
  if (/^-?\d*$/.test(inputValue)) {
    setFieldValue("price",inputValue.replace(/[^0-9]/g, ''));
  }
};
const handleColour=(event)=>{
  
  const inputValue = event.target.value;
  // Validate if the input is a valid number
  if (/^-?\d*$/.test(inputValue)) {
    setFieldValue("no_of_colour",inputValue.replace(/[^0-9]/g, ''));
  }
};

  return (
    
        <>
     <EditIcon className="w-4 h-4" onClick={openModal} />
      {showModal ? (
        <div className="fixed bottom-1 left-20 w-full h-full flex items-center justify-center z-50">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row justify-between w-full bg-[#454546] p-3 items-center rounded">
              <div>
                <h2 className="text-white text-lg font-medium">
                  Edit Template
                </h2>
              </div>
              <div className="items-center">
                <button className="text-white " onClick={closeModal}>
                  <RxCross1 size={20} />
                </button>
              </div>
            </div>
            <div className="flex flex-row justify-center  items-start bg-white p-4">
              <div className="flex flex-col w-1/2 p-4">
                <div className="">
                  <div className="w-[280px] h-64 border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex flex-col items-center justify-center">
                    <Files
                      onChange={(file) => handleImageChange(file)}
                      accepts={[ ".svg"]}
                      maxFileSize={5000000}
                      minFileSize={0}
                      clickable
                    >
                      {templateImage ? (
                        <img
                        alt=""
                          src={
                            typeof templateImage === "object"
                              ? templateImage?.preview?.url
                              : templateImage
                          }
                          className="h-full w-[280px] object-cover"
                        />
                      ) : (
                        <label className="flex flex-col flex-shrink-0 flex-grow-0 justify-center items-center">
                          <BsFillCloudArrowUpFill
                            size={100}
                            className="text-black"
                          />
                          <span className="mt-2 text-base bg-[#454546] hover-bg-slate-600 rounded-lg w-full m-3 p-2 text-center text-white leading-normal cursor-pointer">
                            Upload Image
                          </span>
                        </label>
                      )}
                    </Files>
                    {
                     !templateImage&&
                    <span className="font-semibold">
                      (Only PNG Supported)
                    </span>
                    }
                  </div>
                
                </div>
              </div>
              <div className="flex gap-2 flex-col w-96 justify-start mt-4">
                <div className="">
                <label  className="block text-base text-start after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Name
                        </label>
                  <input
                    name="name"
                    type="text"
                    value={values.name}
                    style={errors.name&&touched.name?{border:"1px solid red"}:{}}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Template name"
                  />
                 
                </div>
                {/* <div className="">
                <label  className="block text-base text-start  text-gray-700 font-bold"
                    htmlFor="name">
                          Price
                        </label>
                  <input
                    name="price"
                    type="tel"
                    value={values.price}
                    onChange={handlePrice}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Template price"
                  />
                 
                </div> */}
                {/* category */}
                <div>

                <label  className="block text-start text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Category
                        </label>
                        <select
                        name="category"
                        onChange={(e)=>{
                          setFieldValue("category","")
                          handleChange(e)
                        }}
                        onBlur={() => handleBlur("category")}
                        value={values.category}
                        placeholder="select category"
                        style={
                          errors.category && touched.category
                            ? { border: "1px solid red" }
                            : {}
                        }
                        className=" text-black border capitalize text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400  "
                        >
                        <option>Select</option>
                        {AllTemplateCategoryModuleList?.map((option,i) => (
                          <option key={i} className="text-black capitalize  bg-gray-200  " value={option.template_category_id}>
                           {option?.template_category_name}
                          </option>
                        ))}
                      </select>
                </div>
                      {/*sub- category */}
           {values.category&&     <div>

<label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
    htmlFor="name">
       Sub   Category
        </label>
        <select
        name="sub_category"
        onChange={(e)=>{
          handleChange(e)
        }}
        onBlur={() => handleBlur("sub_category")}
        value={values.sub_category}
        placeholder="select Sub Category"
        style={
          errors.sub_category && touched.sub_category
            ? { border: "1px solid red" }
            : {}
        }
        className=" text-black border capitalize text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400  "
        >
        <option value={""}>Select</option>
        {AllTemplateCategoryModuleList?.find((val)=>val?.template_category_id==values.category)?.template_sub_category_list?.map((option,i) => (
          <option key={i} className="text-black capitalize  bg-gray-200  " value={option.template_sub_category_id}>
           {option?.template_sub_category_name}
          </option>
        ))}
      </select>
</div>}
                <div className="">
                <label  className="block text-start text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          No of colour used
                        </label>
                  <input
                    name="no_of_colour"
                    type="tel"
                    style={errors.no_of_colour&&touched.no_of_colour?{border:"1px solid red"}:{}}
                    value={values.no_of_colour}
                    onChange={handleColour}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Color used"
                  />
                  
                </div>
                {/* <div className="w-full">
                    <div className="">
                    <label  className="block text-start text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Print Method
                        </label>

                        <Select
                          name="Print "
                          options={printOptions}
                          styles={{ cursor: "pointer",textAlign:"start" }}
                          isSearchable={false}
                          isMulti
                          className={
                            errors.skill && touched.skill
                              ? "error-border"
                              : "custom-react-select"
                          }
                          // styles={customStyles}
                          onChange={(selectedOption) =>
                            setFieldValue("print_methods", selectedOption)
                          }
                        //   onBlur={handleBlur}
                        value={values.print_methods}
                        />
                      </div>
                </div> */}
                <div>
                {/* tag design */}
                <label style={{textAlign:"start"}}  className="block text-base text-start text-gray-700 font-bold"
                    htmlFor="name">
                          Tags
                        </label>
                <div className="h-[100px] ">

                <ReactTags
                           inline={false}
                           tags={tags}
                           suggestions={suggestions}
                           delimiters={delimiters}
                           handleDelete={handleDelete}
                           handleAddition={handleAddition}
                           inputFieldPosition="inline"
                           autocomplete
                           maxLength={15}
                          classNames={{
                            tags: "block h-full w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:b dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none",
                            tagInput: "w-full h-full focus:outline-none",
                            tagInputField:
                              "w-full focous focus:outline-none text-black text-sm rounded-lg p-2.5 dark: dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-none",
                            selected: "flex flex-wrap",
                            tag: "border border-gray-300 bg-gray-300 text-gray-900 text-sm rounded-md p-2.5 m-1",
                            remove: "ml-2 cursor-pointer",
                            suggestions:
                              "absolute bg-white border rounded-lg shadow w-200",
                            activeSuggestion:
                              "bg-blue-500 text-white cursor-pointer",
                          }}
                        />
                </div>
                  </div>

                {/* assign print method */}

               

                <div className="flex justify-end mt-6">
                  <button
                  disabled={isLoading}
                    className="bg-blue-500 p-2 w-[34%] hover-bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
       </>
    
  )
}

export default EditTemplate