import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Template from '../../components/Template';
import * as routesKey from "../../constants/routes";
import { FaRegEdit } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoIosAdd } from "react-icons/io";
import toast from 'react-hot-toast';
const ManageColorCategory = () => {




  const [categories, setCategories] = useState([
    { id: 1, name: 'Category 1' },
    { id: 2, name: 'Category 2' },
    { id: 3, name: 'Category 3' },
  ]);


 

  return (
    <Template>
      <div className='bg-gray-200 flex justify-start w-full'>
      <div className='flex justify-start gap-2 p-2'>
      <p>Home</p>
      /
      <Link to={routesKey.COLOR}>
        <p>Color</p>
      </Link>
      /
      <p>Category</p>
    </div>
      </div>

      <div className='flex flex-col flex-grow-0 flex-shrink-0 justify-center items-stretch mx-11 p-2'>
        <div className='flex flex-1 justify-start gap-6 flex-grow-0 flex-shrink-0 items-center p-4 pt-4'>
          <div className='flex flex-row flex-shrink-0 flex-grow-0'>
            <h2 className='text-2xl font-sans font-bold'>Manage Category</h2>
          </div>
        </div>
        <div className='bg-gray-200'>
          <AddFontCategory />
          <div className='p-2'>
            {/* Category List */}
            <DragDropContext >
            <Droppable droppableId="categoryList">
              {(provided) => (
                <table
                  className='min-w-full bg-white border border-gray-300'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <thead>
                    <tr className='bg-gray-100'>
                      <th className='border border-gray-300 px-4 py-2'>Category Name</th>
                      <th className='border border-gray-300 px-4 py-2'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((category, index) => (
                      <Draggable key={category.id} draggableId={`category-${category.id}`} index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td className='border text-center border-gray-300 px-4 py-2'>{category.name}</td>
                            <td className='border text-center border-gray-300 px-4 py-2'>
                            <button>
                              <EditFontCategory />
                              </button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  </tbody>
                  {provided.placeholder}
                </table>
              )}
              
            </Droppable>
          </DragDropContext>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default ManageColorCategory;


 export const AddFontCategory=()=>{

  const [showmodal, setShowModal] = useState(false);
  const [loading, setLoading]=useState(false)
  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const validationSchema = Yup.object().shape({
    categoryname: Yup.string().required("categoryname is required"),
    enableanddisablestatus:Yup.string().required("status is required"),
});


  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: {
   
      categoryname:"",
      enableanddisablestatus:"",
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
       try {
       {/* setLoading(true)
        const formdata= new FormData()
        formdata.append("images", values?.image)
  
        const {data}= await axiosConfig.post("/save-gallery-image", formdata);
        if(data?.status === "success"){
          toast.success(data?.message || "Gallery Added successfully")
          setShowModal(false)
          resetForm()
        dispatch(getGallery()) */}
   
        
       } catch (error) {
          toast.error("something Went wrong")
       }finally{
        setLoading(false)
       }
    },
  });

  const EnableandDisableStatuschanges = [
    { label: 'enable', value: 0 },
    { label: 'disable', value: 1 },
 
   
  ];
  return (
    <div>
    <div   onClick={openModal} className='flex justify-start items-center p-1 flex-grow-0 flex-shrink-0 cursor-pointer bg-gray-400'>
    <button className='text-[18px] font-normal'>
      <div className='flex items-center'>
        <IoIosAdd className='mr-1' /> Category
      </div>
    </button>
  </div>
  {showmodal ? (
    <div className="">
      <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative  rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Add New Category
              </h3>
              <button
                onClick={closeModal}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Category Name
                  </label>
                  <input
                    type="text"
                    name="categoryname"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Font Name"
                    required=""
                  />
                </div>
                
                <div className="col-span-2 sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Category
                </label>
                <select
                name="categorylist"
                onChange={handleChange}
                onBlur={() => handleBlur("enableanddisablestatus")}
                value={values.enableanddisablestatus}
                placeholder="select category"
                style={
                  errors.enableanddisablestatus && touched.enableanddisablestatus
                    ? { border: "1px solid red" }
                    : {}
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                <option>Select</option>
                {EnableandDisableStatuschanges?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              </div>
             
              </div>
              <button
                type="submit"
                className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null}
    </div>
  )
}


export const EditFontCategory=()=>{
  const [showmodal, setShowModal] = useState(false);
  const [loading, setLoading]=useState(false)
  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const validationSchema = Yup.object().shape({
    categoryname: Yup.string().required("categoryname is required"),
    enableanddisablestatus:Yup.string().required("status is required"),
});


  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: {
   
      categoryname:"",
      enableanddisablestatus:"",
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
       try {
       {/* setLoading(true)
        const formdata= new FormData()
        formdata.append("images", values?.image)
  
        const {data}= await axiosConfig.post("/save-gallery-image", formdata);
        if(data?.status === "success"){
          toast.success(data?.message || "Gallery Added successfully")
          setShowModal(false)
          resetForm()
        dispatch(getGallery()) */}
   
        
       } catch (error) {
          toast.error("something Went wrong")
       }finally{
        setLoading(false)
       }
    },
  });

  const EnableandDisableStatuschanges = [
    { label: 'enable', value: 0 },
    { label: 'disable', value: 1 },
 
   
  ];
  return (
    <div>
    <div   onClick={openModal} className=''>
    <button>
    <FaRegEdit size={20} />
   </button>
  </div>
  {showmodal ? (
    <div className="">
      <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative  rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Edit  Category
              </h3>
              <button
                onClick={closeModal}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                <label
                htmlFor="name"
                className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
              >
                Category Name
              </label>
                  <input
                    type="text"
                    name="categoryname"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Font Name"
                    required=""
                  />
                </div>
         
                <div className="col-span-2 sm:col-span-1">
                <label className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">
                  Status
                </label>
                <select
                name="categorylist"
                onChange={handleChange}
                onBlur={() => handleBlur("enableanddisablestatus")}
                value={values.enableanddisablestatus}
                placeholder="select category"
                style={
                  errors.enableanddisablestatus && touched.enableanddisablestatus
                    ? { border: "1px solid red" }
                    : {}
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option>Select</option>
                {EnableandDisableStatuschanges?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              </div>
              </div>
              <button
                type="submit"
                className="text-white  inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null}
    </div>
  )
}