import React, { useState,useEffect } from 'react'
import Template from '../../components/Template'
import {ReactComponent as Delete} from "../assets/icons/trashiconsmall.svg"
import AddBackground from './AddBackground'
import { useSelector } from 'react-redux'
import EditBackground from './EditBackground'
import { Link } from 'react-router-dom'
import * as routesKey from "../../constants/routes"
import { useDispatch } from 'react-redux'
import { getBackgroundCategory, getBackgrounds } from '../../services/api'
import DeleteModal from '../../components/common/DeleteModal'
import axiosConfig from '../../axios_config/axios_instance'
import toast from 'react-hot-toast'
import EnableDisableModal from '../../components/common/EnableDisableModal'
import Pagination from '../../components/pagination'

const Background = () => {
  const dispatch = useDispatch()
    const {backgroundList,total_background_element,backgroundCategoryList} = useSelector(state=>state.background)
    const [editId, seteditId] = useState("")
    const [page, setPage] = useState(1)
   const [loading, setLoading] = useState(false)

    useEffect(()=>{
        dispatch(getBackgrounds(page))
    },[dispatch,page])
    useEffect(()=>{
        dispatch(getBackgroundCategory())
    },[dispatch])


    const handleDelete=async (id,setshowModal)=>{
         try {
           setLoading(true)
           const {data} = await axiosConfig.delete(`/status-background-change?background_id=${id}`)
           if(data?.status==="success"){
            dispatch(getBackgrounds(page))
            setshowModal(false)
            toast.success("Updated successfully")
           }else{
            toast.error("Something went wrong !!")
           }

           setLoading(false)
         } catch (error) {
          toast.error("Something went wrong !!")
          setLoading(false)
         }
    }
  return (
    <Template>
         <div className="flex  justify-between flex-grow-0 flex-shrink-0 items-center  py-4">
         <div className="flex  w-full  justify-between flex-grow-0 flex-shrink-0 items-center  pt-0">
          <div className="flex flex-row flex-shrink-0 flex-grow-0">
            <h2 className="text-xl font-sans font-bold">Background</h2>
          </div>
          <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
            <Link to={routesKey.BACKGROUND_CATEGORY} className='text-lg'>
            Manage Category
            </Link>


          
          <AddBackground/>

          </div>
        </div>
        </div>
        
        {/* <div className='text-black font-medium text-[30px]'>Backgrounds</div> */}
        <div className="flex flex-col border-2 p-4 bg-ed-600  w-full rounded-md  ">
               {backgroundList?.length ?    <table >
                    <thead>
          <tr className="">
         
            <th className="border-b text-center  whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 ">
              Preview
            </th>
            
            
            <th className="border-b whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3  ">
            Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1  py-3 ">
            Price
            </th>
            
            <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
            Category
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
            Actions
            </th>
            
           
          </tr>
                    </thead>
                      <tbody className="">
                        {backgroundList?.map((background,i) => (
                           <tr   key={background?.background_id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                           
                           
                          
                            <td className="   whitespace-nowrap py-2  leading-tight  flex flex-col  items-center h-full  ">
                              <img alt='' src={background?.background_image} className='h-12 w-12 rounded-md'/>
                            </td>
                           <td className="whitespace-nowrap text-primary font-normal  text-[14px] leading-tight capitalize px-2 text-[#444444] text-center">{background?.background_name}</td>
                           
                            <td className="  text-primary font-normal  text-[14px] whitespace-nowrap  leading-tight  capitalize text-[#444444]  text-center">{background?.price}</td>
                           <td className=" text-primary font-normal  text-[14px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{background?.category_name}</td>
                           <td className="text-primary  font-normal  text-[14px] leading-tight capitalize text-[#444444] mx-auto my-auto items-center text-center">
                           <div className="group/edit invisible  group-hover/item:visible flex justify-center items-center gap-1">
                            {
                              background?.is_active&&
                           <button
                                    className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all"
                                  >     
          <EditBackground  backgroundDetails={background} page={page}/>
                                  </button>
                            }
                                  <div >
                             <EnableDisableModal   onConfirm={handleDelete} id={background?.background_id} isLoading={loading} desc={"Background"} is_active={background?.is_active} />

                                {/* <DeleteModal  heading={"Background"} onDelete={handleDelete} id={background?.background_id} isLoading={loading} desc={"Are you sure want to delete background ?"} /> */}
                                    </div>
                            {/* <Delete className='p-0  shadow-md'/> */}
                           </div>
                           </td>
                           </tr>
                        ))}
                      </tbody>
                    </table>: <div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Background available
                </span>
              </div>}
                  </div>
                  <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>
        <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={total_background_element} 
        pageSize={10}
                  onPageChange={(newPage) => { 
                    setPage(newPage)
                      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                  }
                   }
      />  
      </div>
        
    </Template>
  )
}

export default Background