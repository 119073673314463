import React, { useState } from 'react'


const EnableDisableModal = ({onConfirm,id,isLoading,is_active,desc,dontSHow}) => {
    const [showModal, setShowModal] = useState(false);
 
   
   
  console.log("is_active",is_active)
  
    
  
      const closeModal = () => {
        setShowModal(false);
      };
    
  
      const openModal = () => {
        setShowModal(true);
      }
      const handleClick=()=>{
        onConfirm(id,setShowModal)
      }
  return (
    <>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
    }}
    className={`flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3   ${dontSHow ? "":"group/edit invisible group-hover/item:visible"}`}
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 "
              >
                 {/* flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all */}
                  <div className='p-1 rounded-md bg-slate-100  cursor-pointer' >
                    {
                        is_active?
                        <svg width="25" height="25" viewBox="0 0 0.5 0.5" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M.113.075a.04.04 0 0 0-.038.038v.275a.04.04 0 0 0 .038.037h.275A.04.04 0 0 0 .426.387V.112A.04.04 0 0 0 .388.075zm.23.143A.025.025 0 0 0 .308.183L.225.265.193.232a.025.025 0 0 0-.035.035l.05.05a.025.025 0 0 0 .035 0z" fill="green"/></svg> :
                <svg width="20" height="20" fill='#000' viewBox="0 0 0.6 0.6" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path d="M.335.3.493.143A.025.025 0 1 0 .458.108L.3.265.143.107a.025.025 0 0 0-.035.035l.158.157-.158.157a.025.025 0 0 0 0 .035.025.025 0 0 0 .035 0L.3.333l.157.158a.025.025 0 0 0 .035 0 .025.025 0 0 0 0-.035Z" /></svg>
                        

                    }
                  </div>
    </div>
  </div>
     {showModal ? (
       <>
       <div className="">
       <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
         <div className="relative p-4 w-full max-w-md max-h-full">
           {/* Modal content */}
           <div className="relative  rounded-lg shadow bg-gray-200">
             {/* Modal header */}
          
             {/* Modal body */}
             <div className="p-4 md:p-5">
               <div className="">

                 <div className="col-span-2">
                 <div className="flex flex-col justify-center items-center ">
              <h2 className="text-[16px] font-semibold">
                Do you want to {is_active?"De-active":"Active"} {desc} ?
              </h2>
         
   
                 </div>
                 
                 </div>
          
           
               </div>
               <div className="flex justify-center gap-4 mt-8 items-center">
             
               <button
                 onClick={closeModal}
                 disabled={isLoading}
                 className="text-white flex  items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
               >
                 Cancel
               </button>
               <button
                 type="submit"
                 disabled={isLoading}
                 onClick={handleClick}
                 className="text-white flex  items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
               >
                 {is_active?"Disable":"Enable"}
               </button>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
       </>
     ) : null}
   </>
  )
}

export default EnableDisableModal