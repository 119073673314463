import React, { useEffect } from 'react'
import Template from '../../components/Template'
import { Link, useNavigate } from 'react-router-dom'
import * as routesKey from "../../constants/routes"
import { IoIosAdd } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { resetData, setConfiguratorCurrentPage } from '../../redux/slice/configuratorSlice'
import { getConfiguratorLists } from '../../services/api'
import { useSelector } from 'react-redux'
import { PencilIcon } from '@heroicons/react/24/outline'
import Pagination from '../../components/pagination'
const ManageConfigurator = () => {
  const {configuratorList,isLoading,totalConfigurator,currentConfiguratorPage}=useSelector((state)=>state.configurator)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getConfiguratorLists(0))
  }, [])
  //map((item)=>item?.variants?.flat()).flat()
  return (
      <Template>
           <div className="flex  justify-between flex-grow-0 flex-shrink-0 items-center  py-4">
    <div className="flex  w-full  justify-between flex-grow-0 flex-shrink-0 items-center  pt-0">
     <div className="flex flex-row flex-shrink-0 flex-grow-0">
       <h2 className="text-xl font-sans font-bold">Manage Configurator</h2>
     </div>
     <div className="flex flex-row flex-shrink-0 flex-grow-0 gap-4">
       
       <button
              onClick={() => {
                dispatch(resetData())
                navigate(routesKey.ADDCONFIGURATOR)
              }}
        className="flex justify-start items-center flex-grow-0 flex-shrink-0"
      >
        <button className="text-lg">
          <div className="flex items-center">
            <IoIosAdd /> Add Configurator
          </div>
        </button>
      </button>

     
     {/* <AddTemplate/> */}

     </div>
   </div>
      </div>
      <div className="flex flex-col border-2 p-4 bg-ed-600  w-full rounded-md  ">
          {configuratorList?.length ?    <table >
               <thead>
     <tr className=" ">
       <th className="border-b whitespace-nowrap  border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3  ">
       Name
       </th>
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Total Parts
       </th>
       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Total Sub Parts
       </th>

       <th className="border-b whitespace-nowrap border-black/10 text-[17px]  text-[#000] font-bold px-1 py-3 text-center ">
       Actions
       </th>
     </tr>
               </thead>
                 <tbody className="">
                   {configuratorList?.map((product,i) => (
                      <tr   key={product?.id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                       <td className="   whitespace-nowrap py-2  leading-tight  flex flex-col  items-center h-full  ">
                        {product?.name}
                       </td>
                      <td className="whitespace-nowrap text-primary font-normal  text-[14px] leading-tight capitalize px-2 text-[#444444] text-center">{product?.parts?.length}</td>
                      
                      <td className=" text-primary font-normal  text-[14px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{product?.parts?.map((item)=>item?.variants?.flat())?.flat()?.length}</td>
                      
                       <td className="text-primary  font-normal  text-[14px] leading-tight capitalize text-[#444444] mx-auto my-auto items-center text-center">
                         <Link to={`/edit-configurator/${product?.id}`}>
                         <PencilIcon className='h-4 w-4 cursor-pointer' />
                         </Link>
                      </td>
                      </tr>
                   ))}
                 </tbody>
               </table>: <div className="flex justify-center items-center h-[20vh] w-full">
           <span className="text-center w-full text-xl text-[#7b8794]">
             No Configurator available
           </span>
         </div>}
             </div>
         <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>
        <Pagination
        className="pagination-bar"
        currentPage={currentConfiguratorPage}
        totalCount={totalConfigurator} 
        pageSize={10}
          onPageChange={(newPage) => { 
                    dispatch(setConfiguratorCurrentPage(newPage))
                      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                  }
                   }
      />  
      </div>
    </Template>
  )
}

export default ManageConfigurator