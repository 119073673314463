import React, { useState,useEffect } from 'react'
import Template from '../../components/Template'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Files from "react-files";
import { ReactComponent as UploadIcon } from "../assets/icons/uploadicon.svg";
import Select from "react-select" 
import toast from 'react-hot-toast';
import Fabriccanvas from './FabricCanvas';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getConfiguratorLists, getProdouctColors, getProdouctSubCategory, getProductDetails, getProducts, getTemplateCategory } from '../../services/api';
import axiosConfig from '../../axios_config/axios_instance';
import { clearCo_ordinates, createCo_ordinateField, setEmptyRect, setImageCo_ordinates, setPreviousCo_odinates } from '../../redux/slice/productListSlice';
import { WithContext as ReactTags } from "react-tag-input";


const EditProduct = () => {
    const navigation = useNavigate()
    const params = useParams()
    const dispatch= useDispatch()
    const product_id=params?.product_id
    const [loading, setLoading] = useState(false)
    const [tags, setTags] = useState([])
  const {subCategoryList,colorList,emptyRect,productDetails,co_ordinates} = useSelector(state=>state.productList)
  const {configuratorList}=useSelector((state)=>state.configurator)
  const {templateCategory} = useSelector(state=>state.template)
  useEffect(()=>{
    dispatch(getProdouctColors())
    dispatch(getProdouctSubCategory())
    dispatch(getTemplateCategory(0,1000))
  },[dispatch])
  useEffect(() => {
    dispatch(getConfiguratorLists(0,1000))
  }, [])
     useEffect(()=>{
      if(product_id){
    resetForm()
    setFieldValue("files",[{image:"",cordinate:"",cordinate2:"",type:"",image_id:"",layer:"",mask:"",thumb:""}])
    dispatch(clearCo_ordinates())
        dispatch(getProductDetails(product_id)) 
      }
     },[product_id])
       
    const colors=[
        {label:"Blue",value:"BLUE"},
        {label:"Red",value:"RED"},
        {label:"Green",value:"GREEN"},
        {label:"Black",value:"BLACK"},
    ]
    

    const initialValues={
      name:"",
      category:"",
      color:[],
      subCategory:"",
      print_type:"normal",
      files:[{image:"",cordinate:"",cordinate2:"",type:"",image_id:"",mask:"",layer:""}],
      // cordinate: "",
      product_type: productDetails?.product_type,
      configuratorType: Number(productDetails?.configurator_id),
      template_category_id:productDetails?.template_category_id
     }
     const [errosImage, seterrosImage] = useState([])
     const productValidationSchema=Yup.object({
      name: Yup.string()
        .trim()
        .required("Please enter name"),
        // image: Yup.string()
        // .required("required"),
      // category: Yup.string()
      //   .required("required"),
        subCategory: Yup.string()
         .required("required"),
         template_category_id:Yup.string().required()
    //     color: Yup.array()
    // .of(Yup.object().shape({
    //   label: Yup.string().required("Tag label is required"),
    //   value: Yup.string().required("Tag value is required")
    // }))
    // .required("Please add at least one tag")
    });
    const {
      values,
      handleBlur,
      resetForm,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
    } = useFormik({
      initialValues,
      validationSchema: productValidationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit: async (values, action) => {
        try {
          
          if(values.files?.length>1&&values.color.length){
            // let tempErrors=[...errosImage];
            let allValidated=true;
            const checkAllcordinate=values.files?.filter(v=>typeof v.image==="object")
            checkAllcordinate?.forEach((val,i)=>{
              // if(!val.cordinate&&!val.cordinate2){
              if((!val.cordinate||!val.cordinate2)&&!val.type){
                allValidated=false;
              }
            })
             //validation for coordinates
             if(co_ordinates?.some(element => element?.some(val=>val?.X===""))){
              return toast.error("Add coordinate of each image")
            }
            if(true){
              const formdata = new FormData()
              formdata.append("product_name",values.name)
              let cordinates=[];
              let type=[];
              let exisitng_cordinates=[];
              let exisitng_type=[];
              let exisitng_image_id=[];

              values?.files?.filter(val=>val.image!=="")?.forEach((v,i)=>{
                const newImage=(typeof v?.image==="object"||typeof v?.layer==="object"||typeof v?.mask==="object" ||typeof v?.thumb==="object")&&!v?.image_id
                if (newImage) {
                  if (typeof v.thumb === "object") {
                    formdata.append("thumbs",v?.thumb)
                  } else {
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                    formdata.append("thumbs",emptyFile)
                  }
                  if (typeof v.layer === "object") {
                    formdata.append("layers",v?.layer)
                  } else {
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                    formdata.append("layers",emptyFile)
                  }
                  if (typeof v.mask === "object") {
                    formdata.append("masks",v?.mask)
                  } else {
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                    formdata.append("masks",emptyFile)
                  }
                  if (typeof v.image === "object") {
                    formdata.append("images",v?.image)
                  } else {
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                    formdata.append("images",emptyFile)
                  }
                  cordinates.push(JSON.stringify(co_ordinates[i]))
                  type.push(v.type ? v.type : `Side ${i + 1}`)
                  if (v.image_id) {
                    exisitng_cordinates.push(JSON.stringify(co_ordinates[i]))
                    exisitng_type.push(v.type?v.type:`Side ${i+1}`)
                    exisitng_image_id.push(v?.image_id)
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                    formdata.append("existing_images",typeof v?.image==="object"?v?.image:emptyFile)
                    formdata.append("existing_layers",typeof v?.layer==="object"?v?.layer:emptyFile)
                    formdata.append("existing_thumb",typeof v?.thumb==="object"?v?.thumb:emptyFile)
                    formdata.append("existing_masks",typeof v?.mask==="object"?v?.mask:emptyFile)
                  }
                } else {
                  exisitng_image_id.push(v?.image_id)
                  exisitng_cordinates.push(JSON.stringify(co_ordinates[i]))
                  exisitng_type.push(v.type ? v.type : `Side ${i + 1}`)
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                  console.log("v?.imageXXXXXXXXXXXXXXXXX",typeof v?.image)
                  formdata.append(`existing_images`, typeof v.image==="object"?v?.image:emptyFile)
                  formdata.append("existing_layers",typeof v.layer==="object"?v?.layer:emptyFile)
                  formdata.append("existing_thumb", typeof v.thumb === "object" ? v?.thumb : emptyFile);
                  formdata.append("existing_masks",typeof v.mask==="object"?v?.mask:emptyFile)
                }
                // if(typeof v.layer==="object"){
                //   formdata.append(`layers`, v.layer)
                //   if (v?.image_id) { 
                //     formdata.append(`existing_layers`, v.layer)
                //   }
                // }else{
                //   const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                //    if (v?.image_id) { 
                //     formdata.append(`existing_layers`, emptyFile)
                //    } else {
                //      formdata.append(`layers`, emptyFile)
                //     }
                // }
                // if(typeof v.thumb==="object"){
                //   formdata.append(`thumbs`, v.thumb)
                //   if (v?.image_id) {
                //   formdata.append(`existing_thumb`, v.thumb)
                //   }
                // } else {
                //   const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                //   if (v?.image_id) {
                //     formdata.append(`existing_thumb`, emptyFile)
                //   } else {
                //     formdata.append(`thumbs`, emptyFile)
                //   }
                // }
                // if(typeof v.mask==="object"){
                //   formdata.append(`masks`, v.mask)
                //   if (v?.image_id) {
                //     formdata.append(`existing_masks`, v.mask)
                //   }
                // }else{
                //   const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                //   if (v?.image_id) {
                //     formdata.append(`existing_masks`, emptyFile)
                //   } else {
                //     formdata.append(`masks`, emptyFile)    
                //   }   
                // }
              })
              formdata.append("product_type",values.product_type)
              if (values.product_type === "configurator") {
                formdata.append("configurator_id",values.configuratorType)
              }
                formdata.append("colour_list",values?.color?.map((v)=>v.value))
                formdata.append("product_id",productDetails?.product_id)
                formdata.append("existing_image_Id",exisitng_image_id?.join(","))
                formdata.append("existing_type",exisitng_type?.join(","))
                formdata.append("existing_co_ordinates",exisitng_cordinates)
                formdata.append("template_category_id",values?.template_category_id)
              formdata.append("product_sub_category_id",values?.subCategory)
              if(type?.length){
                formdata.append("type",type?.join(","))
              }
              // formdata.append("co_ordinates",values?.files?.cordinate)
              if(cordinates?.length){
                formdata.append("co_ordinates",cordinates)
              }
              formdata.append("tags", tags.map(({ text }) => text).join(","));
              formdata.append("print_area_type ",values.print_type)
              setLoading(true)
              const {data} = await axiosConfig.put(`/edit-product`,formdata)
              if(data?.status==="success"){
                dispatch(getProducts(1))
                dispatch(clearCo_ordinates())
                resetForm()
                toast.success("Product added successfully")
                navigation(-1)
              }else{
                toast.error(data?.message)
              }
              setLoading(false)
            }
          }else{
            if(!(values.files.length>1)){
  
              toast.error("Add at least one image")
            }else{
              toast.error("Add at least one color")
  
            }
  
          }
        } catch (error) {
          setLoading(false)
          console.log("Errorrrrrrrrrrrrr",error)
          toast.error("Something went wrong")
        }
      }
    })
    useEffect(()=>{
      if(Number(product_id)===Number(productDetails?.product_id)){
        //when reloaded
        setFieldValue("name",productDetails?.product_name)
        setFieldValue("product_type", productDetails?.product_type)
        setFieldValue("configuratorType",productDetails?.configurator_id?Number(productDetails?.configurator_id):"")
        setFieldValue("template_category_id",productDetails?.template_category_id?Number(productDetails?.template_category_id):"")
        setFieldValue("color",productDetails?.product_colour_list?.map((v)=>(
          {label:v?.colour_name,value:v?.colour_id}
        )))
        const previousImage=[]
        // productDetails?.image_list?.map((v)=>(
        //   // {image:v?.product_image,cordinate:"",cordinate2:"",type:JSON.parse(v?.type?.length)?JSON.parse(v?.type[0]):"",image_id:v?.image_id}
        //   // previousImage.push[{image:v?.product_image,cordinate:"",cordinate2:"",type:"",image_id:v?.image_id}]
        // ))
        // setFieldValue("files",[...values.files,...previousImage])

        setFieldValue("files",[...productDetails?.image_list?.map((v,i)=>{
         
        //  data?.map((val,index)=>{

        //   prviousCoordinates.push()
        //  })
          // dispatch(setImageCo_ordinates([]))
          
          // const co_oridinates=v?.co_ordinates.split("-") //19.27.89.00-655.88.99.00 //[19,27,89,00] //19,27,89,00 //-
          // let co_ordinate1=co_oridinates[0].split(".").join(",")
          // let co_ordinate2=co_oridinates[1]?co_oridinates[1].split(".").join(","):"";

        return  {image:v?.product_image,cordinate:"",cordinate2:"",type:(v.type),image_id:v?.image_id,layer:v?.layer,mask:v.mask,thumb:v?.thumb}}
          ),...values.files])
           let previous_coordinate=[];
          productDetails?.image_list?.forEach((val,i)=>{
            
         const data=JSON.parse(val?.co_ordinates)
        //  const replacedVal = data?.map(string =>string.replace(/,/g, '-').replace(/\*/g, ','));
         previous_coordinate=[...previous_coordinate,data]
        })
        dispatch(setPreviousCo_odinates(previous_coordinate))
        // dispatch(setPreviousCo_odinates(previous_coordinate))
        setFieldValue("subCategory",productDetails?.product_sub_category_id)
        if(productDetails?.print_area_type){

          setFieldValue("print_type",productDetails?.print_area_type)
        }
        if(productDetails?.tags){

          const previousTags = []
          productDetails?.tags?.split(",")?.forEach((val) => {
              previousTags.push({ id: val, text: val })
            })

if(previousTags[0]?.text){

setTags(previousTags)
}
        }
      }
     },[productDetails])
    const newImage=values.files.find(img=>typeof img.image==="object")
 const deleteImage=async(index,e)=>{
    e.stopPropagation()
    const image_id= values.files[index].image_id
    if(image_id){
       try {
        const {data} = await axiosConfig.delete(`/delete-image?image_id=${image_id}`)
        if(data?.status==="success"){
          toast.success("Deleted Successfully")
          const remainData = values?.files.filter((element, i) => index !== i);
          setFieldValue("files",remainData)
        }else{
           toast.error("Something went wrong")
        }
       } catch (error) {
        toast.error("Something went wrong")
       }
    }else{
      const remainData = values?.files.filter((element, i) => index !== i);
      setFieldValue("files",remainData)
    }
    
  }
  console.log("ValuesXXXXXXXXXXXXXXXXXXX",values)

 const handleFileUpload=(doc,index)=>{
   if (doc.length) {
     let tempFiles = [...values.files];
     doc?.forEach((item, i) => {
       if (tempFiles[index + i]) {
         tempFiles[index + i] = {...tempFiles[index + i], image: item, cordinate: "", cordinate2: "", type: "" };
       } else {
        tempFiles[index + i] = { image: item, cordinate: "", cordinate2: "", type: "" };
        }
     })
     if (tempFiles?.find((item) => item?.image === "")) {
       setFieldValue("files",tempFiles)
      } else {
       setFieldValue("files",[...tempFiles,{image:"",cordinate:"",cordinate2:"",type:"",image_id:"",mask:"",layer:""}])
      }
    // setFieldValue("files",[...values.files?.filter((v)=>v?.image),...doc.map((val)=>{
      // return {image:val,cordinate:"",cordinate2:"",type:""}
    // }),{image:"",cordinate:"",cordinate2:"",type:""}])
     if (emptyRect?.length) {
       let tempEmptyReact = [...emptyRect];
       doc?.forEach((_, i) => {
        tempEmptyReact[index + i] = [{"X":"","Y":"","width":"",'height':"","rotate":""}]
      })
       // dispatch(setEmptyRect([...emptyRect,...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"",'height':"","rotate":""}])]))
       dispatch(setEmptyRect(tempEmptyReact))
    }else{
      dispatch(setEmptyRect([...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"","height":"","rotate":""}])]))
    }
    if(co_ordinates?.length){
      let tempCordinates = [...co_ordinates];
      doc?.forEach((_, i) => {
        tempCordinates[index + i] = [{"X":"","Y":"","width":"","height":"","rotate":""}]
     })
      dispatch(createCo_ordinateField(tempCordinates))
      // dispatch(createCo_ordinateField([...co_ordinates,...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"","height":"","rotate":""}])]))
    }else{
      dispatch(createCo_ordinateField([...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"","height":"","rotate":""}])]))
    }
  }
 }
 

 const suggestions = [];
const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];
const handleAddition = (tag) => {
  if (tags.length === 5) {
    toast.error("You can not add more than 5 search tags");
    return;
  }
  setTags([...tags, tag]);
};

const handleDelete = (i) => {
  
  setTags(tags?.filter((tag, index) => index !== i));
};
const printArea=[
  {value:"normal",label:"Normal"},
  {value:"sticker",label:"Sticker"},
  {value:"wooden",label:"Wooden"},
  {value:"metal",label:"Metal"},
  { value: "glass", label: "Glass" },
  {value:"embroidery",label:"Embroidery"},
]

  return (
    <Template>
        <div className='flex flex-col gap-8 mx-7'>
            <div className='flex w-full justify-between items-center'>

        <div className='flex gap-5 items-center'>
            <button className='cursor-pointer' onClick={()=>navigation(-1)}>

        <svg  fill="#000000" width="20px" height="20px" viewBox="0 0 1.2 1.2" id="left" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path id="primary" d="M1.05 0.55H0.271l0.265 -0.265a0.05 0.05 0 1 0 -0.071 -0.071l-0.35 0.35a0.05 0.05 0 0 0 0 0.071l0.35 0.35a0.05 0.05 0 0 0 0.071 0 0.05 0.05 0 0 0 0 -0.071L0.271 0.65H1.05a0.05 0.05 0 0 0 0 -0.1" /></svg>
            </button>
        <h2 className='text-2xl font-medium'>Edit Product</h2>
        </div>
        <div className="f ">
                  <button
                  onClick={handleSubmit}
                    className="bg-blue-500 p-2  hover-bg-blue-700 text-white font-bold py-2 px-7  rounded-[90px] focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={loading}
                    style={loading?{background:"#808080"}:{}}
                  >
                    Save
                  </button>
                </div>
            </div>
        <div>
            <div className='flex gap-5 '>

{
    values?.files?.map((file,i)=>
    <div key={i} className='flex flex-col items-center gap-2'>
        <Files
                  className="files-dropzone cursor-pointer"
                  // onChange={(doc) => {
                  //   if(doc.length){
                  //     setFieldValue("files",[...doc.map((val)=>{
                  //       return {image:val,cordinate:"",cordinate2:"",type:"",image_id:""}
                  //     }),...values?.files])
                  //   }
                  // }}
                  onChange={(doc)=>handleFileUpload(doc,i)}
                  onError={(error) => toast.error(error.message)}
                  accepts={["image/png"]}
                  
                  // maxFileSize={512000}
                  maxFileSize={5000000}
                  minFileSize={0}
                  multiple
                  clickable
                >
                  {file.image!=='' ? (
            <>
                    <div className="flex flex-col items-center relative">
                      {
                        values?.files.length>2&&
                        <button onClick={(e)=>{deleteImage(i,e)}} className='absolute top-0 right-0'>
                        <svg fill="gray" width="20" height="20" viewBox="0 0 0.6 0.6" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path d="M.335.3.493.143A.025.025 0 1 0 .458.108L.3.265.143.107a.025.025 0 0 0-.035.035l.158.157-.158.157a.025.025 0 0 0 0 .035.025.025 0 0 0 .035 0L.3.333l.157.158a.025.025 0 0 0 .035 0 .025.025 0 0 0 0-.035Z"/></svg>
                        </button>
                      }
                    <img
                    // src={file?.image?.preview?.url}
                      src={
                        typeof file.image === "object"
                          ? file?.image?.preview?.url
                          : file?.image
                      }
                      alt=""
                      className="h-32   w-[140px] object-cover"
                    />
                  </div>
            </>
                  ) : (
                    <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                      <UploadIcon />
                      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                        <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                          Upload
                        </p>
                      </div>
                    </div>
                  )}
                </Files>
                {file?.image &&         <div className=''>

<Fabriccanvas image={ typeof file.image === "object"? file?.image?.preview?.url: file?.image} setFieldValue={setFieldValue} value={file.cordinate} index={i} allImage={values.files} secondValue={file.cordinate2} error={(file.cordinate||file.cordinate2)&& (file.type)?false:true} setErrors={seterrosImage} layer={typeof file?.layer==="object"?file?.layer?.preview?.url:file.layer} mask={typeof file?.mask==="object"?file?.mask?.preview?.url:file.mask } thumb={typeof file?.thumb==="object"?file?.thumb?.preview?.url:file.thumb }  />
</div>}
    </div>
    )
}
            </div>
      <p className="text-[14px] block mb-2 text-sm font-medium text-gray-900">(upload only png)</p>
        </div>
        <div className='w-[60%] flex flex-col gap-7'>
        <div className='w-full'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Product Name
                        </label>
                  <input
                    name="name"
                    type="text"
                    style={errors.name&&touched.name&&{border:"1px solid red"}}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Product name"
                  />

                </div>
                <div className='w-full'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Product Type
                        </label>
                  <select
                    name="product_type"
                    type="text"
                    style={errors.product_type&&touched.product_type&&{border:"1px solid red"}}
                    value={values.product_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Product Type"
            >
              <option value={""}>Select</option>
              <option value={"configurator"}>Configurator</option>
              <option value={"mockup"}>Mockup</option>
                  </select>
          </div>
          {
            values.product_type==="configurator"&& <div className='w-full'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Configurator
                        </label>
                  <select
                    name="configuratorType"
                    type="text"
                    style={errors.configuratorType&&touched.configuratorType&&{border:"1px solid red"}}
                    value={values.configuratorType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Product Type"
            >
                <option value={""}>Select</option>
                {configuratorList?.map((item) => (
                  <option value={item?.id}>{ item?.name}</option>
                ))}
                  </select>
          </div>
          }

        <div className="w-full">
                    <div className="">
                        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Color
                        </label>

                        <Select
                          name="color"                        
                          options={colorList?.map((option) => ({
                            value: option.colour_id,
                            label: option.colour_name,
                          }))}
                          styles={{ cursor: "pointer" ,border:"1px solid red"}}
                          isSearchable={false}
                          isMulti
                          on
                          className="focus:outline-none focus:shadow-outline"
                          // styles={customStyles}
                          onChange={(selectedOption) =>
                            setFieldValue("color", selectedOption)
                          }
                          onBlur={handleBlur}
                          value={values.color}
                        />
                      </div>
                </div>

                {/* <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Category
                        </label>
                   
                 <select style={errors.category&&touched.category&&{border:"1px solid red"}} name='category' onChange={handleChange} onBlur={handleBlur} value={values.category} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                   <option>category1</option>
                   <option>category1</option>
                   <option>category1</option>
                 </select>

                </div> */}
                <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Sub Category
                        </label>
                   
                 <select name='subCategory' onChange={handleChange} onBlur={handleBlur} value={values.subCategory} style={errors.subCategory&&touched.subCategory&&{border:"1px solid red"}} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  <option value={""}>Select</option>
                  {
                    subCategoryList?.map((subcat,i)=>
                    <option value={subcat?.product_sub_category_id}>{subcat?.product_sub_category_name}</option>
                    )
                  }
                   
                  
                 </select>

          </div>
          <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Template Category
                        </label>
                   
                 <select name='template_category_id' onChange={handleChange} onBlur={handleBlur} value={values.template_category_id} style={errors.template_category_id&&touched.template_category_id&&{border:"1px solid red"}} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  <option value={""}>Select</option>
                  {
                    templateCategory?.map((subcat,i)=>
                    <option value={subcat?.template_category_id}>{subcat?.template_category_name}</option>
                    )
                  }
                   
                  
                 </select>

                </div>
                <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Print area type
                        </label>
                   
                 <select name='print_type' onChange={handleChange} onBlur={handleBlur} value={values.print_type} style={errors.print_type&&touched.print_type&&{border:"1px solid red"}} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  
                  {
                    printArea?.map((print,i)=>
                    <option value={print?.value} key={i}>{print?.label}</option>
                    )
                  }
                   
                  
                 </select>

                </div>
                <div>
                {/* tag design */}
                <label  className="block text-base  text-gray-700 font-bold"
                    htmlFor="name">
                          Tags
                        </label>
                <div className="h-[100px] ">

                <ReactTags
                          inline={false}
                          tags={tags}
                          suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          inputFieldPosition="inline"
                          autocomplete
                          maxLength={15}
                          classNames={{
                            tags: "block h-full w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:b dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none",
                            tagInput: "w-full h-full focus:outline-none",
                            tagInputField:
                              "w-full focous focus:outline-none text-black text-sm rounded-lg p-2.5 dark: dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-none",
                            selected: "flex flex-wrap",
                            tag: "border border-gray-300 bg-gray-300 text-gray-900 text-sm rounded-md p-2.5 m-1",
                            remove: "ml-2 cursor-pointer",
                            suggestions:
                              "absolute bg-white border rounded-lg shadow w-200",
                            activeSuggestion:
                              "bg-blue-500 text-white cursor-pointer",
                          }}
                        />
                </div>
                  </div>

                
        </div>


        </div>
        </Template>
  )
}

export default EditProduct