import { createSlice } from "@reduxjs/toolkit";

const clippartSlice=createSlice({
    name:"clippart",
    initialState:{
        clippartList: [
            {
                "clipart_id": 113,
                "clipart_name": "XYZ",
                "height": 23.45,
                "width": 8.98,
                "price": 1111.00,
                "image": "https://onlinedesigner.com/assets//clipart/113/image 175.png",
                "thumb_image": "https://onlinedesigner.com/assets//clipart/113/thumb_image 175.png",
                "tag": "ABC",
                "is_active": true,
                "clipart_category_id": 1,
                "clipart_category_name": "xyz",
                "clipart_sub_category_id": 6,
                "clipart_sub_category_name": "QQQ"
            },
            {
                "clipart_id": 112,
                "clipart_name": "XYZ",
                "height": 23.45,
                "width": 8.98,
                "price": 1111.00,
                "image": "https://onlinedesigner.com/assets//clipart/112/image 175.png",
                "thumb_image": "https://onlinedesigner.com/assets//clipart/112/thumb_image 175.png",
                "tag": "ABC",
                "is_active": true,
                "clipart_category_id": 1,
                "clipart_category_name": "xyz",
                "clipart_sub_category_id": 6,
                "clipart_sub_category_name": "QQQ"
            },
            {
                "clipart_id": 111,
                "clipart_name": "XYZ",
                "height": 23.45,
                "width": 8.98,
                "price": 1111.00,
                "image": "https://onlinedesigner.com/assets//clipart/111/image 175.png",
                "thumb_image": "https://onlinedesigner.com/assets//clipart/111/thumb_image 175.png",
                "tag": "ABC",
                "is_active": true,
                "clipart_category_id": 1,
                "clipart_category_name": "xyz",
                "clipart_sub_category_id": 6,
                "clipart_sub_category_name": "QQQ"
            }
        ]
    },
    reducers:{

    }
})

export default clippartSlice.reducer;
export const {} = clippartSlice.actions