  import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Files from "react-files";
import { IoIosAdd } from "react-icons/io";

import { WithContext as ReactTags } from 'react-tag-input';
import { ReactComponent as UploadIcon } from "../assets/icons/uploadicon.svg";
import axiosConfig from "../../axios_config/axios_instance";
import { useDispatch } from "react-redux";
import {
  getAllShapeCategoryAllModule,
  getAllShapeList,
  getAllShapeSubCategoryListAllModule,
} from "../../services/apiService";
import { useSelector } from "react-redux";
import { notifyErrorMessage, notifySuccessMessage } from "../../components/common/toastMessage";

const AddShape = () => {
  const [showmodal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { SubShapeCategoryAllModule } = useSelector((state) => state.shape);

  const SubCategoryoptions = SubShapeCategoryAllModule?.map(
    ({ shape_sub_category_id, shape_sub_category_name }) => ({
      value: shape_sub_category_id,
      label: shape_sub_category_name,
    })
  );

  const { ShapeCategoryAllModule } = useSelector((state) => state.shape);

  const Categoryoptionss = ShapeCategoryAllModule?.map(
    ({ shape_category_id, shape_category_name }) => ({
      value: shape_category_id,
      label: shape_category_name,
    })
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if(!SubShapeCategoryAllModule?.length){
      dispatch(getAllShapeCategoryAllModule());
    }
  },[dispatch]);

  //Reacts Tags Logic Start
  const [keyword, setkeywords] = useState([]);

  const suggestions = [];
  const KeyCodes = {
    comma: 188,
    enter: 13,
    space: 32,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

  const handleDeleteKeword = (i) => {
    setkeywords(keyword.filter((tag, index) => index !== i));
  };
  const handleAdditionKeyword = (tag) => {
    setkeywords([...keyword, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...keyword];
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setkeywords(newTags);
  };


  //Reacts Tags Logic ends

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("font name is required"),
    categorylist: Yup.string().required(" category list is required"),
    shapeimage: Yup.mixed().required("ttf file is required"),
  });
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      price: "",
      name: "",
      categorylist: "",
      subcategorylist: "",
      shape_width:"",
      shape_height:"",
      shapeimage: null,
    }, // <-- Corrected the placement of this closing curly brace
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, _action) => {
      try {
        console.log("valuess...", values);
        if(!keyword.length){
          toast.error("Please add atleast one tag")
          return;
        }
        setLoading(true);
        const formdata = new FormData();
        formdata.append("image", values?.shapeimage);
        formdata.append("shape_name", values?.name);
        formdata.append("sort_order",0);
        formdata.append("price", values?.price);
        formdata.append("width", values?.shape_width);
        formdata.append("height", values?.shape_height);
        formdata.append("tag", keyword.map((tag) => tag.text).join(","));
        // formdata.append("shape_sub_category_name",SubShapeCategoryAllModule?.find((subshapee) => subshapee?.shape_sub_category_id)?.shape_sub_category_id);
        formdata.append("shape_sub_category_id", values.subcategorylist);
        formdata.append("shape_category_id", values?.categorylist);
        const { data } = await axiosConfig.post("/save-shape", formdata);
        if (data?.status === "success") {
          notifySuccessMessage(data?.message || "Gallery Added successfully");
          dispatch(getAllShapeList());
          setShowModal(false)
          
                }
      } catch (error) {
        notifyErrorMessage("something Went wrong");
        console.log("error,,,,",error)
      } finally {
        setLoading(false);
      }
    },
  });

  console.log("values...",values)
  console.log("errors...", errors)

  useEffect(() => {
    if(!SubShapeCategoryAllModule?.length){
      dispatch(getAllShapeSubCategoryListAllModule(0,1000));

    }
  }, [dispatch]);


  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("shapeimage", files[0]);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <>
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 p-1 px-3 cursor-pointer bg-gray-200 rounded-md"
      onClick={openModal}
    >
      <button className="text-[16px]">
        <div className="flex items-center">
          <IoIosAdd className="mr-1" /> Add Shape
        </div>
      </button>
    </div>
    {showmodal ? (
      <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black overflow-hidden">
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative w-[550px] bottom-6 h-[580px] rounded-lg shadow bg-gray-200  overflow-auto">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-2 border-b rounded-t bg-[#454546]">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Add Shape
              </h3>
              <button
                onClick={closeModal}
                
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div>
            <div className='flex gap-5 p-4  '>
            <Files
            className="files-dropzone cursor-pointer"
            onChange={(file) => handleImageChange(file)}
            onError={(error) => toast.error(error?.message)}
            accepts={[".svg"]}
            maxFileSize={512000}
            minFileSize={0}
            multiple
            clickable
          >
            {values.shapeimage ? (
              <div className="flex flex-col items-center p-4 relative">
                <img
                  src={
                    typeof values.shapeimage === "object"
                      ? values.shapeimage?.preview?.url
                      : values.shapeimage
                  }
                  alt=""
                  className="h-32 w-[140px] object-cover"
                />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                <UploadIcon />
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                  <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                    Upload
                  </p>
                </div>
              </div>
            )}
          </Files>
    
         
            </div>
      <p className="text-[14px] block mb- mx-4 text-sm font-medium text-gray-900">(upload only svg)</p>
        </div>
        <div className="p-4 md:p-5">
                    <div className="grid gap-4 mb-4 grid-cols-2">
    
                    
                      <div className="col-span-2">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium capitalize text-gray-900"
                        >
                          Shape Name
                        </label>
                        <input
                        type="text"
                        name="name"
                        className=" text-black border text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400  "
                        placeholder="Shape Name"
                        value={values?.name}
                        onChange={handleChange}
                        style={
                          errors.name && touched.name
                            ? { border: "1px solid red" }
                            : {}
                        }
                      />
    
                      </div>
                 
                     
                      <div className="row-span-2 sm:col-span-1">
                      <div className='w-[100%]  '>
                      <p className='block mb-2 text-sm font-medium capitalize text-gray-900'>category</p>
                      <select
                      name="categorylist"
                      onChange={(e)=>{
                        setFieldValue("subcategorylist","")
                        handleChange(e)
                      }}
                      onBlur={() => handleBlur("categorylist")}
                      value={values.categorylist}
                      placeholder="select category"
                      style={
                        errors.categorylist && touched.categorylist
                          ? { border: "1px solid red" }
                          : {}
                      }
                      className=" text-black border capitalize text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400  "
                      >
                      <option>Select</option>
                      {ShapeCategoryAllModule?.map((option,i) => (
                        <option key={i} className="text-black capitalize  bg-gray-200  " value={option.shape_category_id}>
                         {option?.shape_category_name}
                        </option>
                      ))}
                    </select>
    
    
              
                        </div>
                      </div>
                      <div className="row-span-2 sm:col-span-1">
                      <div className='w-[100%]  '>
                      <p className='block mb-2 text-sm font-medium capitalize text-gray-900'>sub category</p>
                      <select
                      name="subcategorylist"
                      onChange={handleChange}
                      onBlur={() => handleBlur("subcategorylist")}
                      value={values.subcategorylist}
                      placeholder="select category"
                      style={
                        errors.subcategorylist && touched.subcategorylist
                          ? { border: "1px solid red" }
                          : {}
                      }
                      className=" text-black border text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400  "
                      disabled={values.categorylist?"":"disable"}
                    >
                      <option>Select</option>
                      {SubShapeCategoryAllModule?.filter((subfilt)=>Number(subfilt?.shape_category_id)===Number(values?.categorylist))?.map((option) => (
                        <option className="text-black  bg-gray-200 " key={option.value} value={option.shape_sub_category_id}>
                          {option?.shape_sub_category_name}
                        </option>
                      ))}
                    </select>
    
              
                        </div>
                      </div>
    
    
                   
                   
    
            
                  </div>
                  <div className="row-span-2 sm:col-span-1">
                  <div className="h-[100px] ">
                  <ReactTags
                  inline={false}
                  placeholder="Press enter to add new keyword"
                  tags={keyword}
                  suggestions={suggestions}
                  delimiters={delimiters}
                  handleDelete={handleDeleteKeword}
                  handleAddition={handleAdditionKeyword}
                  handleDrag={handleDrag}
                  inputFieldPosition="inline"
                  autocomplete
                  maxLength={15}
                  classNames={{
                    tags: "block text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  bg-gray-200 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
                    tagInput: "w-full ",
                    tagInputField:
                      "text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 border  dark:border-gray-500 dark:placeholder-gray-400 ",
                    selected: "flex flex-wrap",
                    tag: "border border-gray-300 bg-gray-300 text-gray-900 text-sm rounded-md p-2.5 m-1",
                    remove: "ml-2 cursor-pointer",
                    suggestions:
                      "absolute bg-white border rounded-lg shadow w-200",
                    activeSuggestion:
                      "bg-blue-500 text-white cursor-pointer",
                  }}
                />
                  </div>



            
                </div>
                  <button
                  onClick={handleSubmit}
                    className="bg-blue-500 p-2 self-end  hover-bg-blue-700 text-white font-bold py-2 px-7  rounded-[90px] focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Save
                  </button>
          </div>
        </div>
      </div>
    </div>
    ) : null}
                    </>
  )
};

export default AddShape;
