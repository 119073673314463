import React from "react";
import Navigation from "./Navigation";
import Header from "./Header";


const Template = ({ children }) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { statusCode } = useSelector((state) => state.equipment);
//   useEffect(() => {
//     const role = localStorage.getItem("role");
//     if (role !== "admin") {
//       navigate("/vendor-dashboard")
//     }
//   },[navigate])

//   useEffect(() => {
//     if (statusCode && statusCode === 401) {
//       navigate("/login")
//       dispatch(setIsAuthenticated(false))
//       toast.error("session is over login to continue...")
//       dispatch(clearStatusCode())
//       localStorage.clear();
//       axiosConfig.interceptors.request.use(
//         (config) => {
//       let headers= {
//               "Access-Control-Allow-Headers": "*",
//       }
//         config.headers=headers
//       return config;
//     },
//     (error) => {
//       console.error("Authentication request failed:", error);
//      return Promise.reject(error);
//         }
//       );
//     }
//   }, [statusCode])
  
//   useEffect(() => {
//     dispatch(getAllEquipmentTypes());
//   }, [dispatch]);
  
  return (
    <div className="flex min-w-full min-h-screen bg-[#f3f5f8]">
      <div className="bg-[#19191c] fixed left-0 top-0 min-h-screen z-10 max-h-screen overflow-y-auto">
        <Navigation />
      </div>
      <div className="min-w-full pl-[240px]">
        <Header />
        <div className="p-4">
        {children}
        </div>
      </div>
    </div>
  );
};

export default Template;
