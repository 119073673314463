import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import * as routesKey from "../../constants/routes"
import { FaRegEdit } from 'react-icons/fa';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { IoIosAdd } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getBackgroundCategory } from '../../services/api';
import axiosConfig from '../../axios_config/axios_instance';
import { setBackgroundSwapCategory } from '../../redux/slice/backgroundSlide';
import Pagination from '../../components/pagination';
import EnableDisableModal from '../../components/common/EnableDisableModal';
import {ReactComponent as EditIcon} from "../assets/icons/pencil_icon.svg"

const BackgroundCategory = () => {
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1)
    const {backgroundCategoryList,total_background_category} = useSelector(state=>state.background)
    const [loading, setLoading] = useState(false)
     const dispatch = useDispatch()
    const closeModal = () => {
      setShowModal(false);
    };
  
    const openModal = () => {
      setShowModal(true);
    };
  
  
    
  
    useEffect(()=>{
      if(!backgroundCategoryList?.length){
        dispatch(getBackgroundCategory(page))

      }
    },[dispatch,page])
    const handleDragEnd = async (e) => {
      if (!e.destination) {
        return;
      }
      const { destination, source } = e;
      const category1 = backgroundCategoryList[destination?.index]?.category_id;
      const category2 = backgroundCategoryList[source.index]?.category_id;
      dispatch(setBackgroundSwapCategory({ cat1: destination?.index, cat2: source?.index }));
      try {
        const { data } = await axiosConfig.put(
          `/swap-background-category-sort-order?category_id1=${category1}&category_id2=${category2}`
        );
        if (data?.status === "success") {
          dispatch(getBackgroundCategory(page));
        }
      } catch (error) {}
    };

    const handleDelete=async (id,setshowModal)=>{
      try {
        setLoading(true)
        const {data} = await axiosConfig.put(`/status-background-category-change?category_id=${id}`)
        if(data?.status==="success"){
         dispatch(getBackgroundCategory(page))
         setshowModal(false)
         toast.success("Updated successfully")
        }else{
         toast.error("Something went wrong !!")
        }

        setLoading(false)
      } catch (error) {
       toast.error("Something went wrong !!")
       setLoading(false)
      }
 }
  
    return (
      <Template>
        <div className='bg-gray-200 flex justify-start w-full'>
        <div className='flex justify-start gap-2 p-2'>
        <p>Home</p>
        /
        <Link to={routesKey.BACKGROUND}>
          <p>Background</p>
        </Link>
        /
        <p>category</p>
      </div>
        </div>
  
        <div className='flex flex-col flex-grow-0 flex-shrink-0 justify-center items-stretch mx-11 p-2'>
          <div className='flex flex-1 justify-start gap-6 flex-grow-0 flex-shrink-0 items-center p-4 pt-4'>
            <div className='flex flex-row flex-shrink-0 flex-grow-0'>
              <h2 className='text-2xl font-sans font-bold'>Manage Category</h2>
            </div>
          </div>
          <div className='bg-gray-200'>
            <AddCategory />
            <div className='p-2'>
              {/* Category List */}
              <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="categoryList">
                {(provided) => (
                  <table
                    className='min-w-full bg-white border border-gray-300'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <thead>
                      <tr className='bg-gray-100'>
                        <th className='border border-gray-300 px-4 py-2'>Category Name</th>
                        <th className='border border-gray-300 px-4 py-2'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {backgroundCategoryList?.map((category, index) => (
                        <Draggable key={category.category_id} draggableId={`category-${category.category_id}`} index={index}>
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <td className='border text-center border-gray-300 px-4 py-2'>{category.category_name}</td>
                              <td className='border text-center border-gray-300 px-4 py-2'>
                                <div className=' flex justify-center items-center gap-5'>
                                  {
                                    category.is_active&&
                              <button className=''>
                                <EditCategory categoryDetails={category} page={page}/>
                                </button>
                                  }
                               

                             <EnableDisableModal dontSHow={true}   onConfirm={handleDelete} id={category.category_id} isLoading={loading} desc={"Category"} is_active={!category?.is_active} />
                                

                                  </div>
                              </td>
                             
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
            </div>
          </div>
        </div>
        <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>
        <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={total_background_category} 
        pageSize={10}
                  onPageChange={(newPage) => { 
                    setPage(newPage)
                      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                  }
                   }
      />  
      </div>
      </Template>
    );
  };
  

export default BackgroundCategory

export const AddCategory=()=>{
    const {backgroundCategoryList} = useSelector(state=>state.background)
    const [showmodal, setShowModal] = useState(false);
    const [loading, setLoading]=useState(false)
    const closeModal = () => {
      setShowModal(false);
    };

    const dispatch = useDispatch()
  // console.log("lengthhhh",backgroundCategoryList.length);
    const openModal = () => {
      setShowModal(true);
    };
  
    const validationSchema = Yup.object().shape({
      categoryname: Yup.string().trim().required("categoryname is required"),
      // enableanddisablestatus:Yup.string().required("status is required"),
  });
  
  
    const {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      errors,
      touched,
      resetForm,
      setFieldValue,
    } = useFormik({
      initialValues: {
     
        categoryname:"",
        enableanddisablestatus:"",
      },
      validationSchema: validationSchema,
      enableReinitialize:true,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: async (values, action) => {
         try {
          setLoading(true)
         const {data} = await axiosConfig.post(`/save-background-category?category_name=${values.categoryname}&sort_order=${backgroundCategoryList?.length+1}`)
         if(data?.status==="success"){
          setLoading(false)
           toast.success("Category saved successfully")
           resetForm()
           closeModal()
           dispatch(getBackgroundCategory())
         }else{
          setLoading(false)
          toast.error("Something went wrong")
         }         
         } catch (error) {
          setLoading(false)
            toast.error("Something went wrong")
         }
      },
    });
  
    const EnableandDisableStatuschanges = [
      { label: 'enable', value: 0 },
      { label: 'disable', value: 1 },
   
     
    ];

    return (
      <div>
      <div   onClick={openModal} className='flex justify-start items-center p-1 flex-grow-0 flex-shrink-0 cursor-pointer bg-gray-400'>
      <button className='text-[18px] font-normal'>
        <div className='flex items-center'>
          <IoIosAdd className='mr-1' />Add Category
        </div>
      </button>
    </div>
    {showmodal ? (
      <div className="">
        <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
          <div className="relative p-4 w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative  rounded-lg shadow bg-slate-100">
              {/* Modal header */}
              <div className="flex items-center bg-black justify-between py-2 px-4 md: border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add New Category
                </h3>
                <button
                  onClick={closeModal}
                  disabled={loading}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-black"
                    >
                      Category Name
                    </label>
                    <input
                    style={errors.categoryname&&touched.categoryname&&{border:"1px solid red"}}
                      type="text"
                      value={values.categoryname}
                      name="categoryname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  ${errors.categoryname&&touched.categoryname?"dark:border-red-500":"dark:border-gray-500"} dark:placeholder-gray bg-white dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                      placeholder="Category Name"
                      required=""
                      
                    />
                  </div>
                  
                  {/* <div className="col-span-2 sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Category
                  </label>
                  <select
                  name="categorylist"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.enableanddisablestatus}
                  placeholder="select category"
                  style={
                    errors.enableanddisablestatus&&errors.enableanddisablestatus
                      ? { border: "1px solid red" }
                      : {}
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  >
                  <option>Select</option>
                  {EnableandDisableStatuschanges?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                </div> */}
               
                </div>
                <button
                onClick={handleSubmit}
                disabled={loading}
                  type="submit"
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null}
      </div>
    )
  }
  
  
  export const EditCategory=({categoryDetails,page})=>{
    const {backgroundCategoryList} = useSelector(state=>state.background)
    const [showmodal, setShowModal] = useState(false);
    const [loading, setLoading]=useState(false)
    const closeModal = () => {
      setShowModal(false);
    };

    const dispatch = useDispatch()
  // console.log("lengthhhh",backgroundCategoryList.length);
    const openModal = () => {
      setShowModal(true);
    };

    useEffect(()=>{
      if(categoryDetails){
        setFieldValue("categoryname",categoryDetails?.category_name)
      }

    },[categoryDetails])
  
    const validationSchema = Yup.object().shape({
      categoryname: Yup.string().trim().required("categoryname is required"),
      // enableanddisablestatus:Yup.string().required("status is required"),
  });
  
  
    const {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      errors,
      touched,
      resetForm,
      setFieldValue,
    } = useFormik({
      initialValues: {
     
        categoryname:"",
        enableanddisablestatus:"",
      },
      validationSchema: validationSchema,
      enableReinitialize:true,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: async (values, action) => {
         try {
          setLoading(true)
         const {data} = await axiosConfig.put(`/update-background-category?category_name=${values.categoryname}&sort_order=${categoryDetails?.sort_order}&category_id=${categoryDetails?.category_id}`)
         if(data?.status==="success"){
          setLoading(false)
           toast.success("Category updated successfully")
           resetForm()
           closeModal()
           dispatch(getBackgroundCategory(page))
         }else{
          setLoading(false)
          toast.error("Something went wrong")
         }         
         } catch (error) {
          setLoading(false)
            toast.error("Something went wrong")
         }
      },
    });
  
    const EnableandDisableStatuschanges = [
      { label: 'enable', value: 0 },
      { label: 'disable', value: 1 },
   
     
    ];

    return (
      <div>
      <EditIcon className="w-4 h-4 " onClick={openModal} />
    {showmodal ? (
      <div className="">
        <div className=" fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black">
          <div className="relative p-4 w-full  max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative  rounded-lg shadow bg-slate-100">
              {/* Modal header */}
              <div className="flex items-center justify-between py-2 px-4 border-b rounded-t bg-black">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Edit  Category
                </h3>
                <button
                  onClick={closeModal}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-start text-sm font-medium text-gray-900 "
                    >
                      Category Name
                    </label>
                    <input
                    style={errors.categoryname&&touched.categoryname&&{border:"1px solid red"}}
                      type="text"
                      value={values.categoryname}
                      name="categoryname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white ${errors.categoryname&&touched.categoryname?"dark:border-red-500":"dark:border-gray-500"} dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                      placeholder="Category Name"
                      required=""
                    />
                  </div>
                  
                  {/* <div className="col-span-2 sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Category
                  </label>
                  <select
                  name="categorylist"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.enableanddisablestatus}
                  placeholder="select category"
                  style={
                    errors.enableanddisablestatus&&errors.enableanddisablestatus
                      ? { border: "1px solid red" }
                      : {}
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  >
                  <option>Select</option>
                  {EnableandDisableStatuschanges?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                </div> */}
               
                </div>
                <button
                onClick={handleSubmit}
                disabled={loading}
                  type="submit"
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null}
      </div>
    )
  }