import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { Link, useNavigate } from 'react-router-dom'
import Files from "react-files";
import { ReactComponent as UploadIcon } from "../assets/icons/uploadicon.svg";
import Select from "react-select" 
import toast from 'react-hot-toast';
import Fabriccanvas from './FabricCanvas';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { getConfiguratorLists, getProdouctColors, getProdouctSubCategory, getProducts, getTemplateCategory } from '../../services/api';
import axiosConfig from '../../axios_config/axios_instance';
import { clearCo_ordinates, createCo_ordinateField, setEmptyRect, setImageCo_ordinates } from '../../redux/slice/productListSlice';
const AddProduct = () => {
  const {subCategoryList,colorList,co_ordinates,emptyRect} = useSelector(state=>state.productList)
  const {configuratorList}=useSelector((state)=>state.configurator)
  const {templateCategory} = useSelector(state=>state.template)
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const [tags, setTags] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)
  useEffect(() => {
    dispatch(getConfiguratorLists(0,1000))
  }, [])
    useEffect(()=>{
      dispatch(getProdouctColors())
      dispatch(getProdouctSubCategory())
    }, [dispatch])
    useEffect(()=>{
      dispatch(getTemplateCategory(0,1000))
  },[dispatch])
    useEffect(()=>{
      dispatch(clearCo_ordinates())
    },[dispatch])
    
    



    const initialValues={
      name:"",
      print_type:"normal",
      category:"",
      color:[],
      subCategory:"",
      files: [{ image: "", cordinate: "", cordinate2: "", type: "", mask: "", layer: "", thumb: "" }],
      product_type: "",
      configuratorType:"",
      template_category_id:""
      // cordinate: "",
     }
     const [errosImage, seterrosImage] = useState([])
     const productValidationSchema=Yup.object({
      template_category_id:Yup.string().required("Required"),
      name: Yup.string()
        .trim()
        .required("Please enter name"),
        subCategory: Yup.string()
         .required("required"),
         product_type: Yup.string(),
         configuratorType:""
    //     color: Yup.array()
    // .of(Yup.object().shape({
    //   label: Yup.string().required("Tag label is required"),
    //   value: Yup.string().required("Tag value is required")
    // }))
    // .required("Please add at least one tag")
    });
    const {
      values,
      handleBlur,
      resetForm,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
    } = useFormik({
      initialValues,
      validationSchema: productValidationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit: async (values, action) => {
        try {
          if(values.files.length>1&&values.color.length){
            // let tempErrors=[...errosImage];
            let allValidated=true;
            const checkAllcordinate=values.files?.filter(v=>v.image!=="")
            checkAllcordinate?.forEach((val,i)=>{
              if(!val.cordinate&&!val.cordinate2){
                allValidated=false;
              }
            })
            //validation for coordinates
            if(co_ordinates?.some(element => element?.some(val=>val?.X===""))){
              return toast.error("Add coordinate of each image")
            }
            if(true){
              const formdata = new FormData()
              formdata.append("product_name",values.name)
              let cordinates=[];
              let type=[];
              values?.files?.filter(file=>file.image!=="").forEach((v,i)=>{
                
  
                // cordinates.push(`${v.cordinate?.split(",").join(".")}${v.cordinate2&& `-${v.cordinate2?.split(",").join(".")}`}`)
                  formdata.append("images",v.image)
                  cordinates.push(JSON.stringify(co_ordinates[i]))
                  type.push(v.type?v.type:`Side ${i+1}`)
                  
                  if(v.thumb){
                  formdata.append("thumbs",v.thumb)
                }else{
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
              formdata.append(`thumbs`, emptyFile)
                }
                if(v.layer){
                  formdata.append(`layers`, v.layer)
                }else{
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
              formdata.append(`layers`, emptyFile)
                }
                if(v.mask){
              formdata.append(`masks`, v.mask)
                }else{
                  const emptyFile = new File([], 'empty.txt', { type: 'text/plain' })
                  formdata.append(`masks`, emptyFile)    
                }
              })
              formdata.append("colour_list",values?.color?.map((v)=>v.value))
              formdata.append("product_sub_category_id",values?.subCategory)
              formdata.append("template_category_id",values?.template_category_id)
              formdata.append("co_ordinates",cordinates)
              formdata.append("product_type",values.product_type)
              if (values.product_type === "configurator") {
                formdata.append("configurator_id",values.configuratorType)
              }
              formdata.append("type",type.join(","))
              formdata.append("print_area_type ",values.print_type)
              formdata.append("tags", tags.map(({ text }) => text).join(","));
              setSaveLoading(true)
              const {data} = await axiosConfig.post(`/save-product`,formdata)
              if(data?.status==="success"){
                dispatch(clearCo_ordinates())
                dispatch(getProducts(1))
                toast.success("Product added successfully")
                navigation(-1)
              }else{
                toast.error(data?.message)
              }
            }else{
              toast.error("Please set all the type and cordinate");
            }
          }else{
            if(!(values.files.length>1)){
  
              toast.error("Add at least one image")
            }else{
              toast.error("Add at least one color")
  
            }
  
          }
          setSaveLoading(false)
        } catch (error) {
          toast.error("Something went wrong")
          setSaveLoading(false)
        }
      }
    })
 const deleteImage=(index,e)=>{
    e.stopPropagation()
    const remainData = values.files.filter((element, i) => index !== i);
    setFieldValue("files",remainData)
 }

 const handleFileUpload=(doc)=>{
  if(doc.length){
    setFieldValue("files",[...values.files?.filter((v)=>v?.image),...doc.map((val)=>{
      return {image:val,cordinate:"",cordinate2:"",type:""}
    }),{image:"",cordinate:"",cordinate2:"",type:""}])
    if(emptyRect?.length){
      dispatch(setEmptyRect([...emptyRect,...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"",'height':"","rotate":""}])]))
    }else{

      dispatch(setEmptyRect([...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"","height":"","rotate":""}])]))
    }
    if(co_ordinates?.length){
      dispatch(createCo_ordinateField([...co_ordinates,...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"","height":"","rotate":""}])]))
    }else{
      dispatch(createCo_ordinateField([...Array.from({ length: doc?.length }, () => [{"X":"","Y":"","width":"","height":"","rotate":""}])]))
    }
  }
 }


const suggestions = [];
const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];
const handleAddition = (tag) => {
  if (tags.length === 5) {
    toast.error("You can not add more than 5 search tags");
    return;
  }
  setTags([...tags, tag]);
};

const handleDelete = (i) => {
  
  setTags(tags?.filter((tag, index) => index !== i));
};

const printArea=[
  {value:"normal",label:"Normal"},
  {value:"sticker",label:"Sticker"},
  {value:"wooden",label:"Wooden"},
  {value:"metal",label:"Metal"},
  {value:"glass",label:"Glass"},
  {value:"embroidery",label:"Embroidery"},
]


  return (
    <Template>
        <div className='flex flex-col gap-8 mx-7'>
            <div className='flex w-full justify-between items-center'>

        <div className='flex gap-5 items-center'>
            <button className='cursor-pointer' onClick={()=>navigation(-1)}>

        <svg  fill="#000000" width="20px" height="20px" viewBox="0 0 1.2 1.2" id="left" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path id="primary" d="M1.05 0.55H0.271l0.265 -0.265a0.05 0.05 0 1 0 -0.071 -0.071l-0.35 0.35a0.05 0.05 0 0 0 0 0.071l0.35 0.35a0.05 0.05 0 0 0 0.071 0 0.05 0.05 0 0 0 0 -0.071L0.271 0.65H1.05a0.05 0.05 0 0 0 0 -0.1" /></svg>
            </button>
        <h2 className='text-2xl font-medium'>Add Product</h2>
        </div>
        <div className="f ">
                  <button
                  onClick={handleSubmit}
                    className="bg-blue-500 p-2  hover-bg-blue-700 text-white font-bold py-2 px-7  rounded-[90px] focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={saveLoading}
                    style={saveLoading?{background:"#808080"}:{}}
                  >
                    Save
                  </button>
                </div>
            </div>
        <div>
            <div className='flex gap-5 '>

{
    values.files?.map((file,i)=>
    <div className='flex flex-col items-center gap-2'>
        <Files
                  className="files-dropzone cursor-pointer"
                  onChange={(doc) => handleFileUpload(doc)}
                  onError={(error) => toast.error(error.message)}
                  accepts={["image/png"]}
                  
                  // maxFileSize={512000}
                  maxFileSize={5000000}
                  minFileSize={0}
                  multiple
                  clickable
                >
                  {file.image!=='' ? (
                    <div className="flex flex-col items-center relative">
                        <button onClick={(e)=>{deleteImage(i,e)}} className='absolute top-0 right-0'>
                        <svg fill="gray" width="20" height="20" viewBox="0 0 0.6 0.6" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path d="M.335.3.493.143A.025.025 0 1 0 .458.108L.3.265.143.107a.025.025 0 0 0-.035.035l.158.157-.158.157a.025.025 0 0 0 0 .035.025.025 0 0 0 .035 0L.3.333l.157.158a.025.025 0 0 0 .035 0 .025.025 0 0 0 0-.035Z"/></svg>
                        </button>
                    <img
                    onClick={(e)=>e.stopPropagation()}
                    src={file?.image?.preview?.url}
                    //   src={
                    //     typeof values.shapeimage === "object"
                    //       ? values.shapeimage?.preview?.url
                    //       : values.shapeimage
                    //   }
                      alt=""
                      className="h-32  cursor-default w-[140px] object-cover"
                    />
         
                   
                  </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                      <UploadIcon />
                      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                        <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                          Upload
                        </p>
                      </div>
                    </div>
                  )}
                </Files>
                {file?.image &&         <div className=''>

<Fabriccanvas image={file.image.preview.url} thumb={file?.thumb?.preview?.url} setFieldValue={setFieldValue} chekingError={co_ordinates[i]} value={file.cordinate} index={i} allImage={values.files} secondValue={file.cordinate2} error={(file.cordinate||file.cordinate2)&& (file.type)?false:true} setErrors={seterrosImage} layer={file?.layer?.preview?.url} mask={file?.mask?.preview?.url} />
</div>}
    </div>
    )
}
            </div>
      <p className="text-[14px] block mb-2 text-sm font-medium text-gray-900">(upload only png)</p>
        </div>
        <div className='w-[60%] flex flex-col gap-7'>
        <div className='w-full'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Product Type
                        </label>
                  <select
                    name="product_type"
                    type="text"
                    style={errors.product_type&&touched.product_type&&{border:"1px solid red"}}
                    value={values.product_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Product Type"
            >
              <option value={""}>Select</option>
              <option value={"configurator"}>Configurator</option>
              <option value={"mockup"}>Mockup</option>
                  </select>
          </div>
          {
            values.product_type==="configurator"&& <div className='w-full'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Configurator
                        </label>
                  <select
                    name="configuratorType"
                    type="text"
                    style={errors.configuratorType&&touched.configuratorType&&{border:"1px solid red"}}
                    value={values.configuratorType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Product Type"
            >
                <option value={""}>Select</option>
                {configuratorList?.map((item) => (
                  <option value={item?.id}>{ item?.name}</option>
                ))}
                  </select>
          </div>
          }
        <div className='w-full'>
        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Product Name
                        </label>
                  <input
                    name="name"
                    type="text"
                    style={errors.name&&touched.name&&{border:"1px solid red"}}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Product name"
                  />

                </div>


        <div className="w-full">
                    <div className="">
                        <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Color
                        </label>

                        <Select
                          name="color"
                         
                          options={colorList?.map((option) => ({
                            value: option.colour_id,
                            label: option.colour_name,
                          }))}
                          styles={{ cursor: "pointer" ,border:"1px solid red"}}
                          isSearchable={false}
                          isMulti
                          className="focus:outline-none focus:shadow-outline"
                          // styles={customStyles}
                          onChange={(selectedOption) =>
                            setFieldValue("color", selectedOption)
                          }
                          onBlur={handleBlur}
                          value={values.color}
                        />
                      </div>
                </div>

                {/* <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Category
                        </label>
                   
                 <select style={errors.category&&touched.category&&{border:"1px solid red"}} name='category' onChange={handleChange} onBlur={handleBlur} value={values.category} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                   <option>category1</option>
                   <option>category1</option>
                   <option>category1</option>
                 </select>

                </div> */}
                <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Sub Category
                        </label>
                   
                 <select name='subCategory' onChange={handleChange} onBlur={handleBlur} value={values.subCategory} style={errors.subCategory&&touched.subCategory&&{border:"1px solid red"}} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  <option value={""}>Select</option>
                  {
                    subCategoryList?.map((subcat,i)=>
                    <option value={subcat?.product_sub_category_id}>{subcat?.product_sub_category_name}</option>
                    )
                  }
                   
                  
                 </select>

                </div>
                <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Template Category
                        </label>
                   
                 <select name='template_category_id' onChange={handleChange} onBlur={handleBlur} value={values.template_category_id} style={errors.template_category_id&&touched.template_category_id&&{border:"1px solid red"}} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  <option value={""}>Select</option>
                  {
                    templateCategory?.map((subcat,i)=>
                    <option value={subcat?.template_category_id}>{subcat?.template_category_name}</option>
                    )
                  }
                   
                  
                 </select>

                </div>
                <div className='w-full'>
                <label  className="block text-base after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-700 font-bold"
                    htmlFor="name">
                          Print area type
                        </label>
                   
                 <select name='print_type' onChange={handleChange} onBlur={handleBlur} value={values.print_type} style={errors.print_type&&touched.print_type&&{border:"1px solid red"}} className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  
                  {
                    printArea?.map((print,i)=>
                    <option value={print?.value} key={i}>{print?.label}</option>
                    )
                  }
                   
                  
                 </select>

                </div>
                <div>
                {/* tag design */}
                <label  className="block text-base  text-gray-700 font-bold"
                    htmlFor="name">
                          Tags
                        </label>
                <div className="h-[100px] ">

                <ReactTags
                          inline={false}
                          tags={tags}
                          suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          inputFieldPosition="inline"
                          autocomplete
                          maxLength={15}
                          classNames={{
                            tags: "block h-full w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:b dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none",
                            tagInput: "w-full h-full focus:outline-none",
                            tagInputField:
                              "w-full focous focus:outline-none text-black text-sm rounded-lg p-2.5 dark: dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-none",
                            selected: "flex flex-wrap",
                            tag: "border border-gray-300 bg-gray-300 text-gray-900 text-sm rounded-md p-2.5 m-1",
                            remove: "ml-2 cursor-pointer",
                            suggestions:
                              "absolute bg-white border rounded-lg shadow w-200",
                            activeSuggestion:
                              "bg-blue-500 text-white cursor-pointer",
                          }}
                        />
                </div>
                  </div>

                
        </div>


        </div>
        </Template>
  )
}

export default AddProduct