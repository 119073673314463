export const LOGIN="/login"
export const BACKGROUND="/"
export const BACKGROUND_CATEGORY="/background/category"
export const COLOR="/color"
export const TEMPLATE="/template"
export const MANAGECOLORCATEGORY="/manageColorCategory"
export const PRODUCT="/product"
export const ADDPRODUCT="/add-product"
export const EDITPRODUCT="/edit-product/:product_id"
export const MANAGEPRODCUTCATEGORY="/manageproductcategory"
export const SHAPE="/shape"
export const MANAGESHAPECATEGORY="/manageshapecategory"
export const ADDSHAPE="/Addshape"
export const EDITSHAPE="/editshape/:shape_id"
export const TEMPLATECATEGORY="/template/category"
export const ADMINSIGNUP="/signup"
export const MANAGECONFIGURATOR="/manage-configurator"
export const ADDCONFIGURATOR="/add-configurator"
export const EDITCONFIGURATOR="/edit-configurator/:id"